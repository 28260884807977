import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardContent,
  Divider,
  InputCheckbox,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';

import { ActionTiming } from './ActionTiming';
import { MODES } from '../../constants';
import { PROSPECT_STATES } from '../../../constants';

export const ACTION_SUBTITLE = 'Set the timing and action to take';

const useStyles = makeStyles({
  actionCard: { flexBasis: '100%' },
  divider: { margin: '20px 0' },
  input: {
    verticalAlign: 'baseline',
    width: '80px',
    '& label.MuiInputLabel-formControl': {
      left: '5px',
      position: 'absolute',
    },
  },
});

const CampaignAction = ({
  actionTrigger,
  clearValidationErrorMessage,
  daysToDelay,
  handleDaysToDelayOnChange,
  handleNoDelayOnChange,
  handleStateTransitionOnChange,
  handleTemplateOnClick,
  hasNoDelay,
  isMarkLostVisible,
  isNoDelayCheckboxEnabled,
  mode,
  nextProspectState,
  templateName,
  validationErrorMessages,
}) => {
  const classes = useStyles();

  const isTemplateSectionDisabled = Boolean(nextProspectState);

  const clearMessage = (field) => () => {
    if (validationErrorMessages[field]) {
      clearValidationErrorMessage(field);
    }
  };

  const {
    actionTask: actionTaskErrorMessage,
    daysToDelay: daysToDelayErrorMessage,
  } = validationErrorMessages;

  return (
    <Card className={classes.actionCard} elevation={0} square>
      <CardContent>
        <Text paragraph>{ACTION_SUBTITLE}</Text>

        <ActionTiming
          actionTrigger={actionTrigger}
          clearMessage={clearMessage}
          daysToDelay={daysToDelay}
          daysToDelayErrorMessage={daysToDelayErrorMessage}
          handleDaysToDelayOnChange={handleDaysToDelayOnChange}
          handleNoDelayOnChange={handleNoDelayOnChange}
          hasNoDelay={hasNoDelay}
          isFormMode={mode === MODES.FORM}
          isNoDelayCheckboxEnabled={isNoDelayCheckboxEnabled}
        />

        <Divider className={classes.divider} />

        <div>
          <Text gutterBottom variant="h6">
            Action
          </Text>

          {(mode === MODES.FORM || templateName) && (
            <div
              className={classnames(
                isTemplateSectionDisabled && 'disabled-template-select'
              )}
            >
              <Text
                component="span"
                variant="subtitle2"
                color={actionTaskErrorMessage ? 'error' : 'secondary'}
              >
                Template:
              </Text>

              <Button
                onClick={handleTemplateOnClick}
                onFocus={clearMessage('actionTask')}
                variant="text"
              >
                {templateName || 'Select Template'}
              </Button>
            </div>
          )}

          {isMarkLostVisible && (
            <React.Fragment>
              {mode === MODES.FORM && (
                <Text variant="body2" color="textPrimary">
                  or
                </Text>
              )}

              <InputCheckbox
                color="primary"
                disabled={mode === MODES.DISPLAY}
                checked={nextProspectState === PROSPECT_STATES.LOST}
                label={
                  <Text
                    color={actionTaskErrorMessage ? 'error' : 'textPrimary'}
                  >
                    Mark As Lost
                  </Text>
                }
                name={PROSPECT_STATES.LOST}
                onChange={handleStateTransitionOnChange}
                onFocus={clearMessage('actionTask')}
              />
            </React.Fragment>
          )}

          {actionTaskErrorMessage && mode === MODES.FORM && (
            <Text color="error" component="div" variant="caption">
              {actionTaskErrorMessage}
            </Text>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

CampaignAction.propTypes = {
  actionTrigger: PropTypes.string,
  clearValidationErrorMessage: PropTypes.func,
  daysToDelay: PropTypes.number,
  handleDaysToDelayOnChange: PropTypes.func,
  handleNoDelayOnChange: PropTypes.func,
  handleStateTransitionOnChange: PropTypes.func,
  handleTemplateOnClick: PropTypes.func,
  hasNoDelay: PropTypes.bool,
  isMarkLostVisible: PropTypes.bool,
  isNoDelayCheckboxEnabled: PropTypes.bool,
  mode: PropTypes.string,
  nextProspectState: PropTypes.string,
  templateName: PropTypes.string,
  validationErrorMessages: PropTypes.shape({
    daysToDelay: PropTypes.string,
    actionTask: PropTypes.string,
  }),
};

export default CampaignAction;
