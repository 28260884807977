import React from 'react';

export const AI_TEMPLATE_TYPES = {
  AI_POWERED_WEBFORM: 'aiPoweredWebform',
  APPLICATION_PROCESS: 'applicationProcess',
  AVAILABILITY: 'availability',
  GENERIC_AMENITIES: 'genericAmenities',
  LAUNDRY: 'laundry',
  PARKING: 'parking',
  PRICING_INQUIRY: 'pricingInquiry',
  PET_POLICY: 'petPolicy',
  SCHEDULE_TOUR: 'scheduleTour',
  UTILITIES: 'utilities',
};

export const AI_INTENT_NAME_TO_TYPE = {
  'AI Powered Webform': AI_TEMPLATE_TYPES.AI_POWERED_WEBFORM,
  'Application Process (how to)': AI_TEMPLATE_TYPES.APPLICATION_PROCESS,
  Availability: AI_TEMPLATE_TYPES.AVAILABILITY,
  'Generic Amenities': AI_TEMPLATE_TYPES.GENERIC_AMENITIES,
  Laundry: AI_TEMPLATE_TYPES.LAUNDRY,
  Parking: AI_TEMPLATE_TYPES.PARKING,
  'Pet policy': AI_TEMPLATE_TYPES.PET_POLICY,
  'Pricing Inquiry': AI_TEMPLATE_TYPES.PRICING_INQUIRY,
  'Schedule a Tour': AI_TEMPLATE_TYPES.SCHEDULE_TOUR,
  'Utilities (electric, sewer, cable, etc)': AI_TEMPLATE_TYPES.UTILITIES,
};

export const AI_TEMPLATE_TYPE_TITLES = {
  [AI_TEMPLATE_TYPES.AI_POWERED_WEBFORM]: 'AI Powered Webform',
  [AI_TEMPLATE_TYPES.APPLICATION_PROCESS]: 'Application Process (how to)',
  [AI_TEMPLATE_TYPES.AVAILABILITY]: 'Availability',
  [AI_TEMPLATE_TYPES.GENERIC_AMENITIES]: 'Generic Amenities',
  [AI_TEMPLATE_TYPES.LAUNDRY]: 'Laundry',
  [AI_TEMPLATE_TYPES.PARKING]: 'Parking',
  [AI_TEMPLATE_TYPES.PET_POLICY]: 'Pet Policy',
  [AI_TEMPLATE_TYPES.PRICING_INQUIRY]: 'Pricing Inquiry',
  [AI_TEMPLATE_TYPES.SCHEDULE_TOUR]: 'Schedule a Tour',
  [AI_TEMPLATE_TYPES.UTILITIES]: 'Utilities',
};

export const AI_TEMPLATE_ORDER = [
  AI_TEMPLATE_TYPES.AI_POWERED_WEBFORM,
  AI_TEMPLATE_TYPES.APPLICATION_PROCESS,
  AI_TEMPLATE_TYPES.AVAILABILITY,
  AI_TEMPLATE_TYPES.GENERIC_AMENITIES,
  AI_TEMPLATE_TYPES.LAUNDRY,
  AI_TEMPLATE_TYPES.PARKING,
  AI_TEMPLATE_TYPES.PET_POLICY,
  AI_TEMPLATE_TYPES.PRICING_INQUIRY,
  AI_TEMPLATE_TYPES.SCHEDULE_TOUR,
  AI_TEMPLATE_TYPES.UTILITIES,
];

export const VALIDATION_ERROR_MESSAGES = {
  SUBJECT: 'A subject is required',
  SUBJECT_LENGTH: 'Subject must have less than 100 characters',
  MESSAGE: 'A message is required',
  AI_POWERED_WEBFORM_MESSAGE:
    'AI Powered Webform must include #AIPersonalizedResponse.',
  TEMPLATE_NAME: 'A template name is required',
};

export const AI_COMPANY_TEMPLATE = {
  MESSAGE: 'message',
  SUBJECT: 'subject',
  TEMPLATE_NAME: 'templateName',
};

export const ADD = 'add';
export const EDIT = 'edit';
export const CREATE = 'create';
export const DELETE = 'delete';

export const AI_CONFIRMATION_BUTTON_TEXT = {
  [ADD]: 'Add Property',
  [CREATE]: 'Create Template',
  [DELETE]: 'Delete Template',
  [EDIT]: 'Save Template',
};

export const AI_CONFIRMATION_MODES = {
  ADD: ADD,
  CREATE: CREATE,
  DELETE: DELETE,
  EDIT: EDIT,
};

export const OFF = "OFF";
export const SETUP = "SETUP";
export const ON = "ON";

export const AI_VIEWS = {
  OFF,
  SETUP,
  ON,
};

export const getAiConfirmationTitle = (templateName, actionType) => {
  switch (actionType) {
    case ADD:
      return 'Add Property';
    case CREATE:
    case EDIT:
      return `Save ${templateName}`;
    case DELETE:
      return `Delete the ${templateName}`;
    default:
      return '';
  }
};

export const AI_PERSONALIZED_RESPONSE_CUSTOM_TAG = {
  default: '',
  description: 'AI Personalized Response',
  name: 'AIPersonalizedResponse',
  required: true,
  scope: 'public',
};

export const getAiConfirmationMessage = (templateName, actionType) => {
  switch (actionType) {
    case ADD:
      return `Selected properties will be removed from their currently assigned templates and added to ${templateName}`;
    case CREATE:
    case EDIT:
      return `Changes made to this ${templateName} Template will be applied to all included properties.`;
    case DELETE:
      return (
        <span>
          Are you sure you want to delete the <b>{templateName}</b> template?
          Properties from this template will be automatically put in the default
          template.
        </span>
      );
    default:
      return '';
  }
};

export const CUSTOM_MERGE_TAG_FIELD_ID = 'mergeTagField';
export const MERGE_TAG_LIST_OF_URLS = ['ApplicationURL', 'KnockScheduleURL'];

export const AI_MERGE_TAGS = ['CommunityName'];