import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import InfoIconTooltip from '../components/InfoTooltip';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext';
import { LeasingProviderOptionsEnum, TOOLTIP_MESSAGES } from './constants';

const OnSiteUrlPlaceholder = 'https://www.on-site.com';
const OneSiteOLLUrlPlaceholder = 'https://leasing.realpage.com';

const ApplicationInformation = ({
  inputChanged,
  onLeasingProviderChanged,
  leasingProvider,
  applicationUrl,
  isDefaultApplicationUrlOverridden,
  applicationFee,
  qualificationCriteria,
  applicationInstructions,
  errors,
  clearErrors,
}) => {
  const { isApplicationLeasingProviderEnabled } = useFeatureFlagContext();

  const isOverrideButtonVisible =
    leasingProvider !== LeasingProviderOptionsEnum.CustomURL &&
    leasingProvider !== LeasingProviderOptionsEnum.MriProspectConnect;
  const isUrlConfigurable =
    leasingProvider === LeasingProviderOptionsEnum.CustomURL ||
    leasingProvider === LeasingProviderOptionsEnum.MriProspectConnect ||
    isDefaultApplicationUrlOverridden;

  const getUrlFieldLabel = () =>
    leasingProvider === LeasingProviderOptionsEnum.MriProspectConnect
      ? 'Base URL'
      : 'Application URL';

  const getUrlPlaceholder = () => {
    if (leasingProvider === LeasingProviderOptionsEnum.OnSite) {
      return OnSiteUrlPlaceholder;
    }
    if (leasingProvider === LeasingProviderOptionsEnum.OneSiteOLL) {
      return OneSiteOLLUrlPlaceholder;
    }
    return '';
  };

  return (
    <div className="full-width application-information-container">
      <h2 className="side-margin-lg">Application Information</h2>
      <div className="property-info-edit-field-container">
        {isApplicationLeasingProviderEnabled && (
          <Fragment>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">
                <div>Online Leasing</div>
                <div>Provider</div>
              </div>
              <div className="property-info-edit-field-input">
                <select
                  value={leasingProvider}
                  onBlur={(e) => {
                    onLeasingProviderChanged(e, true);
                  }}
                  onChange={onLeasingProviderChanged}
                >
                  <option
                    key={
                      'leasing-provider-' + LeasingProviderOptionsEnum.OnSite
                    }
                    value={LeasingProviderOptionsEnum.OnSite}
                  >
                    {LeasingProviderOptionsEnum.OnSite}
                  </option>
                  <option
                    key={
                      'leasing-provider-' + LeasingProviderOptionsEnum.CustomURL
                    }
                    value={LeasingProviderOptionsEnum.CustomURL}
                  >
                    {'Custom URL'}
                  </option>
                  <option
                    key={
                      'leasing-provider-' +
                      LeasingProviderOptionsEnum.OneSiteOLL
                    }
                    value={LeasingProviderOptionsEnum.OneSiteOLL}
                  >
                    {'OneSite/OLL'}
                  </option>
                  <option
                    key={
                      'leasing-provider-' +
                      LeasingProviderOptionsEnum.MriProspectConnect
                    }
                    value={LeasingProviderOptionsEnum.MriProspectConnect}
                  >
                    {'MRI/ProspectConnect'}
                  </option>
                </select>
                <InfoIconTooltip
                  message={TOOLTIP_MESSAGES.ONLINE_LEASING_PROVIDER}
                />
              </div>
            </div>
            {isOverrideButtonVisible && (
              <div className="knock-react-flex">
                <div className="property-info-edit-field-label">
                  Override Default URL
                </div>
                <div className="property-info-edit-field-input">
                  <input
                    className="override-default-url-input"
                    type="checkbox"
                    checked={isDefaultApplicationUrlOverridden}
                    onBlur={inputChanged(
                      'isDefaultApplicationUrlOverridden',
                      true
                    )}
                    onChange={inputChanged(
                      'isDefaultApplicationUrlOverridden',
                      true
                    )}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}
        <div className="knock-react-flex">
          <div className="property-info-edit-field-label">
            {getUrlFieldLabel()}
          </div>
          <div className="property-info-edit-field-input">
            <input
              data-testid="application-information-url-input"
              value={isUrlConfigurable ? applicationUrl : getUrlPlaceholder()}
              name="applicationUrl"
              disabled={!isUrlConfigurable}
              onBlur={inputChanged('applicationUrl', true)}
              onFocus={clearErrors}
              onChange={inputChanged('applicationUrl')}
              className="knock-react-input"
              placeholder="Link to leasing application"
            />
            <InfoIconTooltip message={TOOLTIP_MESSAGES.APPLICATION_URL} />
          </div>
          {errors.applicationUrl && (
            <span className="knock-react-form-hint knock-react-form-hint-error">
              {errors.applicationUrl}
            </span>
          )}
        </div>
        <div className="knock-react-flex">
          <div className="property-info-edit-field-label">Application Fee</div>
          <div className="property-info-edit-field-input">
            <input
              value={applicationFee}
              name="applicationFee"
              onBlur={inputChanged('applicationFee', true)}
              onChange={inputChanged('applicationFee')}
              className="knock-react-input"
              placeholder="e.g. $40 per adult, $60 per couple"
            />
            <span className="input-append-text">USD</span>
          </div>
        </div>
        <div className="knock-react-flex">
          <div className="property-info-edit-field-label">
            Qualification Criteria
          </div>
          <div className="property-info-edit-field-input">
            <textarea
              value={qualificationCriteria}
              onBlur={inputChanged('qualificationCriteria', true)}
              onChange={inputChanged('qualificationCriteria')}
            />
          </div>
        </div>
        <div className="knock-react-flex">
          <div className="property-info-edit-field-label">Instructions</div>
          <div className="property-info-edit-field-input">
            <textarea
              value={applicationInstructions}
              onBlur={inputChanged('applicationInstructions', true)}
              onChange={inputChanged('applicationInstructions')}
              placeholder="Enter any instructions for renters who wish to apply!"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ApplicationInformation.propTypes = {
  inputChanged: PropTypes.func,
  onLeasingProviderChanged: PropTypes.func,
  leasingProvider: PropTypes.string,
  applicationUrl: PropTypes.string,
  applicationFee: PropTypes.string,
  isDefaultApplicationUrlOverridden: PropTypes.bool,
  qualificationCriteria: PropTypes.string,
  applicationInstructions: PropTypes.string,
  errors: PropTypes.object,
  clearErrors: PropTypes.func,
};

export default ApplicationInformation;
