import { useCallback, useEffect, useState } from 'react';
import { initializeApp } from './utils';
import { useServices } from './useServices';

export const appDataInitialState = {
  companyId: null,
  companyName: null,
  isInternalMode: false,
  isLoggedIn: false,
  isReady: false,
  userId: null,
  userType: null,
};

export const useApp = () => {
  const [appData, setAppData] = useState(appDataInitialState);

  const updateAppData = useCallback((dataUpdate) => {
    setAppData((prevState) => ({
      ...prevState,
      ...dataUpdate,
    }));
  }, []);

  const setIsReady = useCallback(
    (isReady) => {
      updateAppData({ isReady });
    },
    [updateAppData]
  );

  useEffect(() => {
    initializeApp(updateAppData);
  }, [updateAppData]);

  const {
    companyId,
    companyName,
    isInternalMode,
    isLoggedIn,
    isReady,
    userId,
    userType,
  } = appData;

  useServices({
    companyId,
    companyName,
    isInternalMode,
    userType,
    userId,
  });

  return {
    companyId,
    companyName,
    isInternalMode,
    isLoggedIn,
    isReady,
    setIsReady,
  };
};
