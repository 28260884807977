import { AuthenticationService } from '@knockrentals/knock-react';
import { DEPLOYMENT_VERSION, ENVIRONMENT } from '../config';
import { datadogRum } from '@datadog/browser-rum';

export function dataDogInit() {
  datadogRum.init({
    applicationId: '36ed6286-5317-4cda-94c3-e3d9757a35cd',
    clientToken: 'pub7f2ffac947e781629abc8e3740087915',
    site: 'datadoghq.com',
    service: 'knock-admin-web',
    env: `${ENVIRONMENT || 'development'}`,
    version: `${DEPLOYMENT_VERSION || '1.0.0'}`,
    replaySampleRate: 100, // deprecated in future version of @datadog/browser-rum
    sampleRate: 25, // deprecated in future version of @datadog/browser-rum
    sessionSampleRate: 25,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

export function setDataDogUser(companyName, companyId, userId) {
  const userData = {
    companyId: companyId,
    companyName: companyName,
    internalLogin: AuthenticationService._internalMode || false,
    userId: userId,
    userLevel: AuthenticationService.getRole(),
  };

  datadogRum.setUser(userData);
}
