import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Button, makeStyles } from '@knockrentals/knock-shared-web';
import CKEditor from 'ckeditor4-react';
import '../../../../RichTextEditor/_RichTextEditor.scss';

CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.21.0/full-all/ckeditor.js';

const setHtmlTagFormatRules = (editor) => {
  var htmlTagNames = ['br', 'li', 'ol', 'p', 'ul'];
  var rules = {
    breakAfterClose: false,
    breakAfterOpen: false,
    breakBeforeClose: false,
    breakBeforeOpen: false,
    indent: false,
  };

  htmlTagNames.forEach((tagName) => {
    editor.dataProcessor.writer.setRules(tagName, rules);
  });
};

const useStyles = makeStyles(() => ({
  templateSection: {
    marginTop: '20px',
  },
}));

const TextEditor = ({
  content,
  onChange,
  onSave,
  charLimit,
  disabled = false,
}) => {
  const classes = useStyles();

  const [editorName, setEditorName] = useState(null);
  const [data, setData] = useState(content || '');
  const [charCount, setCharCount] = useState(0);
  const [ckeditor, setCkeditor] = useState(null);

  useEffect(() => {
    if (editorName) {
      const editorBody = ckeditor.instances[editorName].document.getBody();
      const textContent = editorBody.getText();
      const charLength = textContent.length;
      setCharCount(charLength);
      onChange && onChange(data, charLength);
    }
  }, [data, editorName]);

  const getConfig = () => {
    return {
      extraPlugins: 'emoji, image2',
      removePlugins: 'exportpdf,image',
      dataIndentationChars: '',
      fillEmptyBlocks: false,
      toolbar: [
        { name: 'links', items: ['Link', 'Unlink'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
        { name: 'insert', items: ['EmojiPanel'] },
      ],
      on: {
        change: handleChange,
      },
      linkShowTargetTab: false,
      linkShowAdvancedTab: false,
      versionCheck: false,
    };
  };

  const thisOnChange = (data) => setData(data);

  const handleChange = ({ editor }) => {
    const editorContent = editor.getData();
    thisOnChange(editorContent);
  };

  const isCharLimitExceeded = charCount > charLimit;

  return (
    <div>
      <CKEditor
        config={getConfig()}
        data={content}
        onInstanceReady={({ editor }) => {
          setEditorName(editor.name);
          setHtmlTagFormatRules(editor);
          editor.setReadOnly(disabled);
        }}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.addCss(
            `body{background:${disabled ? '#eeeef2' : '#ffffff'};}`
          );
          setCkeditor(CKEDITOR);
        }}
      />
      {charLimit && !isCharLimitExceeded && (
        <div>{`${charLimit - charCount} characters left`}</div>
      )}
      {charLimit && isCharLimitExceeded && (
        <div>{`Character limit exceeded by ${charCount - charLimit}`}</div>
      )}
      {onSave && (
        <Box className={classes.templateSection}>
          <Button onClick={onSave} disabled={isCharLimitExceeded}>
            Save
          </Button>
        </Box>
      )}
    </div>
  );
};

TextEditor.propTypes = {
  content: PropTypes.string,
  charLimit: PropTypes.number,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default TextEditor;
