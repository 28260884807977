import React, { useState } from 'react';

import {
  Box,
  Divider,
  Tab,
  TabPanel,
  Tabs,
  Text,
  ThemeProvider,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { useAppContext } from '../../Context/AppContext';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import VirtualAgentConfig from './Configuration/VirtualAgentConfig';
import NewVirtualAgentConfig from './NewConfiguration/NewVirtualAgentConfig';
import VirtualAgentResponses from './NewResponses/VirtualAgentResponses';
import VirtualAgentSetupShortcuts from './NewSetupShortcuts/VirtualAgentSetupShortcuts';

const useStyles = makeStyles(() => ({
  tabPanel: {
    marginTop: '10px',
  },

  divider: {
    marginTop: '0 !important',
    position: 'relative',
    top: '-1px',
  },

  subtitle: {
    marginTop: '10px',
    marginBottom: '25px',
  },
}));

const TABS = {
  CONFIG: 'config',
  RESPONSES: 'responses',
  SETUP: 'setup',
};

const TABS_NAMES = {
  config: 'Configuration',
  responses: 'Responses',
  setup: 'Setup Shortcuts',
};

const NewVirtualAgent = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(TABS.RESPONSES);

  const { companyId, isInternalMode: internalMode } = useAppContext();

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const { isShowNewVAConfigurationUiEnabled } = useFeatureFlagContext();

  return (
    <ThemeProvider>
      <Text variant="h6">Virtual Agent</Text>
      <Text variant="body1" className={classes.subtitle}>
        {TABS_NAMES[currentTab]}
      </Text>
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleTabsChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label={TABS_NAMES.responses} value={TABS.RESPONSES} />
          <Tab label={TABS_NAMES.setup} value={TABS.SETUP} />
          {(internalMode || isShowNewVAConfigurationUiEnabled) && (
            <Tab label={TABS_NAMES.config} value={TABS.CONFIG} />
          )}
        </Tabs>
        <Divider className={classes.divider} />
      </Box>

      <TabPanel
        id="ResponsesTabPanel"
        value={currentTab}
        index={TABS.RESPONSES}
        className={classes.tabPanel}
      >
        <VirtualAgentResponses />
      </TabPanel>

      <TabPanel
        id="SetupShortcutsTabPanel"
        value={currentTab}
        index={TABS.SETUP}
        className={classes.tabPanel}
      >
        <VirtualAgentSetupShortcuts companyId={companyId} />
      </TabPanel>

      {(internalMode || isShowNewVAConfigurationUiEnabled) && (
        <TabPanel
          id="ConfigTabPanel"
          value={currentTab}
          index={TABS.CONFIG}
          className={classes.tabPanel}
        >
          {isShowNewVAConfigurationUiEnabled ? (
            <NewVirtualAgentConfig
              companyId={companyId}
              isInternalMode={internalMode}
            />
          ) : (
            <VirtualAgentConfig companyId={companyId} />
          )}
        </TabPanel>
      )}
    </ThemeProvider>
  );
};

export default NewVirtualAgent;
