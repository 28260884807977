import React, { useEffect, useState } from 'react';
import DataTable from 'material-table';

import {
  makeStyles,
  Paper,
  Select,
  LoadingOverlay,
  Autocomplete,
  TextField, Box
} from '@knockrentals/knock-shared-web';
import { NotificationService } from '../../../Components/Notifications';
import PropertiesAPI from '../../Properties/PropertiesAPI';
import { getHousingTypes, getPropertyClasses, getValidYears } from './BenchmarkingAttributesLookupTables';
import { getDefaultTableOptions } from '../../../Services/SharedService';
import { BENCHMARKING_NOTIFICATIONS } from './constants';
import { SAVED_NOTIFICATION } from '../../../constants';

const useStyles = makeStyles(() => ({
  // These styles are needed to correct the icon sizes in the Autocomplete fields since they are getting reduced somehow within this project
  selectYearEndAdornment: {
    '& .MuiAutocomplete-endAdornment': {
      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '20px',
      },

      '& .MuiAutocomplete-popupIndicator': {
        '& .MuiSvgIcon-root': {
          fontSize: '24px',
        },
      },
    },
  },

  // This style removes the bottom margin for the FormControl component that MUI uses inside the Autocomplete, which isn't ideal when it's used
  // in a table
  yearSelection: {
    '& .MuiFormControl-root': {
      marginBottom: 0
    }
  }
}));

const BenchmarkingAttributes = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const getProperties = async () => {
      const propertiesResponse = await PropertiesAPI.getCommunityInfo();

      const propertyData = propertiesResponse.properties.map(property => {
        const benchmarking = {
          housingType: '0',
          propertyClass: '0',
          yearBuilt: null,
          yearRenovated: null
        };

        if (property.data.benchmarking) {
          benchmarking.housingType = property.data.benchmarking.housingType ? property.data.benchmarking.housingType : '0';
          benchmarking.propertyClass = property.data.benchmarking.propertyClass ? property.data.benchmarking.propertyClass : '0';
          benchmarking.yearBuilt = property.data.benchmarking.yearBuilt;
          benchmarking.yearRenovated = property.data.benchmarking.yearRenovated;
        }

        return {
          propertyId: property.id,
          propertyName: property.data.location.name,
          preferences: property.preferences,
          data: { ...property.data, benchmarking },
          propertyCustomFees: property.custom_fees,
          hasBenchmarkData: false
        };
      });

      propertyData.sort((lValue, rValue) => {
        return lValue.propertyName >= rValue.propertyName ? 1 : -1;
      });

      setProperties(propertyData);
    };

    if (properties.length === 0) {
      setIsLoading(true);

      getProperties().catch(() => {
        NotificationService.notify(BENCHMARKING_NOTIFICATIONS.ERROR_FETCHING);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const saveData = async (field, newValue, propertyId) => {
    const newPropertyData = [...properties];
    const index = newPropertyData.findIndex(item => item.propertyId === propertyId);

    // The Autocomplete fields for the year values allow you to re-select the current value, and it will fire a change event in that case. We don't
    // want to make an unneeded request, so we exit if the value is unchanged.
    if (newValue === newPropertyData[index].data.benchmarking[field]) {
      return;
    }

    const newBenchmarkingData = { [field]: newValue };

    try {
      await PropertiesAPI.updateProperty(propertyId, {
        data: { ...newPropertyData[index].data, benchmarking: newBenchmarkingData },
        preferences: newPropertyData[index].preferences,
        propertyCustomFees: newPropertyData[index].propertyCustomFees
      });

      NotificationService.notify(SAVED_NOTIFICATION);

      newPropertyData[index].data.benchmarking[field] = newValue;
      setProperties(newPropertyData);
    } catch (error) {
      NotificationService.notify(BENCHMARKING_NOTIFICATIONS.ERROR_SAVING_SETTINGS);
    }
  };

  const getColumns = () => {
    return [
      ['Property', 'propertyName', undefined, '350px'],
      ['Housing Type', 'housingType', 'string', '320px', (rowData) => {
        return (
          <Select
            options={getHousingTypes()}
            name="housing type"
            aria-label="Select a housing type"
            defaultValue={rowData.data.benchmarking.housingType}
            fullWidth={true}
            noForm={true}
            onSelectChange={value => saveData('housingType', value, rowData.propertyId)}
          />
        );
      }],
      ['Property Class', 'propertyClass', 'string', '180px', (rowData) => {
        return (
          <Select
            options={getPropertyClasses()}
            name="property class"
            aria-label="Select a property class"
            defaultValue={rowData.data.benchmarking.propertyClass}
            fullWidth={true}
            noForm={true}
            onSelectChange={value => saveData('propertyClass', value, rowData.propertyId)}
          />
        );
      }],
      ['Year Built', 'yearBuilt', 'string', '160px', (rowData) => {
        return (
          <Box className={classes.yearSelection}>
            <Autocomplete
              options={getValidYears()}
              value={{ label: rowData.data.benchmarking.yearBuilt, value: rowData.data.benchmarking.yearBuilt }}
              getOptionLabel={(option) => option ? (option.label ? option.label.toString() : '') : ''}
              onChange={(event, option) => {
                if (option) {
                  return saveData('yearBuilt', option.value, rowData.propertyId);
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  className={classes.selectYearEndAdornment}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              }
            />
          </Box>
        );
      }],
      ['Year Renovated', 'yearRenovated', 'string', '152px', (rowData) => {
        return (
          <Box className={classes.yearSelection}>
            <Autocomplete
              options={getValidYears()}
              value={{ label: rowData.data.benchmarking.yearRenovated, value: rowData.data.benchmarking.yearRenovated }}
              getOptionLabel={(option) => option ? (option.label ? option.label.toString() : '') : ''}
              onChange={(event, option) => {
                if (option) {
                  return saveData('yearRenovated', option.value, rowData.propertyId);
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  className={classes.selectYearEndAdornment}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              }
            />
          </Box>
        );
      }]
    ];
  };

  const getColumnHeaderConfig = (column) => {
    const [title, field, type, width, render] = column;

    return {
      align: 'left',
      title,
      field,
      type,
      width,
      cellStyle: {
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      },
      render
    };
  };

  const columns = getColumns().map(getColumnHeaderConfig);

  return (
    <React.Fragment>
      <LoadingOverlay open={isLoading} style={{ position: 'absolute', opacity: "0.5" }} />

      <DataTable
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={1} />,
        }}
        data={properties}
        options={{ ...getDefaultTableOptions(), tableLayout: 'fixed' }}
        style={{ width: 'min-content' }}
      />
    </React.Fragment>
  );
};

export default BenchmarkingAttributes;
