import React, { useEffect, useState } from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import PropTypes, { object } from 'prop-types';
import DataTable from 'material-table';
import {
  InputCheckbox,
  LoadingOverlay,
  makeStyles,
  Select,
  Switch,
  ThemeProvider,
} from '@knockrentals/knock-shared-web';
import { Toaster } from '@knockrentals/knock-react';

import EditButton from './Components/EditButton';
import {
  FilterMulti,
  FilterProperty,
  FilterRadio,
} from './Components/FilterBoxes';
import { getVirtualAgents, updateVirtualAgent } from '../VirtualAgentAPI';
import { editAiEmailEnabled } from '../aiEmailAPI';
import { getOfferTimes } from '../VirtualAgentLookupTables';

const useStyles = makeStyles({
  ringTimeSelect: {
    border: 0,
    padding: 0,
    fontWeight: 550,
    textAlign: 'center',

    '& #ring-time-dropdown': {
      paddingRight: '0'
    }
  },
  callRoutingSelect: {
    border: 0,
    borderBottom: '1px solid #DEDFE6',
    backgroundColor: '#FAFAFA',
    width: '150px',
    paddingRight: '15px'
  },
  filterAndEditContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
  }
});

export const FIELDS = {
  CALL_ROUTING: 'call_routing',
  OFFER_TIMES: 'offer_time',
  ANSWER_VERIFICATION: 'is_answer_verification',
  IS_GEN_AI_VOICE_ENABLED: 'is_gen_ai_voice_enabled',
  RPCC_AGENT_ROLLOVER: 'rpcc_agent_rollover',
  IS_CHAT_ENABLED: 'is_chat_enabled',
  IS_SMS_ENABLED: 'is_sms_enabled',
  IS_AI_EMAIL_ENABLED: 'ai_email_enabled',
  SCHEDULE_TOUR_VA_ENABLED: 'schedule_tour_va_enabled',
  PNA_VA_ENABLED: 'pna_va_enabled',
};

export const OFFER_TIMES = Array(25)
  .fill(0)
  .map((_, i) => ({ label: `${i + 6} seconds`, value: i + 6 }));

export const CALL_ROUTING = [
  {
    label: 'All Calls',
    value: 'ALL',
  },
  {
    label: 'Missed Calls',
    value: 'MISSED_CALLS',
  },
  {
    label: 'Off',
    value: 'OFF',
  },
];

export const API_ERROR = 'Error updating virtual agent';

const AiVoiceSettings = ({ properties, isInternalMode, companyId }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [virtualAgents, setVirtualAgents] = useState([]);
  const [fetchedVirtualAgents, setFetchedVirtualAgents] = useState(false);
  const [selectedVirtualAgents, setSelectedVirtualAgents] = useState([]);

  const [filteredVirtualAgents, setFilteredVirtualAgents] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState(
    properties.map(({ propertyId }) => propertyId)
  );
  const [filters, setFilters] = useState({});

  const tableOptions = {
    actionsColumnIndex: -1,
    draggable: false,
    grouping: false,
    headerStyle: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '32px',
      position: 'static',
    },
    padding: 'dense',
    paging: false,
    rowStyle: {
      fontSize: '14px',
      fontWeight: 400,
    },
    search: false,
    sorting: false,
    toolbar: false,
  };

  useEffect(() => {
    const getVirtualAgentsData = async () => {
      const propertyIds = properties.map(({ propertyId }) => propertyId);
      const virtualAgentsResponse = await getVirtualAgents(
        companyId,
        1,
        1000
      );
      const virtualAgents = (virtualAgentsResponse.results || [])
        .filter(virtualAgent => propertyIds.includes(virtualAgent.property_id))
        .map((virtualAgent) => {
          const property = properties.find(
            (property) => property.propertyId === virtualAgent.property_id
          );
          return {
            ...virtualAgent,
            propertyName: property.propertyName,
            ai_email_enabled: property.aiEmailEnabled
          };
        });

      virtualAgents.sort((a, b) => a.propertyName.localeCompare(b.propertyName));
      setVirtualAgents(virtualAgents);
      setFilteredVirtualAgents(virtualAgents);
    };

    if (!fetchedVirtualAgents && properties.length) {
      setIsLoading(true);
      getVirtualAgentsData()
        .catch(() => {
          Toaster.showToast(
            'Error fetching virtual agent data',
            2000,
            Toaster.ToastClasses.error
          );
        })
        .finally(() => {
          setFetchedVirtualAgents(true);
          setIsLoading(false);
        });
    }
  }, [fetchedVirtualAgents]);

  const applyFilters = (currentFilters, currentSelectedProperties) => {
    const filteredVirtualAgents = virtualAgents.filter((virtualAgent) => {
      const isRowValid = Object.keys(currentFilters).every(key => {
        if (currentFilters[key] === null) {
          return true;
        } else if (Array.isArray(currentFilters[key])) {
          return currentFilters[key].includes(virtualAgent[key]);
        } else if (key === 'ai_email_enabled') {
          return (currentFilters[key] && virtualAgent[key] === 'ON') || (!currentFilters[key] && virtualAgent[key] !== 'ON');
        } else {
          return virtualAgent[key] === currentFilters[key];
        }
      })

      const isSelected = currentSelectedProperties.length
        ? currentSelectedProperties.includes(virtualAgent.property_id)
        : true;

      return isRowValid && isSelected;
    });

    setFilteredVirtualAgents(filteredVirtualAgents);
  };

  const handleFilterChange = (field, value) => {
    if (value === null) {
      const { [field]: removedKey, ...restFilters } = filters;
      applyFilters(restFilters, selectedProperties);
      setFilters(restFilters);
    } else {
      setFilters((prevFilters) => {
        const updatedFilters = {
          ...prevFilters,
          [field]: value,
        };
        applyFilters(updatedFilters, selectedProperties);
        return updatedFilters;
      });
    }
  };

  const handlePropertySelect = (properties) => {
    applyFilters(filters, properties);
    setSelectedProperties(properties);
  };

  const handleSelectAgent = (virtualAgentId) => {
    const idx = selectedVirtualAgents.findIndex((id) => id === virtualAgentId);
    setSelectedVirtualAgents((prevselectedVirtualAgents) => {
      if (idx === -1) {
        return [...prevselectedVirtualAgents, virtualAgentId];
      } else {
        return prevselectedVirtualAgents.filter((id) => id !== virtualAgentId);
      }
    });
  };

  const handleSelectAllOnChange = (e) => {
    const selectedCount = selectedVirtualAgents.length;
    const isSelectAllChecked =
      selectedVirtualAgents.length === virtualAgents.length;
    const isSelectAllIndeterminate = selectedCount > 0 && !isSelectAllChecked;

    if (isSelectAllIndeterminate || selectedCount === 0) {
      setSelectedVirtualAgents(virtualAgents.map(({ id }) => id));
    } else {
      setSelectedVirtualAgents([]);
    }
  };

  const isCallRoutingMissed = (field) => {
    return field === 'MISSED_CALLS'
  };

  const saveAiEmail = async (rowData, value) => {
    const payload = [
      {
        property_id: rowData.property_id,
        company_id: companyId,
        [FIELDS.IS_AI_EMAIL_ENABLED]: value ? 'ON' : 'SETUP'
      }
    ];

    try {
      await editAiEmailEnabled(payload);
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      rowData[FIELDS.IS_AI_EMAIL_ENABLED] = value ? 'ON' : 'SETUP';

      const existingIdx = filteredVirtualAgents.findIndex((va) => va.id === rowData.id);
      setFetchedVirtualAgents([
        ...filteredVirtualAgents.slice(0, existingIdx),
        rowData,
        ...filteredVirtualAgents.slice(existingIdx + 1, filteredVirtualAgents.length)
      ]);
    } catch (err) {
      console.error(err);
      Toaster.showToast(
        API_ERROR,
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const saveVaData = async (rowData, field, value) => {
    const payload = {
      virtualAgentId: rowData.id,
      propertyId: rowData.property_id,
      field,
      value
    };

    try {
      await updateVirtualAgent(payload);
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      rowData[field] = value;

      const existingIdx = filteredVirtualAgents.findIndex((va) => va.id === rowData.id);
      setFetchedVirtualAgents([
        ...filteredVirtualAgents.slice(0, existingIdx),
        rowData,
        ...filteredVirtualAgents.slice(existingIdx + 1, filteredVirtualAgents.length)
      ]);
    } catch (err) {
      console.error(err);
      Toaster.showToast(
        API_ERROR,
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const getColumns = (classes) => {
    const tableArray = [
      {
        align: 'left',
        width: '50px',
        title: (
          <InputCheckbox
            color="primary"
            onChange={handleSelectAllOnChange}
            checked={!!virtualAgents.length && selectedVirtualAgents.length === virtualAgents.length}
            indeterminate={
              selectedVirtualAgents.length > 0 &&
              selectedVirtualAgents.length < virtualAgents.length
            }
          />
        ),
        render: (rowData) => {
          return (
            <InputCheckbox
              color="primary"
              onChange={() => handleSelectAgent(rowData.id)}
              checked={selectedVirtualAgents.includes(rowData.id)}
            />
          );
        },
      },
      {
        title: 'Properties',
        width: '100px',
        render: (rowData) => rowData.propertyName
      },
      {
        title: 'Call Routing',
        field: FIELDS.CALL_ROUTING,
        width: '150px',
        render: (rowData) => {
          return (
            <Select
              noForm
              className={classes.callRoutingSelect}
              options={CALL_ROUTING}
              defaultValue={rowData[FIELDS.CALL_ROUTING]}
              onSelectChange={(value) => {
                saveVaData(rowData, FIELDS.CALL_ROUTING, value);
              }}
              IconComponent={() => (<KeyboardArrowDown />)}
            />
          );
        },
      },
      {
        title: 'Ring Time',
        field: FIELDS.OFFER_TIMES,
        width: '150px',
        render: (rowData) => {
          return (
            <Select
              noForm
              id={'ring-time-dropdown'}
              className={classes.ringTimeSelect}
              options={getOfferTimes()}
              defaultValue={rowData[FIELDS.OFFER_TIMES]}
              value={rowData[FIELDS.OFFER_TIMES]}
              onSelectChange={(value) => {
                saveVaData(rowData, FIELDS.OFFER_TIMES, value);
              }}
              IconComponent={() => (<ArrowDropDown />)}
            />
          );
        },
      },
    ];

    if (isInternalMode) {
      tableArray.push(
        {
          title: 'Answer Verification',
          field: FIELDS.ANSWER_VERIFICATION,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={!!rowData.is_answer_verification}
                disabled={!isCallRoutingMissed(rowData[FIELDS.CALL_ROUTING])}
                onChange={(event) =>
                  saveVaData(rowData, FIELDS.ANSWER_VERIFICATION, event.target.checked)
                }
              />
            );
          },
        },
        {
          title: 'AI Chat',
          field: FIELDS.IS_CHAT_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={!!rowData.is_chat_enabled}
                onChange={(event) =>
                  saveVaData(rowData, FIELDS.IS_CHAT_ENABLED, event.target.checked)
                }
              />
            );
          },
        },
        {
          title: 'AI SMS',
          field: FIELDS.IS_SMS_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={!!rowData.is_sms_enabled}
                onChange={(event) =>
                  saveVaData(rowData, FIELDS.IS_SMS_ENABLED, event.target.checked)
                }
              />
            );
          },
        },
        {
          title: 'AI Email',
          field: FIELDS.IS_AI_EMAIL_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={rowData[FIELDS.IS_AI_EMAIL_ENABLED] === 'ON'}
                disabled={rowData.aiEmailEnabled === 'OFF'}
                onChange={(event) =>
                  saveAiEmail(rowData, event.target.checked)
                }
              />
            );
          },
        },
        {
          title: 'P&A Flow',
          field: FIELDS.PNA_VA_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={!!rowData.pna_va_enabled}
                onChange={(event) =>
                  saveVaData(rowData, FIELDS.PNA_VA_ENABLED, event.target.checked)
                }
              />
            );
          },
        },
        {
          title: 'Schedule Tour Flow',
          field: FIELDS.SCHEDULE_TOUR_VA_ENABLED,
          width: '100px',
          render: (rowData) => {
            return (
              <Switch
                color="primary"
                checked={!!rowData.schedule_tour_va_enabled}
                onChange={(event) =>
                  saveVaData(rowData, FIELDS.SCHEDULE_TOUR_VA_ENABLED, event.target.checked)
                }
              />
            );
          },
        }
      );
    }

    return tableArray;
  };

  return (
    <ThemeProvider>
      <div className={classes.filterAndEditContainer}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <FilterProperty
            properties={properties.map((property) => ({
              id: property.propertyId,
              name: property.propertyName,
              selected: selectedProperties.includes(property.propertyId),
            }))}
            onPropertySelect={handlePropertySelect}
          />
          <FilterMulti
            filterName={'Call Routing'}
            onFilterChange={(value) =>
              handleFilterChange(FIELDS.CALL_ROUTING, value)
            }
            filterOptions={CALL_ROUTING}
          />
          <FilterMulti
            filterName={'Ring Time'}
            onFilterChange={(value) => handleFilterChange(FIELDS.OFFER_TIMES, value)}
            filterOptions={OFFER_TIMES}
          />
          {isInternalMode && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <FilterRadio
                filterName={'Answer Verification'}
                onFilterChange={(value) =>
                  handleFilterChange(FIELDS.ANSWER_VERIFICATION, value)
                }
              />
              <FilterRadio
                filterName={'AI Chat'}
                onFilterChange={(value) =>
                  handleFilterChange(FIELDS.IS_CHAT_ENABLED, value)
                }
              />
              <FilterRadio
                filterName={'AI SMS'}
                onFilterChange={(value) =>
                  handleFilterChange(FIELDS.IS_SMS_ENABLED, value)
                }
              />

              <FilterRadio
                filterName={'AI Email'}
                onFilterChange={(value) =>
                  handleFilterChange(FIELDS.IS_AI_EMAIL_ENABLED, value)
                }
              />
              <FilterRadio
                filterName={'P&A'}
                onFilterChange={(value) =>
                  handleFilterChange(FIELDS.PNA_VA_ENABLED, value)
                }
              />
              <FilterRadio
                filterName={'Schedule Tour'}
                onFilterChange={(value) =>
                  handleFilterChange(FIELDS.SCHEDULE_TOUR_VA_ENABLED, value)
                }
              />
            </div>
          )}
        </div>
      </div>
      <EditButton />
      <LoadingOverlay
        open={isLoading}
        style={{ position: 'absolute', opacity: '0.5' }}
      />
      <DataTable
        className="scheduling-grid-data-table"
        columns={getColumns(classes)}
        data={filteredVirtualAgents}
        options={tableOptions}
      />
    </ThemeProvider>
  );
};

AiVoiceSettings.propTypes = {
  properties: PropTypes.arrayOf(object),
  companyId: PropTypes.number,
  isInternalMode: PropTypes.bool,
};

export default AiVoiceSettings;
