import React, { useEffect, useState } from 'react';
import { Text, makeStyles } from '@knockrentals/knock-shared-web';
import FeatureDrawer from '../../../Features/common/FeatureDrawer';
import FeatureCard from '../../../Features/common/FeatureCard';
import FeatureCardContent from '../../../Features/common/FeatureCardContent';
import { WarningBanner } from '../../../Features/CallIntelPage/BulkPropertiesUpdateDrawer/WarningBanner';
import { PropertyChips } from '../../../Features/common/PropertyChips';
import AiBulkEditFeatures from './AiBulkEditFeatures';
import { FeatureActions } from '../../../Features/common/FeatureActions';
import CancelBulkEditModal from './CancelBulkEditModal';

const useStyles = makeStyles({
  chipsSpacing: {
    marginBottom: '24px'
  }
});

const DEFAULT_FEATURE_STATE = [
  { field: "Voice", value: undefined, equal: undefined },
  { field: "Chat", value: undefined, equal: undefined },
  { field: "SMS", value: undefined, equal: undefined },
  { field: "Email", value: undefined, equal: undefined }
]

const AiBulkEditDrawer = ({
  isEditDrawerOpen,
  setIsEditDrawerOpen,
  title,
  properties
}) => {
  const classes = useStyles();

  const [featureStates, setFeatureStates] = useState(DEFAULT_FEATURE_STATE);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const checkPropertyValuesEqual = (selectedProperties) => {
    const isVoiceProductEnabledEqual = selectedProperties.every(property => property.voiceProductEnabled === selectedProperties[0].voiceProductEnabled);
    const isChatProductEnabledEqual = selectedProperties.every(property => property.chatProductEnabled === selectedProperties[0].chatProductEnabled);
    const isSmsProductEnabledEqual = selectedProperties.every(property => property.smsProductEnabled === selectedProperties[0].smsProductEnabled);
    const isEmailProductEnabledEqual = selectedProperties.every(property => property.emailProductEnabled === selectedProperties[0].emailProductEnabled);
    if (!isVoiceProductEnabledEqual || !isChatProductEnabledEqual ||
      !isSmsProductEnabledEqual || !isEmailProductEnabledEqual
    ) {
      setShowWarning(true);
    }
    setFeatureStates([
      { field: "Voice", value: selectedProperties[0].voiceProductEnabled, equal: isVoiceProductEnabledEqual },
      { field: "Chat", value: selectedProperties[0].chatProductEnabled, equal: isChatProductEnabledEqual },
      { field: "SMS", value: selectedProperties[0].smsProductEnabled, equal: isSmsProductEnabledEqual },
      { field: "Email", value: selectedProperties[0].emailProductEnabled !== 'OFF', equal: isEmailProductEnabledEqual }
    ]);
  }

  const handleEditFeatures = (obj) => {
    setIsChangesMade(true);
    const [field, value] = Object.entries(obj)[0];
    setFeatureStates(prevState => 
      prevState.map(feature =>
        feature.field === field ?
          {...feature, value: value, equal: true}
          : feature
      )
    );
  }
  
  const handleCancelBulkEditDrawer = () => {
    if (!isChangesMade) {
      setIsEditDrawerOpen(false);
      setShowWarning(false);
    } else {
      setShowModal(true);
    }
  }

  const handleCancelBulkEditModal = (isContinue = false) => {
    setShowModal(false);
    if (isContinue) {
      setIsEditDrawerOpen(false);
      setIsChangesMade(false);
      setShowWarning(false);
    }
  }

  useEffect(() => {
    setShowWarning(false);
    if (properties.length) {
      checkPropertyValuesEqual(properties);
    }
  }, [properties])

  return (
    <FeatureDrawer disableEnforceFocus isOpen={isEditDrawerOpen}>
      <FeatureCard
        closeDrawer={handleCancelBulkEditDrawer}
        subheader={title}
      >
        {showModal && (
          <CancelBulkEditModal isOpen={showModal} handleClose={handleCancelBulkEditModal} />
        )}
        <FeatureCardContent>
          {showWarning && <WarningBanner virtualAgent={true}/>}
          <Text variant="body1">Selected Properties</Text>
          <div className={classes.chipsSpacing}>
            <PropertyChips properties={properties} />
          </div>
          <AiBulkEditFeatures featureStates={featureStates} handleEditFeatures={handleEditFeatures} />
        </FeatureCardContent>
        <FeatureActions 
          handleCancelOnClick={handleCancelBulkEditDrawer}
          isSaveButtonDisabled={!isChangesMade}
        />
      </FeatureCard>
  </FeatureDrawer>
  );
}

export default AiBulkEditDrawer;