import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import {
  getCustomStyleText,
  DEFAULT_EDITOR_HEIGHT,
  DEFAULT_MARGIN_TOP,
} from './utils';

const PREVIEW_PLACEHOLDER_TEXT = `<p style="line-height: ${
  DEFAULT_EDITOR_HEIGHT - 2 * DEFAULT_MARGIN_TOP
}px" >Text entered in the form above will appear here</p>`;

CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.21.0/full-all/ckeditor.js';

export const FOOTER_PREVIEW_ARIA_LABEL = 'brand footer content preview';
const EDITOR_NAME = 'brand-footer-content-preview';
const EDITOR_BODY_CLASS = `${EDITOR_NAME}-body`;

const editorConfig = {
  allowedContent: true,
  bodyClass: EDITOR_BODY_CLASS,
  height: DEFAULT_EDITOR_HEIGHT,
  removeDialogTabs: 'image:advanced;link:advanced;table:advanced',
  removePlugins:
    'exportpdf,image,liststyle,tableselection,tabletools,scayt,menubutton,contextmenu,language,uploadwidget,pastefromgdocs,widget,pastefromlibreoffice,pastefromword,pastetext,pastetools,uploadimage,elementspath,clipboard,toolbar,resize',
  resize_enabled: false,
  startupShowBorders: false,
  versionCheck: false,
};

const BrandFooterPreview = ({
  brandColor,
  footerContent,
  isBrandColorMixed,
  textColor,
}) => {
  const customStyleText = getCustomStyleText({
    bodyClassName: EDITOR_BODY_CLASS,
    brandColor,
    isBrandColorMixed,
    textColor,
  });

  const previewContent = footerContent || PREVIEW_PLACEHOLDER_TEXT;

  return (
    <div className={EDITOR_NAME} aria-label={FOOTER_PREVIEW_ARIA_LABEL}>
      <CKEditor
        config={editorConfig}
        style={{ width: '100%', border: 'none' }}
        data={previewContent}
        name={EDITOR_NAME}
        readOnly
        onInstanceReady={({ editor }) => {
          editor.on('contentDom', function (event) {
            event.editor.document.appendStyleText(customStyleText);
          });
        }}
      />
    </div>
  );
};

BrandFooterPreview.propTypes = {
  brandColor: PropTypes.string,
  footerContent: PropTypes.string,
  isBrandColorMixed: PropTypes.bool,
  textColor: PropTypes.string,
};

export default BrandFooterPreview;
