import { apiRemote } from '../../Services/RemoteService';

class AccessAPI {
  static getCompanyAccessSettings() {
    return apiRemote
      .get(`admin/access`)
      .then(response => response.json())
      .catch(e => e);
  }

  static setCompanyAccessSettings(updatedAccessSettings) {
    return apiRemote
      .post(`admin/access/`, { ...updatedAccessSettings })
      .then(response => response.json())
      .catch(e => e);
  }
}

export default AccessAPI;
