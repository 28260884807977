import React, { useState } from 'react';
import { Menu, MenuItem, ThemeProvider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { VerticalNavbarLink } from '@knockrentals/knock-react';
import { makeStyles } from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  filterRadio: {
    display: 'flex',
    position: 'relative',
  },
  customMenuItem: {
    paddingRight: '100px !important',
  },
  filterHeader: {
    backgroundColor: '#f0f0f0',
    borderRadius: '20px',
    padding: '8px 10px 8px 15px',
    textDecoration: 'none',
    cursor: 'pointer',
    border: '1px solid white',
  },
  filterHeaderClicked: {
    border: '1px solid blue',
    borderRadius: '20px',
    backgroundColor: '#B0C4DE',
  },
  arrowIconUp: {
    transform: 'rotate(180deg)',
  },
}));

const FilterRadio = ({ onFilterChange, filterName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterDisplay, setSelectedFilterDisplay] = useState('All');
  const [clicked, setClicked] = useState(false);
  const classes = useStyles();

  const handleOpenDropdown = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setClicked(true);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setClicked(false);
  };

  const handleFilterChange = (filter) => {
    const display =
      (filter === null && 'All') ||
      (filter && 'On') ||
      (filter === false && 'Off');
    setSelectedFilterDisplay(display);
    setSelectedFilter(filter);
    onFilterChange(filter);
    handleCloseDropdown();
  };

  return (
    <ThemeProvider>
      <div className={`${classes.filterRadio}`}>
        <div
          className={`${classes.filterHeader} ${
            clicked ? classes.filterHeaderClicked : ''
          }`}
          onClick={handleOpenDropdown}
          style={{ color: 'black' }}
        >
          <VerticalNavbarLink>
            {filterName}: {selectedFilterDisplay}{' '}
            <i
              className={`${'fa fa-angle-down fa-fw support-dropdown-icon '}${
                clicked ? classes.arrowIconUp : ''
              }`}
            />
          </VerticalNavbarLink>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseDropdown}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{ marginTop: '55px' }}
        >
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleFilterChange(null);
            }}
            className={classes.customMenuItem}
            data-testid="all-filter"
          >
            <Radio
              color="primary"
              checked={selectedFilter === null}
              onClick={(event) => {
                event.stopPropagation();
                handleFilterChange(null);
              }}
            />{' '}
            All
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleFilterChange(true);
            }}
            className={classes.customMenuItem}
            data-testid="on-filter"
          >
            <Radio
              color="primary"
              checked={selectedFilter === true}
              onClick={(event) => {
                event.stopPropagation();
                handleFilterChange(true);
              }}
            />{' '}
            On
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleFilterChange(false);
            }}
            className={classes.customMenuItem}
            data-testid="off-filter"
          >
            <Radio
              color="primary"
              checked={selectedFilter === false}
              onClick={(event) => {
                event.stopPropagation();
                handleFilterChange(false);
              }}
            />{' '}
            Off
          </MenuItem>
        </Menu>
      </div>
    </ThemeProvider>
  );
};

FilterRadio.propTypes = {
  onFilterChange: PropTypes.func,
  filterName: PropTypes.string,
};

export default FilterRadio;
