import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import {
  VerticalNavbar,
  VerticalNavbarLink,
  AuthenticationService,
} from '@knockrentals/knock-react';
import CookieService from '../../Services/CookieService';
import { useAppContext } from '../../Context/AppContext';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import SupportNavbarLink from '../../Components/SupportNavbarLink';
import NavHeader from './NavHeader';
import AccessAPI from '../../Pages/Access/AccessAPI';
import { isMasterRole } from '../utils';
import { BASE_ANALYTICS_URI } from '../../config';

export const IS_SHEETS_LINK_VISIBLE = false;

const NavigationBar = () => {
  const {
    isCallIntelStandaloneEnabled,
    isBrandContentEnabled,
    isUseCookieForTokenEnabled,
  } = useFeatureFlagContext();

  const { companyId, isInternalMode } = useAppContext();
  const [userHasAttributesAccess, setUserHasAttributesAccess] = useState(false);

  useEffect(() => {
    const getUserHasAttributesAccess = async () => {
      const { access: { settings: accessSettings = {} } = {} } =
        await AccessAPI.getCompanyAccessSettings();

      const role = AuthenticationService.getRole();

      setUserHasAttributesAccess(
        isMasterRole() ||
          (accessSettings.attribute_page.indexOf(role) > -1 &&
            accessSettings.company_id === companyId)
      );
    };

    getUserHasAttributesAccess();
  }, []);

  const openAnalytics = async () => {
    const tokenValue = AuthenticationService.getToken();
    if (isUseCookieForTokenEnabled) {
      CookieService.setTokenCookie(tokenValue, 'accessToken');
      window.open(BASE_ANALYTICS_URI + '/login', 'noopener,noreferrer');
    } else {
      window.open(
        BASE_ANALYTICS_URI + '/login?accessToken=' + tokenValue,
        'noopener,noreferrer'
      );
    }
  };

  return (
    <VerticalNavbar>
      <NavHeader />

      <NavLink to="/integrations">
        <VerticalNavbarLink>
          <i className="fa fa-cogs fa-fw" /> Integrations
        </VerticalNavbarLink>
      </NavLink>

      <NavLink to="/sources">
        <VerticalNavbarLink>
          <i className="fa fa-share-alt fa-fw" /> Sources
        </VerticalNavbarLink>
      </NavLink>

      <NavLink to="/users">
        <VerticalNavbarLink>
          <i className="fa fa-users fa-fw" /> Users
        </VerticalNavbarLink>
      </NavLink>

      <NavLink to="/properties">
        <VerticalNavbarLink>
          <i className="fa fa-building fa-fw" /> Properties
        </VerticalNavbarLink>
      </NavLink>

      {isInternalMode && (
        <NavLink to="/syndication">
          <VerticalNavbarLink>
            <i className="fa fa-code-fork fa-fw" /> Syndication
          </VerticalNavbarLink>
        </NavLink>
      )}

      <NavLink to="/pricing">
        <VerticalNavbarLink>
          <i className="fa fa-dollar fa-fw" /> Pricing
        </VerticalNavbarLink>
      </NavLink>

      <NavLink to="/scheduling">
        <VerticalNavbarLink>
          <i className="fa fa-sliders fa-fw" /> Scheduling
        </VerticalNavbarLink>
      </NavLink>

      {isBrandContentEnabled && (
        <NavLink to="/brand-content">
          <VerticalNavbarLink>
            <i className="fa fa-palette fa-fw" /> Brand Content
          </VerticalNavbarLink>
        </NavLink>
      )}

      <NavLink to="/doorway">
        <VerticalNavbarLink>
          <i className="fa fa-calendar-days fa-fw" /> Doorway
        </VerticalNavbarLink>
      </NavLink>

      <NavLink to="/drip-campaign">
        <VerticalNavbarLink>
          <i className="fa fa-list-check fa-fw" /> Drip Campaign
        </VerticalNavbarLink>
      </NavLink>

      {IS_SHEETS_LINK_VISIBLE && (
        <NavLink to="/sheets">
          <VerticalNavbarLink>
            <i className="fa fa-upload fa-fw" /> Sheets
          </VerticalNavbarLink>
        </NavLink>
      )}

      <NavLink to="/templates">
        <VerticalNavbarLink>
          <i className="fa fa-edit fa-fw" /> Templates
        </VerticalNavbarLink>
      </NavLink>

      <NavLink to="/ai-templates">
        <VerticalNavbarLink>
          <i className="fa fa-edit fa-fw" /> AI Templates
        </VerticalNavbarLink>
      </NavLink>

      <NavLink to="/quick-replies">
        <VerticalNavbarLink>
          <i className="fa fa-reply fa-fw" /> Quick Replies
        </VerticalNavbarLink>
      </NavLink>

      {userHasAttributesAccess && (
        <NavLink to="/attributes">
          <VerticalNavbarLink>
            <i className="fa fa-table fa-fw" /> Attributes
          </VerticalNavbarLink>
        </NavLink>
      )}

      {isMasterRole() && (
        <NavLink to="/access">
          <VerticalNavbarLink>
            <i className="fa fa-lock fa-fw" /> Access
          </VerticalNavbarLink>
        </NavLink>
      )}

      <NavLink to="/admins">
        <VerticalNavbarLink>
          <i className="fa fa-cogs fa-fw" /> Admins
        </VerticalNavbarLink>
      </NavLink>

      <a href={'#'} onClick={() => openAnalytics()}>
        <VerticalNavbarLink>
          <i className="fa fa-chart-column fa-fw" /> Analytics
        </VerticalNavbarLink>
      </a>

      {Boolean(companyId) && (
        <NavLink to="/virtual-agent">
          <VerticalNavbarLink>
            <i className="fa fa-robot fa-fw" /> Virtual Agent
          </VerticalNavbarLink>
        </NavLink>
      )}

      {isCallIntelStandaloneEnabled && isInternalMode && (
        <NavLink to="/call-intel">
          <VerticalNavbarLink>
            <i className="fa fa-phone fa-fw" /> Call Intel
          </VerticalNavbarLink>
        </NavLink>
      )}

      <NavLink to="/tours">
        <VerticalNavbarLink>
          <i className="fa fa-map fa-fw" /> Knock Tours
        </VerticalNavbarLink>
      </NavLink>

      <Link to="/logout">
        <VerticalNavbarLink>
          <i className="fa fa-power-off fa-fw" /> Log out
        </VerticalNavbarLink>
      </Link>

      <SupportNavbarLink />
    </VerticalNavbar>
  );
};

export default NavigationBar;
