import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';
import PropertyList from '../../../../Components/PropertyList';
import SearchBar from '../../../../Components/SearchBar';
import { ErrorDialog } from '../../../Features/common/ErrorDialog';
import { useProperties } from '../../NewSetupShortcuts/useProperties';

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: '16px',
  },
  mBottom20: {
    marginBottom: '20px',
  },
}));

/**
 * This component allows the user to copy all voice responses in all intents
 * and groups into the corresponding chat response fields
 */
const VirtualAgentResponsesDownloadModal = ({
  isOpen,
  closeDialog,
  handleConfirmation,
  setErrorMessage,
  errorMessage,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    getSelectedProperties,
    selectedPropertiesCount,
    selectedPropertiesMap,
    setSelectedPropertiesMap,
    propertiesList,
  } = useProperties({
    setErrorMessage,
  });

  const handleSelectOnClick = () => {
    const selectedProperties = getSelectedProperties();
    const propertyIds = selectedProperties.map(
      (property) => property.propertyId,
    );
    handleConfirmation(propertyIds);
    setSelectedPropertiesMap({});
    setSearchTerm('');
  };

  const handleClose = () => {
    closeDialog();
    setSearchTerm('');
  };

  const getPropertiesList = () => {
    const filteredProperties = propertiesList.filter((property) =>
        property.propertyName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const sortedProperties = filteredProperties.sort((a, b) => a.propertyName.localeCompare(b.propertyName));
    return sortedProperties.length ? sortedProperties : propertiesList;
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle disableTypography={true}>
        <Text variant="h6" className={classes.mBottom20}>
          Download CSV
        </Text>
        <Text variant="body1">
          Select the properties you would like to download responses for.
        </Text>
      </DialogTitle>

      <DialogContent dividers={false}>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <PropertyList
          propertiesList={getPropertiesList()}
          selectedCount={selectedPropertiesCount}
          selectedPropertiesMap={selectedPropertiesMap}
          setSelectedPropertiesMap={setSelectedPropertiesMap}
        />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedPropertiesMap({});
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSelectOnClick}
          disabled={selectedPropertiesCount <= 0}
        >
          Download
        </Button>
      </DialogActions>

      <ErrorDialog
        closeDialog={() => {
          setErrorMessage('');
          closeDialog();
        }}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />
    </Dialog>
  );
};

VirtualAgentResponsesDownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default VirtualAgentResponsesDownloadModal;
