import React from 'react';
import PropTypes from 'prop-types';
import { AddIconButton } from '../../../common/AddIconButton';
import CampaignAction from './CampaignAction';
import { Divider } from '@knockrentals/knock-shared-web';
import { MODES } from '../../constants';
import CampaignActionAccordion from './CampaignActionsAccordion';
import {
  DRIP_CAMPAIGN_ACTION_TYPES,
  PROSPECT_STATES,
} from '../../../constants';
import { TRIGGER_ACTION_DESC } from '../Campaign/constants';

const TRIGGER = 'Trigger';

const getActionTrigger = (triggerText, sequenceNumber) =>
  sequenceNumber === 1 ? triggerText : `Action ${sequenceNumber - 1}`;

const getIsAddButtonDisabled = (actions) =>
  actions.some((action) => {
    return (!action.daysToDelay && !action.hasNoDelay) || !action.templateId;
  });

const CampaignActions = ({
  actions,
  addAction,
  deleteAction,
  mode,
  setActionTemplate,
  setValidationErrorMessages,
  updateAction,
  validationErrorMessages = [],
  triggerType,
}) => {
  const triggerActionDesc = TRIGGER_ACTION_DESC[triggerType]  || TRIGGER_ACTION_DESC.DEFAULT;

  const handleDaysToDelayOnChange = (actionIndex) => (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const daysToDelay = Number(value) || null;

    if (!daysToDelay || (daysToDelay > 0 && daysToDelay < 366)) {
      updateAction(actionIndex, { daysToDelay });
    }
  };

  const handleNoDelayOnChange = (actionIndex) => (e) => {
    const hasNoDelay = e.target.checked;
    const actionUpdate = {
      hasNoDelay,
    };

    if (hasNoDelay) {
      actionUpdate.daysToDelay = null;
    }

    updateAction(actionIndex, actionUpdate);
  };

  const handleTemplateOnClick = (idx, templateId) => () => {
    const actionIndex = idx;
    setActionTemplate({ actionIndex, templateId });
  };

  const handleStateTransitionOnChange = (actionIndex) => (e) => {
    const { checked: isChecked, name: prospectState } = e.target;

    const addedStateTransition = {
      nextProspectState: prospectState,
      actionType: DRIP_CAMPAIGN_ACTION_TYPES.STATE_TRANSITION,
      templateId: null,
      templateName: '',
    };

    const removedStateTransition = {
      nextProspectState: '',
      actionType: '',
    };

    const actionUpdate = isChecked
      ? addedStateTransition
      : removedStateTransition;

    updateAction(actionIndex, actionUpdate);
  };

  const deleteActionValidationErrorMessages = (actionIndex) => {
    setValidationErrorMessages((prevState) => {
      return {
        ...prevState,
        actions: prevState.actions.filter(
          (_action, index) => index !== actionIndex
        ),
      };
    });
  };

  const clearValidationErrorMessage = (actionIndex) => (field) => {
    setValidationErrorMessages((prevState) => {
      const updatedActionMessages = [...prevState.actions];
      updatedActionMessages[actionIndex] = {
        ...updatedActionMessages[actionIndex],
        [field]: '',
      };

      return {
        ...prevState,
        actions: updatedActionMessages,
      };
    });
  };

  return (
    <div>
      {actions.map((action, idx) => {
        const {
          actionType,
          daysToDelay,
          hasNoDelay,
          nextProspectState,
          templateId,
          templateName,
        } = action;

        const sequenceNumber = idx + 1;
        const accordionSummaryTitle = `Action ${sequenceNumber}`;

        const hasActionTiming = daysToDelay || hasNoDelay;

        const shouldDisplayDescription = Boolean(
          hasActionTiming && templateName
        );

        const getTimingMessage = () =>
          daysToDelay
            ? `${daysToDelay} ${daysToDelay === 1 ? 'day' : 'days'} after`
            : 'Immediately on';

        const accordionSummaryDescription = shouldDisplayDescription
          ? `${getTimingMessage()} ${getActionTrigger(
              TRIGGER,
              sequenceNumber
            )}, send:${templateName}`
          : '';

        const handleDeleteAction = () => {
          if (validationErrorMessages[idx]) {
            deleteActionValidationErrorMessages(idx);
          }
          deleteAction(idx);
        };

        const isDeleteActionVisible =
          mode === MODES.FORM && sequenceNumber !== 1;

        const isMarkLostVisible =
          nextProspectState === PROSPECT_STATES.LOST ||
          (mode === MODES.FORM && sequenceNumber === actions.length);

        const errorMessages = validationErrorMessages[idx] || {};

        return (
          <CampaignActionAccordion
            accordionSummaryTitle={accordionSummaryTitle}
            accordionSummaryDescription={accordionSummaryDescription}
            handleDeleteAction={handleDeleteAction}
            hasValidationError={Boolean(
              errorMessages.daysToDelay || errorMessages.template
            )}
            isDeleteActionVisible={isDeleteActionVisible}
            isDefaultExpanded={mode === MODES.FORM}
            key={idx + accordionSummaryTitle}
          >
            <CampaignAction
              actionTrigger={getActionTrigger(triggerActionDesc, sequenceNumber)}
              actionType={actionType}
              clearValidationErrorMessage={clearValidationErrorMessage(idx)}
              daysToDelay={daysToDelay}
              handleDaysToDelayOnChange={handleDaysToDelayOnChange(idx)}
              handleNoDelayOnChange={handleNoDelayOnChange(idx)}
              handleStateTransitionOnChange={handleStateTransitionOnChange(idx)}
              hasNoDelay={hasNoDelay}
              isNoDelayCheckboxEnabled={sequenceNumber === 1}
              handleTemplateOnClick={handleTemplateOnClick(idx, templateId)}
              isMarkLostVisible={isMarkLostVisible}
              mode={mode}
              nextProspectState={nextProspectState}
              templateName={templateName}
              validationErrorMessages={errorMessages}
            />
          </CampaignActionAccordion>
        );
      })}

      <Divider />

      {mode === MODES.FORM && (
        <AddIconButton
          data-testid="add-action-button"
          disabled={getIsAddButtonDisabled(actions)}
          handleOnClick={addAction}
          primaryText={'Add Action'}
        />
      )}
    </div>
  );
};

CampaignActions.propTypes = {
  addAction: PropTypes.func,
  actions: PropTypes.array,
  deleteAction: PropTypes.func,
  mode: PropTypes.string,
  setActionTemplate: PropTypes.func,
  updateAction: PropTypes.func,
  setValidationErrorMessages: PropTypes.func,
  validationErrorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      daysToDelay: PropTypes.string,
      template: PropTypes.string,
    })
  ),
  triggerType: PropTypes.string,
};

export default CampaignActions;
