import React from 'react';
import PropTypes from 'prop-types';
import { InputCheckbox, Text } from '@knockrentals/knock-shared-web';
import TimingLabel from './TimingLabel';

export const NO_DELAY_LABEL_TEXT = 'Immediately on';

const NoDelayCheckbox = ({
  actionTrigger,
  clearErrorMessage,
  handleNoDelayOnChange,
  hasError,
  hasNoDelay = false,
  isFormMode,
}) => (
  <React.Fragment>
    {isFormMode && (
      <Text variant="body2" color="textPrimary">
        or
      </Text>
    )}

    <InputCheckbox
      color="primary"
      disabled={!isFormMode}
      checked={hasNoDelay}
      label={
        <TimingLabel
          actionTrigger={actionTrigger}
          hasError={hasError}
          labelText={NO_DELAY_LABEL_TEXT}
        />
      }
      onChange={handleNoDelayOnChange}
      onFocus={clearErrorMessage}
    />
  </React.Fragment>
);

NoDelayCheckbox.propTypes = {
  actionTrigger: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  handleNoDelayOnChange: PropTypes.func,
  hasError: PropTypes.bool,
  hasNoDelay: PropTypes.bool,
  isFormMode: PropTypes.bool,
};

export default NoDelayCheckbox;
