import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CKEditor from 'ckeditor4-react';

CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.21.0/full-all/ckeditor.js';

const editorConfig = {
  allowedContent: true,
  autoGrow_minHeight: 200,
  extraPlugins: 'autogrow',
  removeDialogTabs: 'image:advanced;link:advanced;table:advanced',
  removePlugins:
    'exportpdf,image,liststyle,tableselection,tabletools,scayt,menubutton,contextmenu,language,uploadwidget,pastefromgdocs,widget,pastefromlibreoffice,pastefromword,pastetext,pastetools,uploadimage,elementspath,clipboard,toolbar,resize',
  resize_enabled: false,
  startupShowBorders: false,
  versionCheck: false,
};

const EmailTemplatePreview = ({ content, name, width = '528px' }) => (
  <div className={classnames('email-template-preview', name)}>
    <CKEditor
      config={editorConfig}
      style={{ width, border: 'none' }}
      data={content}
      name={name}
      readOnly
    />
  </div>
);

EmailTemplatePreview.propTypes = {
  content: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
};

export default EmailTemplatePreview;
