import {
  InputCheckbox,
  Switch,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { Toaster } from '@knockrentals/knock-react';
import DataTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import EditButton from './Components/EditButton';
import { FilterProperty, FilterRadio } from './Components/FilterBoxes';
import * as VirtualAgentAPI from '../VirtualAgentAPI';
import * as AiEmailAPI from '../aiEmailAPI';
import AiBulkEditDrawer from './Components/AiBulkEditDrawer';

const useStyles = makeStyles((theme) => ({
  filterAndEditContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
}));

const NewChannelAiConfig = ({ channelProperties, companyId}) => {
  const classes = useStyles();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [channelProduct, setChannelProducts] = useState(channelProperties);
  const [showedProperties, setShowedProperties] = useState([]);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState(
    channelProduct.map(({ propertyId }) => propertyId)
  );
  const [filters, setFilters] = useState({
    sms: null,
    voice: null,
    chat: null,
    email: null,
  });
  const FIELDS = {
    voiceProductEnabled: 'voice_product_enabled',
    chatProductEnabled: 'chat_product_enabled',
    smsProductEnabled: 'sms_product_enabled',
    emailProductEnabled: 'ai_email_enabled'
  }

  const applyFilters = (channelProduct, filters) => {
    const filteredProperties = channelProduct.filter((property) => {
      const isSelected = selectedProperties.length
        ? selectedProperties.includes(property.propertyId)
        : true;

      const smsEnabled =
        filters.sms === null ||
        (filters.sms && property.smsProductEnabled) ||
        (!filters.sms && !property.smsProductEnabled);
      const voiceEnabled =
        filters.voice === null ||
        (filters.voice && property.voiceProductEnabled) ||
        (!filters.voice && !property.voiceProductEnabled);
      const chatEnabled =
        filters.chat === null ||
        (filters.chat && property.chatProductEnabled) ||
        (!filters.chat && !property.chatProductEnabled);
      const emailEnabled =
        filters.email === null ||
        (filters.email && property.emailProductEnabled !== 'OFF') ||
        (!filters.email && property.emailProductEnabled === 'OFF');


      return (
        isSelected & smsEnabled && voiceEnabled && chatEnabled && emailEnabled
      );
    });

    setShowedProperties(filteredProperties);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleSelectProperty = (propertyId) => {
    setSelectedPropertyIds((prevSelectedPropertyIds) => {
      if (prevSelectedPropertyIds.includes(propertyId)) {
        return prevSelectedPropertyIds.filter((id) => id !== propertyId);
      } else {
        return [...prevSelectedPropertyIds, propertyId];
      }
    });
  };

  const handleSelectAllOnChange = () => {
    const selectedCount = selectedPropertyIds.length;
    setSelectedPropertyIds(
      selectedCount === showedProperties.length
        ? []
        : showedProperties.map(({ propertyId }) => propertyId)
    );
  };

  const handlePropertySelect = (properties) => {
    setSelectedProperties(properties);
  };

  const handleSwitchToggle = (rowData, field, value) => {
    const updatedShowedProperties = showedProperties.map((property) => {
      if (property.propertyId === rowData.propertyId) {
        return { ...property, [field]: value };
      }
      return property;
    });

    const updatedAllProperties = channelProduct.map((channelProperty) => {
      if (channelProperty.propertyId === rowData.propertyId) {
        return { ...channelProperty, [field]: value };
      }
      return channelProperty;
    });

    setChannelProducts(updatedAllProperties);
    setShowedProperties(updatedShowedProperties);
  };

  const saveProductsEnabled = async (field, value, rowData) => {
    if (FIELDS[field] === 'ai_email_enabled') {
      const ai_email_value = value === 'OFF' ? 'SETUP' : 'OFF'
      const payload = [
        {
          property_id: rowData.propertyId,
          company_id: companyId,
          [FIELDS[field]]: ai_email_value
        }
      ]
      try {
        await AiEmailAPI.editAiEmailEnabled(payload);
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        handleSwitchToggle(rowData, field, ai_email_value);
      } catch (error) {
        Toaster.showToast(
          'Error Saving Products',
          2000,
          Toaster.ToastClasses.error
        );
      }
    } else {
      const payload = {
        pmc_id: companyId,
        properties: [
          {
            property_id: rowData.propertyId,
            [FIELDS[field]]: value
          }
        ]
      } 
      try {
        await VirtualAgentAPI.saveProductsEnabled(payload)
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        handleSwitchToggle(rowData, field, value);
      } catch (error) {
        Toaster.showToast(
          'Error Saving Products',
          2000,
          Toaster.ToastClasses.error
        );
      }
    }
  }

  useEffect(() => {
    applyFilters(channelProduct, filters, selectedProperties);
  }, [filters, selectedProperties, channelProduct]);

  const getColumns = () => [
    {
      align: 'left',
      width: '50px',
      title: (
        <InputCheckbox
          color="primary"
          onChange={handleSelectAllOnChange}
          checked={
            !!showedProperties.length &&
            selectedPropertyIds.length === showedProperties.length
          }
          indeterminate={
            selectedPropertyIds.length > 0 &&
            selectedPropertyIds.length < showedProperties.length
          }
          data-testid="select-all-checkbox"
        />
      ),
      render: (rowData) => (
        <InputCheckbox
          color="primary"
          onChange={() => handleSelectProperty(rowData.propertyId)}
          checked={selectedPropertyIds.includes(rowData.propertyId)}
        />
      ),
    },
    {
      align: 'left',
      title: 'Properties',
      width: '100px',
      render: (rowData) =>
        channelProduct.find(
          ({ propertyId }) => propertyId === rowData.propertyId
        )?.propertyName || '',
    },
    {
      align: 'center',
      title: 'Voice',
      field: 'voiceProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch 
          color="primary" 
          name="voice-product-enabled"
          checked={!!rowData.voiceProductEnabled} 
          onChange={() => {
            saveProductsEnabled(
              'voiceProductEnabled',
              !rowData.voiceProductEnabled,
              rowData
            )
          }}
        />
      ),
    },
    {
      align: 'center',
      title: 'Chat',
      field: 'chatProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch 
          color="primary" 
          name="chat-product-enabled"
          checked={!!rowData.chatProductEnabled}
          onChange={() => {
            saveProductsEnabled(
              'chatProductEnabled',
              !rowData.chatProductEnabled,
              rowData
            )
          }}
        />
      ),
    },
    {
      align: 'center',
      title: 'SMS',
      field: 'smsProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch
          color="primary" 
          name="sms-product-enabled"
          checked={!!rowData.smsProductEnabled} 
          onChange={() => {
            saveProductsEnabled(
              'smsProductEnabled',
              !rowData.smsProductEnabled,
              rowData
            )
          }}
        />
      ),
    },
    {
      align: 'center',
      title: 'Email',
      field: 'emailProductEnabled',
      width: '160px',
      render: (rowData) => (
        <Switch
          color="primary"
          name="email-product-enabled"
          checked={rowData.emailProductEnabled !== 'OFF'}
          onChange={() => {
            saveProductsEnabled(
              'emailProductEnabled',
              rowData.emailProductEnabled,
              rowData
            )
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div className={classes.filterAndEditContainer}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <FilterProperty
            properties={channelProduct.map((property) => ({ 
              id: property.propertyId,
              name: property.propertyName,
              selected: selectedProperties.includes(property.id),
            }))}
            onPropertySelect={handlePropertySelect}
          />
          {['Voice', 'Chat', 'SMS', 'Email'].map((filterName) => (
            <FilterRadio
              key={filterName}
              filterName={filterName}
              onFilterChange={(value) =>
                handleFilterChange(filterName.toLowerCase(), value)
              }
            />
          ))}
        </div>
        <EditButton selected={selectedPropertyIds} onClick={() => setIsEditDrawerOpen(true)} />
      </div>
      <AiBulkEditDrawer 
        isEditDrawerOpen={isEditDrawerOpen} 
        setIsEditDrawerOpen={setIsEditDrawerOpen} 
        title={"Edit Product Settings"}
        properties={channelProduct.filter(channel => selectedPropertyIds.includes(channel.propertyId))}
      />
      <DataTable
        className="scheduling-grid-data-table"
        columns={getColumns()}
        data={showedProperties}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          grouping: false,
          headerStyle: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '32px',
            position: 'static',
          },
          padding: 'dense',
          paging: false,
          rowStyle: {
            fontSize: '14px',
            fontWeight: 400,
          },
          search: false,
          sorting: false,
          toolbar: false,
        }}
      />
    </div>
  );
};

NewChannelAiConfig.propTypes = {
  channelProperties: PropTypes.arrayOf(PropTypes.object),
  companyId: PropTypes.number,
};

export default NewChannelAiConfig;
