import React from 'react';
import PropTypes from 'prop-types';

import { Text, makeStyles } from '@knockrentals/knock-shared-web';

import { PropertyChips } from '../../../common/PropertyChips';
import { BrandContentAlert } from './BrandContentAlert';
import { BrandFooter } from './BrandFooter';
import { BrandHeader } from './BrandHeader';

export const BRAND_CONTENT_TITLE =
  'Manage the usage and display of your property logo and brand color in outgoing email communications.';

const useStyles = makeStyles({
  brandContentTitle: {
    margin: '24px 0',
  },
});

const BrandContent = ({
  brandContent,
  isBatchUpdate,
  mergeTags,
  propertyList,
  updateBrandContent,
}) => {
  const classes = useStyles();

  const propertyCount = propertyList.length;

  const shouldRenderAlerts =
    isBatchUpdate || !brandContent.logoUrl || !brandContent.brandColor;

  const hasMixedFooterSettings =
    brandContent.isBrandColorMixed || brandContent.isFooterContentMixed;

  const hasMixedHeaderSettings =
    brandContent.isBrandColorMixed ||
    brandContent.isLogoUrlMixed ||
    brandContent.isHeaderAlignmentMixed;

  return (
    <div>
      {shouldRenderAlerts && (
        <BrandContentAlert
          hasMixedFooterSettings={hasMixedFooterSettings}
          hasMixedHeaderSettings={hasMixedHeaderSettings}
          hasNoBrandColor={!brandContent.brandColor}
          hasNoLogo={!brandContent.logoUrl}
        />
      )}

      {isBatchUpdate && <PropertyChips properties={propertyList} />}

      <Text className={classes.brandContentTitle} variant="body2">
        {BRAND_CONTENT_TITLE}
      </Text>

      <BrandHeader
        alignment={brandContent.headerAlignment}
        brandColor={brandContent.brandColor}
        contrastingTextColor={brandContent.contrastingTextColor}
        hasMixedHeaderSettings={hasMixedHeaderSettings}
        isBatchUpdate={isBatchUpdate}
        isBrandColorMixed={brandContent.isBrandColorMixed}
        isHeaderEnabled={brandContent.isHeaderEnabled}
        isHeaderEnabledIndeterminate={brandContent.isHeaderEnabledIndeterminate}
        logoUrl={brandContent.logoUrl}
        propertyName={brandContent.propertyName}
        propertyCount={propertyCount}
        updateBrandContent={updateBrandContent}
      />

      <BrandFooter
        brandColor={brandContent.brandColor}
        contrastingTextColor={brandContent.contrastingTextColor}
        footerContent={brandContent.footerContent}
        isBatchUpdate={isBatchUpdate}
        isBrandColorMixed={brandContent.isBrandColorMixed}
        isFooterContentMixed={brandContent.isFooterContentMixed}
        isFooterEnabled={brandContent.isFooterEnabled}
        isFooterEnabledIndeterminate={brandContent.isFooterEnabledIndeterminate}
        mergeTags={mergeTags}
        propertyCount={propertyCount}
        updateBrandContent={updateBrandContent}
      />
    </div>
  );
};

BrandContent.propTypes = {
  brandContent: PropTypes.shape({
    brandColor: PropTypes.string,
    contrastingTextColor: PropTypes.string,
    footerContent: PropTypes.string,
    footerAlignment: PropTypes.string,
    headerAlignment: PropTypes.string,
    isBrandColorMixed: PropTypes.bool,
    isFooterContentMixed: PropTypes.bool,
    isFooterEnabled: PropTypes.bool,
    isFooterEnabledIndeterminate: PropTypes.bool,
    isHeaderAlignmentMixed: PropTypes.bool,
    isHeaderEnabled: PropTypes.bool,
    isHeaderEnabledIndeterminate: PropTypes.bool,
    isLogoUrlMixed: PropTypes.bool,
    logoUrl: PropTypes.string,
    propertyId: PropTypes.number,
    propertyName: PropTypes.string,
  }),
  isBatchUpdate: PropTypes.bool,
  mergeTags: PropTypes.array,
  propertyList: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
  updateBrandContent: PropTypes.func,
};

export default BrandContent;
