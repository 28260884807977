import React, { Component } from 'react';
import { Toaster, Alert, PageContainer } from '@knockrentals/knock-react';
import { AuthenticationService } from '@knockrentals/knock-react';
import { Tooltip } from '@knockrentals/knock-shared-web';
import AdminUserEditor from './AdminUserEditor';
import DeleteAdminUser from './DeleteAdminUser';
import AdminsAPI from './AdminsAPI';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import { UNIFIED_LOGIN_URL } from '../../config';
import { ADMIN_USER_LABELS, ADMIN_USER_ROLES } from '../../constants';
import './_adminUsers.scss';

const getUserId = () => AuthenticationService.getTokenPayload().user_id;
const isCurrentUser = (currentUserId, user) => user.admin_id === currentUserId;

class AdminUsers extends Component {
  state = {
    selectedAdminUser: null,
    adminUsers: [],
    addingAdminUser: false,
    editAdminUser: false,
    deleteAdminUser: false,
    isLoading: true,
    isUserCreationEnabled:
      AuthenticationService.getRole() === ADMIN_USER_ROLES.MASTER,
    loggedInUserType: AuthenticationService.getRole(),
  };

  static contextType = FeatureFlagContext;

  async componentDidMount() {
    this.load();
  }

  userDeleteDisabled = () => {
    const isInternalMode = AuthenticationService._internalMode;
    return isInternalMode ? false : this.context.isUserDeleteDisabled;
  };

  load = () => {
    AdminsAPI.getAdminUsers()
      .then((response) => {
        let adminUsers = [];
        if (typeof response.admin_users !== 'undefined') {
          adminUsers = response.admin_users;
          const currentUserId = getUserId();

          adminUsers.map((user) => {
            if (
              this.context.isAdminPasswordResetEnabled &&
              isCurrentUser(currentUserId, user)
            ) {
              this.setState({
                isUserCreationEnabled: user.user_creation_enabled,
              });
            }
            user.leasing_teams.sort((a, b) => {
              const teamNameA = a.leasing_team_name.toLocaleLowerCase();
              const teamNameB = b.leasing_team_name.toLocaleLowerCase();
              if (teamNameA < teamNameB) return -1;
              if (teamNameA > teamNameB) return 1;
              return 0;
            });
            return user;
          });
        }

        this.setState({
          adminUsers: adminUsers,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          adminUsers: [],
          isLoading: false,
        });
      });
  };

  render() {
    const addAdminUser =
      this.state.addingAdminUser && !this.state.deleteAdminUser;
    const editAdminUser =
      this.state.selectedAdminUser &&
      this.state.editAdminUser &&
      !this.state.deleteAdminUser;
    const deleteAdminUser = this.state.deleteAdminUser;

    return (
      <PageContainer
        className="admin-users-page-container"
        isLoading={this.state.isLoading}
      >
        <h1>Admins</h1>

        {this.userDeleteDisabled() && (
          <h4>
            We have temporarily disabled the Delete User button. Should you need
            assistance please reach out to your CSM.
          </h4>
        )}

        {this.state.isUserCreationEnabled && (
          <button
            className="knock-react-button add-new-user"
            onClick={this.addAdminUser.bind(this)}
          >
            Add new admin user
          </button>
        )}

        {this.renderTable()}

        {addAdminUser && (
          <AdminUserEditor
            loggedInUserType={this.state.loggedInUserType}
            addingAdminUser={this.state.addingAdminUser}
            onAdminUserCreated={this.onAdminUserCreated.bind(this)}
            onCancel={this.onCancel}
          />
        )}

        {editAdminUser && (
          <AdminUserEditor
            loggedInUserType={this.state.loggedInUserType}
            adminUser={this.state.selectedAdminUser}
            onAdminUserUpdated={this.onAdminUserUpdated.bind(this)}
            onCancel={this.onCancel}
          />
        )}

        {deleteAdminUser && (
          <DeleteAdminUser
            adminUser={this.state.selectedAdminUser}
            onCancel={this.onCancel}
            onDeleteSuccess={this.onDeleteSuccess}
          />
        )}
      </PageContainer>
    );
  }

  renderTable() {
    if (
      typeof this.state.adminUsers === 'undefined' ||
      this.state.adminUsers.length === 0
    ) {
      return (
        <div>
          <Alert>There are no admin users.</Alert>
        </div>
      );
    }

    return (
      <table className="wire-frame-table">
        <thead>
          <tr>
            <th className="username-header">Username</th>
            <th className="email-header">Contact Email</th>
            <th className="name-header">Name</th>
            <th className="leasing-teams-header">Leasing Teams</th>
            <th>Role</th>
            <th className="user-migrated-header">
              User Migrated to RealPage Unified Platform
            </th>
          </tr>
        </thead>
        <tbody>{this.renderAdminUsers()}</tbody>
      </table>
    );
  }

  renderAdminUsers() {
    return this.state.adminUsers.map((adminUser) => {
      const editAdminUser = () => {
        this.editAdminUser(adminUser);
      };

      const deleteAdminModal = () => {
        this.renderDeleteAdminModal(adminUser);
      };

      return (
        <tr key={adminUser.admin_id}>
          <td className="username-column">
            <div>{adminUser.username}</div>

            <div>
              {adminUser.user_role !== ADMIN_USER_ROLES.MASTER && (
                <button
                  className="knock-react-button edit"
                  onClick={editAdminUser}
                  disabled={adminUser.is_migrated}
                >
                  Edit
                </button>
              )}

              {this.state.loggedInUserType === ADMIN_USER_ROLES.MASTER &&
                adminUser.user_role !== ADMIN_USER_ROLES.MASTER &&
                !this.userDeleteDisabled() && (
                  <button
                    className="knock-react-button & danger"
                    onClick={deleteAdminModal}
                    disabled={adminUser.is_migrated}
                  >
                    Delete
                  </button>
                )}

              {adminUser.user_role !== ADMIN_USER_ROLES.MASTER &&
                adminUser.is_migrated && (
                  <Tooltip title="This user has been migrated. Edit and delete buttons for this user have been disabled. Click here to modify user in Unified Platform">
                    <a
                      href={UNIFIED_LOGIN_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-regular fa-question-circle edit-and-delete-buttons-tooltip"></i>
                    </a>
                  </Tooltip>
                )}
            </div>
          </td>
          <td className="email-column">{adminUser.email}</td>
          <td className="name-column">
            {adminUser.first_name} {adminUser.last_name}
          </td>

          {adminUser.leasing_teams !== null && (
            <td>{this.listLeasingTeams(adminUser.leasing_teams)}</td>
          )}
          <td className="type-column">
            {this.renderAdminUserRoleDisplay(adminUser.user_role)}
          </td>
          <td className="user-migrated-column">
            {adminUser.is_migrated ? 'migrated' : ''}
          </td>
        </tr>
      );
    });
  }

  renderAdminUserRoleDisplay = (userRole) => ADMIN_USER_LABELS[userRole] || '';

  listLeasingTeams(adminUserLeasingTeams) {
    const leasingTeams = adminUserLeasingTeams.map(
      (leasingTeam) => leasingTeam.leasing_team_name
    );
    return <div className="leasing-teams">{leasingTeams.join(', ')}</div>;
  }

  addAdminUser() {
    this.setState({ addingAdminUser: true });
  }

  editAdminUser(selectedAdminUser) {
    this.setState({
      selectedAdminUser,
      editAdminUser: true,
    });
  }

  onCancel = () => {
    this.setState({
      selectedAdminUser: null,
      addingAdminUser: false,
      deleteAdminUser: false,
    });
  };

  onAdminUserCreated(adminUser) {
    return AdminsAPI.createAdminUser({
      first_name: adminUser.first_name,
      last_name: adminUser.last_name,
      email: adminUser.email,
      username: adminUser.username,
      password: adminUser.password,
      leasing_teams: adminUser.adminLeasingTeams,
      role: adminUser.user_role,
      user_creation_enabled: adminUser.isUserCreationEnabled,
    })
      .then(() => {
        Toaster.showToast('Admin created!', 2000, Toaster.ToastClasses.success);
        this.setState({
          addingAdminUser: false,
          selectedAdminUser: null,
        });
        this.load();
      })
      .catch(() => {
        Toaster.showToast(
          'Error creating Admin',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  onAdminUserUpdated(adminUser) {
    return AdminsAPI.updateAdminUser(adminUser.admin_id, adminUser.user_role, {
      first_name: adminUser.first_name,
      last_name: adminUser.last_name,
      username: adminUser.username,
      email: adminUser.email,
      password: adminUser.password,
      leasing_teams: adminUser.adminLeasingTeams,
      user_creation_enabled: adminUser.isUserCreationEnabled,
    })
      .then((response) => {
        if (response.message === 'CONFLICT') {
          Toaster.showToast('Admin updated!', 2000, Toaster.ToastClasses.error);
          return false;
        }

        // Update Web UI
        const adminUsersCopy = this.state.adminUsers.slice();
        const _adminUser = adminUsersCopy.find(
          (_admin) =>
            _admin.admin_id === adminUser.admin_id &&
            _admin.user_role === adminUser.user_role
        );
        _adminUser.first_name = adminUser.first_name;
        _adminUser.last_name = adminUser.last_name;
        _adminUser.username = adminUser.username;
        _adminUser.email = adminUser.email;
        _adminUser.leasing_teams = adminUser.adminLeasingTeams;
        _adminUser.user_creation_enabled = adminUser.isUserCreationEnabled;

        this.setState({ adminUsers: adminUsersCopy, selectedAdminUser: null });
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        return true;
      })
      .catch(() => {
        Toaster.showToast(
          'Error updating Admin',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  renderDeleteAdminModal = (adminUser) => {
    this.setState({
      selectedAdminUser: adminUser,
      deleteAdminUser: true,
    });
  };

  onDeleteSuccess = (deletedAdminUser) => {
    const updatedAdminsList = this.state.adminUsers.filter(
      (adminUser) => adminUser.admin_id !== deletedAdminUser.admin_id
    );

    this.setState({ adminUsers: updatedAdminsList });
  };
}

export default AdminUsers;
