import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Collapse } from '@material-ui/core';
import { makeStyles, Text } from '@knockrentals/knock-shared-web';

export const BRAND_COLOR_ALERT = {
  message:
    'To manage your brand color, navigate to the properties page and select basics to update your brand color.',
  title: 'No Brand Color Available',
  type: 'color',
};

export const LOGO_ALERT = {
  message:
    'To manage your property logo, navigate to the properties page in the Knock CRM and click on edit community.',
  title: 'No Logo Available',
  type: 'logo',
};

export const MIXED_ALERT_TITLE = 'Settings Mixed Across Selected Properties';

export const getMixedSpecifier = (isHeaderMixed, isFooterMixed) => {
  const header = isHeaderMixed ? 'HEADER' : '';
  const footer = isFooterMixed ? 'FOOTER' : '';
  const and = header && footer ? ' and ' : '';

  return `${header}${and}${footer}`;
};

export const getMixedAlert = (isHeaderMixed, isFooterMixed) => ({
  message: `The selected properties have different settings for the display of brand content in the ${getMixedSpecifier(
    isHeaderMixed,
    isFooterMixed
  )} of outgoing email. Review individual properties for details. Changing the settings available here will update ALL selected properties.`,
  title: MIXED_ALERT_TITLE,
  type: 'mixed',
});

const useStyles = makeStyles({
  alert: {
    borderRadius: '8px',
    marginBottom: '16px',
    '& .MuiAlert-action': {
      alignItems: 'start',
    },
  },
});

const BrandContentAlert = ({
  hasMixedFooterSettings,
  hasMixedHeaderSettings,
  hasNoBrandColor,
  hasNoLogo,
}) => {
  const [isAlertClosedMap, setIsAlertClosedMap] = useState({});
  const classes = useStyles();

  const handleOnClose = (alertType) => () =>
    setIsAlertClosedMap((prevState) => ({
      ...prevState,
      [alertType]: true,
    }));

  const alerts =
    hasMixedFooterSettings || hasMixedHeaderSettings
      ? [getMixedAlert(hasMixedHeaderSettings, hasMixedFooterSettings)]
      : [hasNoBrandColor && BRAND_COLOR_ALERT, hasNoLogo && LOGO_ALERT];

  return alerts.map((alert) =>
    !alert ? null : (
      <Collapse
        key={alert.type}
        in={!isAlertClosedMap[alert.type]}
        unmountOnExit
      >
        <Alert
          className={classes.alert}
          onClose={handleOnClose(alert.type)}
          severity="warning"
        >
          <AlertTitle>
            <Text variant="subtitle1">{alert.title}</Text>
          </AlertTitle>
          {alert.message}
        </Alert>
      </Collapse>
    )
  );
};

BrandContentAlert.propTypes = {
  hasMixedFooterSettings: PropTypes.bool,
  hasMixedHeaderSettings: PropTypes.bool,
  hasNoBrandColor: PropTypes.bool,
  hasNoLogo: PropTypes.bool,
};

export default BrandContentAlert;
