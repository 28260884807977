export const ADMIN_USER_ROLES = {
  ADMIN: 'admin',
  ANALYTICS: 'analytics_user',
  MASTER: 'master',
};

export const ADMIN_USER_LABELS = {
  [ADMIN_USER_ROLES.ADMIN]: 'Admin',
  [ADMIN_USER_ROLES.ANALYTICS]: 'Analytics',
  [ADMIN_USER_ROLES.MASTER]: 'Master',
};

export const SAVED_NOTIFICATION = 'Saved!';
