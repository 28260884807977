import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Divider,
  LoadingOverlay,
  Tab,
  TabPanel,
  Tabs,
  ThemeProvider,
  makeStyles,
} from '@knockrentals/knock-shared-web';

import PropertiesAPI from '../../Properties/PropertiesAPI';
import { getProductsEnabled } from '../VirtualAgentAPI';
import { getIsAiEmailEnabled } from '../aiEmailAPI';
import AiVoiceSettings from './AiVoiceSettings';
import ErrorToaster from './Components/ErrorToaster';
import NewChannelAiConfig from './NewAiChannelConfig';
import InternalConfigSettings from './InternalConfigSettings';

const useStyles = makeStyles(() => ({
  tabPanel: {
    marginTop: '10px',
  },

  divider: {
    marginTop: '0 !important',
    position: 'relative',
    top: '-1px',
  },

  subtitle: {
    marginTop: '10px',
    marginBottom: '25px',
  },
}));

const TABS = {
  CHANNEL: 'channel',
  CONFIG: 'config',
  VOICE: 'voice',
};

const TABS_NAMES = {
  channel: 'Products',
  config: 'Internal Config',
  voice: 'Virtual Agent Config',
};

const NewVirtualAgentConfig = ({ companyId, isInternalMode }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(isInternalMode ? TABS.CHANNEL : TABS.VOICE);
  const [productsEnabled, setProductsEnabled] = useState([]);
  const [fetchedPropertyData, setFetchedPropertyData] = useState(false);
  const [hasPropertyVoiceEnabled, setHasPropertyVoiceEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorToaster, setShowErrorToaster] = useState(false);

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    setHasPropertyVoiceEnabled(productsEnabled.find((pe) => pe.voiceProductEnabled));
  }, [productsEnabled, hasPropertyVoiceEnabled]);

  useEffect(() => {
    const getPropertyData = async () => {
      const { properties } = (await PropertiesAPI.getCommunityInfo()) || {};
      properties.sort((a, b) => {
        return a.data.location.name.localeCompare(b.data.location.name);
      });

      const propertyIds = properties.map(({ id }) => id);
      const aiEmailEnabledPropertyList = await getIsAiEmailEnabled(propertyIds);
      const chunkSize = 5;
      for (let i = 0; i < properties.length; i += chunkSize) {
        const chunk = properties.slice(i, i + chunkSize);
        const promises = await Promise.all(
          chunk.map(async (property) => {
            const {
              voice_product_enabled,
              sms_product_enabled,
              chat_product_enabled,
            } = await getProductsEnabled(companyId, property.id);
            const {
              ai_email_enabled: aiEmailEnabled
            } = aiEmailEnabledPropertyList.find(({ property_id }) => property_id === property.id);

            return {
              propertyId: property.id,
              propertyName: property.data.location.name,
              voiceProductEnabled: voice_product_enabled,
              smsProductEnabled: sms_product_enabled,
              chatProductEnabled: chat_product_enabled,
              emailProductEnabled: aiEmailEnabled,
              aiEmailEnabled: aiEmailEnabled
            };
          })
        );

        promises.forEach((propertyProductsEnabled) => {
          setProductsEnabled((prevState) => [
            ...prevState,
            propertyProductsEnabled,
          ]);
        });
      }
    };

    if (!fetchedPropertyData) {
      setIsLoading(true);

      getPropertyData()
        .catch((err) => {
          console.error(err);
          setShowErrorToaster(true);
        })
        .finally(() => {
          setFetchedPropertyData(true);
          setIsLoading(false);
        });
    }
  }, [companyId, fetchedPropertyData]);

  return (
    <ThemeProvider>
      <LoadingOverlay
        open={isLoading}
        style={{ position: 'absolute', opacity: '0.5' }}
      />
      <Box data-testid="new-va-agent-config">
        <Tabs
          value={currentTab}
          onChange={handleTabsChange}
          textColor="primary"
          indicatorColor="primary"
        >
          {isInternalMode && (<Tab label={TABS_NAMES.channel} value={TABS.CHANNEL} />)}
          {isInternalMode && (<Tab label={TABS_NAMES.config} value={TABS.CONFIG} />)}
          {hasPropertyVoiceEnabled && (<Tab label={TABS_NAMES.voice} value={TABS.VOICE} />)}
        </Tabs>
        <Divider className={classes.divider} />
      </Box>

      <TabPanel
        id="ChannelTabPanel"
        value={currentTab}
        index={TABS.CHANNEL}
        className={classes.tabPanel}
      >
        {fetchedPropertyData && isInternalMode && (
          <NewChannelAiConfig channelProperties={productsEnabled} companyId={companyId} />
        )}
      </TabPanel>

      {showErrorToaster && (
        <ErrorToaster open={showErrorToaster} onClose={setShowErrorToaster} />
      )}

      <TabPanel
        id="ChannelTabPanel"
        value={currentTab}
        index={TABS.CONFIG}
        className={classes.tabPanel}
      >
        {fetchedPropertyData && isInternalMode && (
          <InternalConfigSettings
            companyId={companyId}
            properties={productsEnabled}
          />
        )}
      </TabPanel>

      <TabPanel
        id="ChannelTabPanel"
        value={currentTab}
        index={TABS.VOICE}
        className={classes.tabPanel}
      >
        {fetchedPropertyData && hasPropertyVoiceEnabled && (
          <AiVoiceSettings
            isInternalMode={isInternalMode}
            properties={productsEnabled.filter((pe) => pe.voiceProductEnabled)}
            companyId={companyId}
          />
        )}
      </TabPanel>
    </ThemeProvider>
  );
};

NewVirtualAgentConfig.propTypes = {
  companyId: PropTypes.number,
  isInternalMode: PropTypes.bool,
};

export default NewVirtualAgentConfig;
