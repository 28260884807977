import { MODES, STATUS } from '../../constants';
import { CAMPAIGN_SUBHEADER, CONFIRMATION_MODES } from '../constants';
import {
  CAMPAIGN_TRIGGER,
  PLACE_HOLDER_TEXT,
  RECIPIENT,
  RECIPIENT_TYPES,
  TRIGGER_LABELS,
  TRIGGER_TYPES,
} from './constants';

const CAMPAIGN_SUBHEADER_VIEW_TEXT = {
  [STATUS.ARCHIVED]: CAMPAIGN_SUBHEADER.VIEW_ARCHIVE,
  [STATUS.DRAFT]: CAMPAIGN_SUBHEADER.VIEW_DRAFT,
  [STATUS.LIVE]: CAMPAIGN_SUBHEADER.VIEW_LIVE,
};

const CAMPAIGN_SUBHEADER_USE_TEXT = {
  [STATUS.ARCHIVED]: CAMPAIGN_SUBHEADER.USE_ARCHIVE,
  [STATUS.DRAFT]: CAMPAIGN_SUBHEADER.USE_DRAFT,
  [STATUS.LIVE]: CAMPAIGN_SUBHEADER.USE_LIVE,
};

const getRecipientOptions = () => {
  const recipients = [
    ['Prospect', RECIPIENT_TYPES.PROSPECT],
    // TODO - enable resident when ready
    // ['Resident', RECIPIENT_TYPES.RESIDENT],
  ];
  return recipients.map(([label, value]) => ({ label, value }));
};

const RECIPIENT_TRIGGERS = {
  [RECIPIENT_TYPES.PROSPECT]: [
    TRIGGER_TYPES.AI_EMAIL,
    TRIGGER_TYPES.CANCELLED_MANAGER,
    TRIGGER_TYPES.CANCELLED_RENTER,
    TRIGGER_TYPES.NO_SHOW,
    TRIGGER_TYPES.POST_APPOINTMENT_MESSAGE,
    TRIGGER_TYPES.STALE_PROSPECT,
  ],
};

const getTriggerOptions = (recipient) => {
  const triggers = RECIPIENT_TRIGGERS[recipient] || [];
  return triggers.map((trigger) => ({
    label: TRIGGER_LABELS[trigger],
    value: trigger,
  }));
};

export const getSelectOptions = (name) => {
  switch (name) {
    case RECIPIENT:
      return getRecipientOptions();
    case CAMPAIGN_TRIGGER:
      return getTriggerOptions;
    default:
      return;
  }
};

export const setPlaceHolder = (targetValue, targetName) => {
  if (!targetValue) {
    return () => PLACE_HOLDER_TEXT[targetName];
  }
};

export const getCardConfig = ({
  closeDrawer,
  handleCancel,
  handleFormGoBack,
  hasCampaignChanged,
  isOriginCreateCampaign,
  mode,
  setConfirmationMode,
  status,
}) => {
  const getConfirmationModeHandler = (mode) => () => setConfirmationMode(mode);

  const configs = {
    [MODES.DISPLAY]: {
      backButtonHandler: isOriginCreateCampaign && closeDrawer,
      closeDrawer: handleCancel,
      subheader: isOriginCreateCampaign
        ? CAMPAIGN_SUBHEADER_USE_TEXT[status]
        : CAMPAIGN_SUBHEADER_VIEW_TEXT[status],
    },
    [MODES.FORM]: {
      backButtonHandler: hasCampaignChanged
        ? getConfirmationModeHandler(CONFIRMATION_MODES.BACK)
        : handleFormGoBack,
      closeDrawer: hasCampaignChanged
        ? getConfirmationModeHandler(CONFIRMATION_MODES.CANCEL)
        : handleCancel,
      subheader:
        status === STATUS.DRAFT
          ? CAMPAIGN_SUBHEADER.EDIT_DRAFT
          : CAMPAIGN_SUBHEADER.NEW,
    },
  };

  return configs[mode];
};

export const CAMPAIGN_VALIDATION_ERROR_MESSAGES = {
  ACTION_TASK: 'A template or marked as lost is required',
  ASSOCIATED_PROPERTIES: 'Properties are required',
  CAMPAIGN_NAME: 'A campaign name is required',
  CAMPAIGN_TRIGGER: 'A campaign trigger is required',
  DAYS_TO_DELAY: 'The number of days is required',
  ACTION_TIMING: 'An action timing is required',
  RECIPIENT: 'A recipient is required',
};

const getActionTimingMessage = (actionIndex) => {
  const isNoDelayCheckboxEnabled = actionIndex === 0;
  return isNoDelayCheckboxEnabled
    ? CAMPAIGN_VALIDATION_ERROR_MESSAGES.ACTION_TIMING
    : CAMPAIGN_VALIDATION_ERROR_MESSAGES.DAYS_TO_DELAY;
};

export const getActionErrorMessages = (actions) => {
  const errors = actions.reduce(
    (validationErrors, action, index) => {
      const errors = {};
      const { daysToDelay, hasNoDelay, nextProspectState, templateName } =
        action;

      if (!daysToDelay && !hasNoDelay) {
        errors.daysToDelay = getActionTimingMessage(index);
      }

      if (!nextProspectState && !templateName) {
        errors.actionTask = CAMPAIGN_VALIDATION_ERROR_MESSAGES.ACTION_TASK;
      }

      validationErrors.errorMessages.push(errors);

      if (
        !validationErrors.hasActionErrorMessages &&
        Object.keys(errors).length
      ) {
        validationErrors.hasActionErrorMessages = true;
      }

      return validationErrors;
    },
    { errorMessages: [], hasActionErrorMessages: false }
  );

  if (errors.hasActionErrorMessages) {
    return errors.errorMessages;
  }
};

export const validateCampaign = (campaign) => {
  const messages = {};
  const {
    actions = [],
    associatedProperties = [],
    campaignName,
    campaignTrigger,
    recipient,
  } = campaign;

  const actionErrorMessages = getActionErrorMessages(actions);

  if (actionErrorMessages) {
    messages.actions = actionErrorMessages;
  }

  if (!associatedProperties.length) {
    messages.properties =
      CAMPAIGN_VALIDATION_ERROR_MESSAGES.ASSOCIATED_PROPERTIES;
  }
  if (!campaignName.trim()) {
    messages.campaignName = CAMPAIGN_VALIDATION_ERROR_MESSAGES.CAMPAIGN_NAME;
  }

  if (!campaignTrigger) {
    messages.campaignTrigger =
      CAMPAIGN_VALIDATION_ERROR_MESSAGES.CAMPAIGN_TRIGGER;
  }

  if (!recipient) {
    messages.recipient = CAMPAIGN_VALIDATION_ERROR_MESSAGES.RECIPIENT;
  }

  if (Object.keys(messages).length) {
    return messages;
  }
};
