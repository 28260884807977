import { getContrastingTextColor } from './get-contrasting-text-color';

export const brandContentApiMap = {
  brandColor: 'brand_color',
  footerContent: 'email_footer_content',
  headerAlignment: 'email_header_position',
  isBrandColorMixed: 'is_brand_color_mixed',
  isFooterContentMixed: 'email_footer_content_mixed',
  isFooterEnabled: 'email_footer_enabled',
  isFooterEnabledIndeterminate: 'email_footer_enabled_mixed',
  isHeaderAlignmentMixed: 'email_header_position_mixed',
  isHeaderEnabled: 'email_header_enabled',
  isHeaderEnabledIndeterminate: 'email_header_enabled_mixed',
  isLogoUrlMixed: 'is_logo_mixed',
  logoUrl: 'logo',
  propertyId: 'property_id',
  propertyIds: 'property_ids',
  propertyName: 'property_name',
};

export const sortColumnMap = {
  propertyName: 'property',
};

export const getQueryString = (params) => {
  const { page, pageSize, sortColumn, sortDirection } = params;
  const offSet = page * pageSize;
  return `?offset=${offSet}&page_size=${pageSize}&sort_column=${sortColumnMap[sortColumn]}&sort_direction=${sortDirection}`;
};

const getNormalizedPropertyBrandContent = (propertyBrandContent) => ({
  brandColor: propertyBrandContent[brandContentApiMap.brandColor],
  isFooterEnabled: propertyBrandContent[brandContentApiMap.isFooterEnabled],
  isHeaderEnabled: propertyBrandContent[brandContentApiMap.isHeaderEnabled],
  logoUrl: propertyBrandContent[brandContentApiMap.logoUrl],
  propertyId: propertyBrandContent[brandContentApiMap.propertyId],
  propertyName: propertyBrandContent[brandContentApiMap.propertyName],
});

export const normalizeBrandContent = (brandContent) =>
  brandContent.map(getNormalizedPropertyBrandContent);

export const normalizeBrandContentById = (propertyBrandContent) => {
  const brandColor = propertyBrandContent[brandContentApiMap.brandColor];
  return {
    ...getNormalizedPropertyBrandContent(propertyBrandContent),
    footerContent: propertyBrandContent[brandContentApiMap.footerContent],
    headerAlignment: propertyBrandContent[brandContentApiMap.headerAlignment],
    contrastingTextColor: brandColor && getContrastingTextColor(brandColor),
  };
};

export const normalizeBatchBrandContent = (batchBrandContent) => ({
  brandColor: batchBrandContent[brandContentApiMap.brandColor],
  headerAlignment: batchBrandContent[brandContentApiMap.headerAlignment],
  logoUrl: batchBrandContent[brandContentApiMap.logoUrl],
  isBrandColorMixed: batchBrandContent[brandContentApiMap.isBrandColorMixed],
  isFooterContentMixed:
    batchBrandContent[brandContentApiMap.isFooterContentMixed],
  isFooterEnabled: batchBrandContent[brandContentApiMap.isFooterEnabled],
  isFooterEnabledIndeterminate:
    batchBrandContent[brandContentApiMap.isFooterEnabledIndeterminate],
  isHeaderAlignmentMixed:
    batchBrandContent[brandContentApiMap.isHeaderAlignmentMixed],
  isHeaderEnabled: batchBrandContent[brandContentApiMap.isHeaderEnabled],
  isHeaderEnabledIndeterminate:
    batchBrandContent[brandContentApiMap.isHeaderEnabledIndeterminate],
  isLogoUrlMixed: batchBrandContent[brandContentApiMap.isLogoUrlMixed],
});

export const transformPayload = (payload) => {
  const transformedPayloadList = [
    [brandContentApiMap.footerContent, payload.footerContent],
    [brandContentApiMap.headerAlignment, payload.headerAlignment],
    [brandContentApiMap.isFooterEnabled, payload.isFooterEnabled],
    [brandContentApiMap.isHeaderEnabled, payload.isHeaderEnabled],
    [brandContentApiMap.propertyIds, payload.propertyIds],
  ].filter(
    ([_apiKeyName, updatedValue]) =>
      updatedValue !== null && updatedValue !== undefined
  );

  return Object.fromEntries(transformedPayloadList);
};
