import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Toaster,
  Alert,
  PageContainer,
} from '@knockrentals/knock-react';
import * as _ from 'lodash';
import { Formik } from 'formik';
import { removeSpaces, trimValue, whiteSpaceRestrictValue } from '../../Utils';

import UsersAPI from './UsersAPI';
import PasswordEditor from './PasswordEditor';
import InfoIconTooltip from '../Properties/components/InfoTooltip';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import { ADMIN_USER_LABELS, ADMIN_USER_ROLES } from '../../constants';

import './_adminsPage.scss';
import './_usersPage.scss';

export const USER_CREATION_LABEL = 'Admin User Management:';

export const USER_CREATION_MESSAGE =
  'Enabling admin user management will grant permission to create new admins, as well as edit / update existing admins, including passwords.';

const LABEL_IDS = {
  first_name: 'admin-user-editor-first_name',
  last_name: 'admin-user-editor-last_name',
  email: 'admin-user-editor-email',
  isUserCreationEnabled: 'admin-user-editor-isUserCreationEnabled',
  username: 'admin-user-editor-username',
  user_role: 'admin-user-editor-user_role',
  password: 'admin-user-editor-password',
  passwordConfirm: 'admin-user-editor-passwordConfirm',
};

class AdminUserEditor extends Component {
  constructor(props) {
    super(props);

    const { adminUser } = props;
    const adminLeasingTeams = adminUser?.leasing_teams || [];

    this.state = {
      adminLeasingTeams,
      companyLeasingTeams: [],
      isUsernameAvailable: !!adminUser,
      isCheckingUsernameAvailability: false,
      isLoading: true,
    };

    this.usernameDebounce = _.debounce(
      (username) => {
        UsersAPI.isUsernameAvailable(username)
          .then((response) => {
            if (username.length === 0 || !response.is_available) {
              this.setState({
                isUsernameAvailable: false,
                isCheckingUsernameAvailability: false,
              });
            } else {
              this.setState({
                isUsernameAvailable: true,
                isCheckingUsernameAvailability: false,
              });
            }
          })
          .catch((e) => {
            Toaster.showToast(
              'Error checking username availability',
              2000,
              Toaster.ToastClasses.error
            );
            console.log(e);
          });
      },
      1000,
      { maxWait: 3000 }
    );
  }

  componentDidMount() {
    UsersAPI.getCompanyUsers()
      .then((response) => {
        this.setState({
          companyLeasingTeams: response.leasing_team_users,
          isLoading: false,
        });
      })
      .catch((e) => {
        Toaster.showToast(
          'Error getting leasing teams',
          2000,
          Toaster.ToastClasses.error
        );
        console.error(e);
      });
  }

  render() {
    const { adminUser } = this.props;

    if (this.state.isChangingPassword && this.props.adminUser) {
      return (
        <PasswordEditor
          adminUser={adminUser}
          submitPasswordUpdate={this.submitPasswordChange}
          cancel={this.cancelPasswordEdit}
        />
      );
    }

    const { user_role: adminUserRole } = adminUser || {};
    const adminUserRoleLabel = ADMIN_USER_LABELS[adminUserRole];

    return (
      <Modal className="admin-user-editor-container">
        <ModalHeader>
          {adminUser ? (
            <h2>
              <span>
                Edit Admin
                <button
                  className="knock-react-button"
                  type="button"
                  onClick={this.startChangePassword}
                >
                  Change password
                </button>
              </span>
              <span>
                <br /> Username: {adminUser.username}{' '}
              </span>
            </h2>
          ) : (
            <h2>Add Admin User</h2>
          )}
        </ModalHeader>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validate={this.validate}
          onSubmit={this.submit}
          initialValues={{
            first_name: adminUser ? adminUser.first_name || '' : '',
            last_name: adminUser ? adminUser.last_name || '' : '',
            email: adminUser ? adminUser.email || '' : '',
            isUserCreationEnabled: adminUser?.user_creation_enabled || false,
            username: adminUser ? adminUser.username : '',
            user_role: this.getInitialUserRole(),
            password: '',
            passwordConfirm: '',
            searchTerm: '',
          }}
        >
          {(props) => (
            <form
              onKeyPress={this.enterSubmitForm}
              autoComplete="off"
              onSubmit={props.handleSubmit}
            >
              <PageContainer isLoading={this.state.isLoading}>
                <ModalContent>
                  <div className="knock-react-flex">
                    <div className="modal-field-label" id={LABEL_IDS.user_role}>
                      User Role:
                    </div>
                    {adminUser ? (
                      <span>{adminUserRoleLabel}</span>
                    ) : (
                      <select
                        aria-labelledby={LABEL_IDS.user_role}
                        className="modal-field-input knock-react-select"
                        data-testid={'user-role-select'}
                        disabled={
                          this.props.loggedInUserType !==
                          ADMIN_USER_ROLES.MASTER
                        }
                        name="user_role"
                        onChange={this.handleUserRoleOnChange(
                          props.setFieldValue
                        )}
                        value={props.values.user_role}
                      >
                        <option disabled value={''}>
                          --Select--
                        </option>
                        {[
                          ADMIN_USER_ROLES.ADMIN,
                          ADMIN_USER_ROLES.ANALYTICS,
                        ].map((userRole) => (
                          <option
                            name={'user-role-option'}
                            key={userRole}
                            value={userRole}
                          >
                            {ADMIN_USER_LABELS[userRole]}
                          </option>
                        ))}
                      </select>
                    )}
                    {!!props.errors.userRole && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.userRole}
                      </span>
                    )}
                  </div>
                  <div className="knock-react-flex">
                    <div
                      className="modal-field-label"
                      id={LABEL_IDS.first_name}
                    >
                      First name:
                    </div>
                    <div className="modal-field-input">
                      <input
                        aria-labelledby={LABEL_IDS.first_name}
                        autoFocus
                        className="knock-react-input"
                        value={props.values.first_name}
                        name="first_name"
                        onBlur={trimValue(props.setFieldValue)}
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="modal-field-label" id={LABEL_IDS.last_name}>
                      Last name:
                    </div>
                    <div className="modal-field-input">
                      <input
                        aria-labelledby={LABEL_IDS.last_name}
                        className="knock-react-input"
                        value={props.values.last_name}
                        name="last_name"
                        onBlur={trimValue(props.setFieldValue)}
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="modal-field-label" id={LABEL_IDS.username}>
                      Username:
                    </div>
                    <div className="modal-field-input">
                      <input
                        aria-labelledby={LABEL_IDS.username}
                        className={
                          'knock-react-input icon ' +
                          (this.state.isCheckingUsernameAvailability
                            ? null
                            : this.state.isUsernameAvailable
                            ? 'available'
                            : 'unavailable')
                        }
                        value={props.values.username}
                        name="username"
                        onChange={(event) => this.usernameChanged(event, props)}
                      />
                      {!!props.errors.username && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.username}
                        </span>
                      )}
                      {this.state.isCheckingUsernameAvailability ? (
                        <span
                          data-testid="checking"
                          className="input-append-text"
                        >
                          <i className="fa fa-spin fa-cog" />
                        </span>
                      ) : this.state.isUsernameAvailable ? (
                        <span
                          data-testid="available"
                          className="input-append-text txt-success knock-react-form-hint"
                        >
                          <i className="fa fa-check-circle" />
                        </span>
                      ) : (
                        <span
                          data-testid="not-available"
                          className="input-append-text txt-danger knock-react-form-hint"
                        >
                          <i className="fa fa-exclamation-triangle" />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="modal-field-label" id={LABEL_IDS.email}>
                      Contact email:
                    </div>
                    <div className="modal-field-input">
                      <input
                        aria-labelledby={LABEL_IDS.email}
                        className="knock-react-input"
                        value={props.values.email}
                        name="email"
                        onChange={whiteSpaceRestrictValue(props.setFieldValue)}
                      />
                    </div>
                  </div>
                  {this.props.addingAdminUser && (
                    <span>
                      <div className="knock-react-flex">
                        <div
                          className="modal-field-label"
                          id={LABEL_IDS.password}
                        >
                          Password:
                        </div>
                        <div className="modal-field-input">
                          <input
                            aria-labelledby={LABEL_IDS.password}
                            className="knock-react-input"
                            type="password"
                            value={props.values.password}
                            name="password"
                            onChange={whiteSpaceRestrictValue(
                              props.setFieldValue
                            )}
                          />
                          {!!props.errors.password && (
                            <span className="knock-react-form-hint knock-react-form-hint-error">
                              {props.errors.password}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="knock-react-flex">
                        <div
                          className="modal-field-label"
                          id={LABEL_IDS.passwordConfirm}
                        >
                          Confirm password:
                        </div>
                        <div className="modal-field-input">
                          <input
                            aria-labelledby={LABEL_IDS.passwordConfirm}
                            className="knock-react-input"
                            type={'password'}
                            value={props.values.passwordConfirm}
                            name="passwordConfirm"
                            onChange={whiteSpaceRestrictValue(
                              props.setFieldValue
                            )}
                          />
                          {!!props.errors.passwordConfirm && (
                            <span className="knock-react-form-hint knock-react-form-hint-error">
                              {props.errors.passwordConfirm}
                            </span>
                          )}
                        </div>
                      </div>
                    </span>
                  )}

                  {this.shouldShowAdminUserCreatePermission(
                    props.values.user_role
                  ) && (
                    <div className="knock-react-flex">
                      <div
                        className="modal-field-label"
                        id={LABEL_IDS.isUserCreationEnabled}
                      >
                        {USER_CREATION_LABEL}
                      </div>
                      <input
                        aria-labelledby={LABEL_IDS.isUserCreationEnabled}
                        name="isUserCreationEnabled"
                        type="checkbox"
                        checked={props.values.isUserCreationEnabled}
                        onChange={props.handleChange}
                      />
                      <InfoIconTooltip message={USER_CREATION_MESSAGE} />
                    </div>
                  )}

                  {this.props.loggedInUserType === ADMIN_USER_ROLES.MASTER &&
                    this.state.companyLeasingTeams &&
                    this.renderLeasingTeams(props)}
                </ModalContent>
              </PageContainer>
              <ModalFooter>
                <button
                  className="knock-react-button"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
                <button
                  className="knock-react-button"
                  disabled={
                    this.state.saveButtonDisabled ||
                    !this.state.isUsernameAvailable
                  }
                  type="submit"
                >
                  Save
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  renderLeasingTeams(formProps) {
    if (_.isEmpty(this.state.companyLeasingTeams)) {
      return (
        <div>
          <Alert>There are no leasing teams.</Alert>
        </div>
      );
    }

    return (
      <span className="leasing-teams-editor">
        <div className="knock-react-flex">
          <div className="modal-field-label">Leasing Teams:</div>

          <div className="knock-react-input" name="leasing-teams">
            {this.state.adminLeasingTeams &&
              this.listLeasingTeams(this.state.adminLeasingTeams, 'added ')}
            <input
              className="knock-react-input leasing-team-search"
              name="searchTerm"
              value={formProps.values.searchTerm}
              type="string"
              onChange={formProps.handleChange}
              placeholder="Search Teams..."
            />
            {formProps.values.searchTerm && this.leasingTeamSearch(formProps)}
            <button
              type="button"
              className="knock-react-button add-all"
              onClick={this.addAllLeasingTeams.bind(this)}
            >
              Add all
              <i className="fa fa-plus fa-lg" />
            </button>
            <button
              type="button"
              className="knock-react-button remove-all"
              onClick={this.removeAllLeasingTeams.bind(this)}
            >
              Remove all
              <i className="fa fa-times fa-lg" />
            </button>
          </div>
        </div>
      </span>
    );
  }

  listLeasingTeams = (leasingTeams, key) => {
    return (
      <ul className="modal-list">
        {leasingTeams.map((leasingTeam) => {
          return (
            <li
              className="modal-list-item"
              key={key + leasingTeam.leasing_team_id}
              value={leasingTeam.leasing_team_id}
            >
              <button
                type="button"
                className="inline-list-button"
                onClick={this.updateAdminLeasingTeam.bind(this, leasingTeam)}
                value={leasingTeam.leasing_team_id}
              >
                {leasingTeam.leasing_team_name}
                {this.state.adminLeasingTeams.includes(leasingTeam) ? (
                  <i className="fa fa-times-circle fa-lg button-remove"></i>
                ) : (
                  <i className="fa fa-plus-circle fa-lg button-add"></i>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    );
  };

  handleUserRoleOnChange = (setFieldValue) => (e) => {
    const { value: userRole } = e.target;

    if (this.context.isAdminPasswordResetEnabled) {
      setFieldValue(
        'isUserCreationEnabled',
        userRole === ADMIN_USER_ROLES.ADMIN
      );
    }

    setFieldValue('user_role', userRole);
  };

  usernameChanged = (event, formProps) => {
    const { username } = formProps.values;
    const value = removeSpaces(event.target.value);

    if (username === value) {
      return this.setState({ usernameAvailable: true });
    }

    formProps.setFieldValue('username', value);
    this.setState({ isCheckingUsernameAvailability: true }, () =>
      this.usernameDebounce(value)
    );
  };

  updateAdminLeasingTeam(selectedLeasingTeam) {
    const currentAdminLeasingTeams = this.state.adminLeasingTeams;

    const noAdminLeasingTeams =
      typeof currentAdminLeasingTeams === undefined ||
      !currentAdminLeasingTeams;
    const addAdminLeasingTeam =
      !currentAdminLeasingTeams.includes(selectedLeasingTeam);
    const removeAdminLeasingTeam =
      currentAdminLeasingTeams.includes(selectedLeasingTeam);

    if (noAdminLeasingTeams) {
      this.setState({
        adminLeasingTeams: [selectedLeasingTeam],
      });
    }

    if (addAdminLeasingTeam) {
      this.setState((prevState) => ({
        adminLeasingTeams: [
          ...prevState.adminLeasingTeams,
          selectedLeasingTeam,
        ],
      }));
    }

    if (removeAdminLeasingTeam) {
      const adminIndex = currentAdminLeasingTeams.findIndex(
        (team) => team.leasing_team_id === selectedLeasingTeam.leasing_team_id
      );

      this.setState((prevState) => ({
        adminLeasingTeams: [
          ...prevState.adminLeasingTeams.slice(0, adminIndex),
          ...prevState.adminLeasingTeams.slice(adminIndex + 1),
        ],
      }));
    }

    this.setState({ searchTerm: '' });
  }

  addAllLeasingTeams() {
    this.setState({
      adminLeasingTeams: [...this.state.companyLeasingTeams],
    });
  }

  removeAllLeasingTeams() {
    this.setState({
      adminLeasingTeams: [],
    });
  }

  leasingTeamSearch(formProps) {
    const { adminLeasingTeams, companyLeasingTeams } = this.state;

    const mappedSelectedTeams = adminLeasingTeams.reduce((result, current) => {
      result[current.leasing_team_name] = true;
      return result;
    }, {});

    const filteredTeams = companyLeasingTeams.filter(
      ({ leasing_team_name }) => {
        const nameDoesMatch =
          leasing_team_name
            .trim()
            .toLowerCase()
            .indexOf(formProps.values.searchTerm.trim().toLowerCase()) !== -1;
        const teamIsSelected =
          mappedSelectedTeams.hasOwnProperty(leasing_team_name);
        return nameDoesMatch && !teamIsSelected;
      }
    );

    if (filteredTeams.length === 0) {
      return <div>No teams found...</div>;
    }

    return this.listLeasingTeams(filteredTeams, 'results');
  }

  //Prevent the enter key from bubbling up and closing the form or submitting invalid data
  enterSubmitForm = (event) => {
    if (event.charCode === 13) {
      // Enter key
      event.stopPropagation();
      event.preventDefault();

      this.form.validateAll();

      // TODO validations for optional fields current validations rules duplicate required logic therefore cannot be used on optional fields without causing erros
      // console.log('Errors in form:' + _.isEmpty(!this.form.state.errors));

      if (_.isEmpty(this.form.state.errors) && this.state.isUsernameAvailable) {
        this.submit(event);
      }
    }
  };

  submit = (values) => {
    this.setState({ saveButtonDisabled: true });

    if (this.state.isUsernameAvailable && values.user_role) {
      const { adminLeasingTeams, companyLeasingTeams } = this.state;
      const { adminUser } = this.props;

      if (adminUser) {
        this.props.onAdminUserUpdated({
          admin_id: adminUser.admin_id,
          user_role: adminUser.user_role,
          adminLeasingTeams,
          companyLeasingTeams,
          ...values,
        });
      }

      if (
        this.props.hasOwnProperty('addingAdminUser') &&
        this.props.addingAdminUser
      ) {
        this.props.onAdminUserCreated({
          adminLeasingTeams,
          companyLeasingTeams,
          ...values,
        });
      }
    }
  };

  startChangePassword = () => {
    this.setState({ isChangingPassword: true });
  };

  submitPasswordChange = ({ password, passwordConfirm }) => {
    const { adminLeasingTeams, companyLeasingTeams } = this.state;
    const { adminUser } = this.props;

    this.props.onAdminUserUpdated({
      admin_id: adminUser.admin_id,
      user_role: adminUser.user_role,
      adminLeasingTeams,
      companyLeasingTeams,
      password,
      passwordConfirm,
      ...adminUser,
    });
  };

  cancel = (event) => {
    event.preventDefault();
    this.props.onCancel();
  };

  cancelPasswordEdit = (event) => {
    event.preventDefault();
    this.setState({ isChangingPassword: false });
    this.props.onCancel();
  };

  validate = (values) => {
    const errors = {};

    if (this.props.addingAdminUser && !values.user_role) {
      errors.userRole = 'Required';
    }

    if (!values.username) {
      errors.username = 'Required';
    }

    if (this.props.addingAdminUser && values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters.';
    }

    if (this.props.addingAdminUser && !values.password) {
      errors.password = 'Required';
    }

    if (this.props.addingAdminUser && !values.passwordConfirm) {
      errors.passwordConfirm = 'Required';
    }

    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Passwords must match';
    }

    return errors;
  };

  getInitialUserRole = () => {
    const { adminUser, loggedInUserType } = this.props;

    if (adminUser) {
      return adminUser.user_role;
    }

    return loggedInUserType !== ADMIN_USER_ROLES.MASTER
      ? ADMIN_USER_ROLES.ADMIN
      : '';
  };

  shouldShowAdminUserCreatePermission = (selectedUserRole) => {
    const { isAdminPasswordResetEnabled } = this.context;
    const isMasterUser =
      this.props.loggedInUserType === ADMIN_USER_ROLES.MASTER;
    return (
      isAdminPasswordResetEnabled &&
      isMasterUser &&
      selectedUserRole === ADMIN_USER_ROLES.ADMIN
    );
  };
}

AdminUserEditor.propTypes = {
  adminUser: PropTypes.shape({
    admin_id: PropTypes.number,
    user_role: PropTypes.string,
    leasing_teams: PropTypes.array,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    user_creation_enabled: PropTypes.bool,
    username: PropTypes.string,
  }),
  addingAdminUser: PropTypes.bool,
  loggedInUserType: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onAdminUserUpdated: PropTypes.func,
  onAdminUserCreated: PropTypes.func,
};

AdminUserEditor.contextType = FeatureFlagContext;

export default AdminUserEditor;
