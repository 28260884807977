import React from 'react';
import PropTypes from 'prop-types';
import { AuthenticationService } from '@knockrentals/knock-react';
import { Redirect, Route } from 'react-router-dom';
import queryString from 'query-string';

const ProtectedRoute = ({ component, path, ...rest }) => {
  const { location } = rest;
  const queryParams = queryString.parse(location?.search);
  if (queryParams.accessToken) {
    AuthenticationService.clearRefreshToken();
    AuthenticationService.setToken(queryParams.accessToken);
  }

  const redirectToLogin = {
    pathname: '/login',
    state: { from: location },
  };

  if (AuthenticationService.isAuthenticated()) {
    return <Route path={path} component={component} {...rest} />;
  }

  if (location?.pathname === path) {
    return <Redirect to={redirectToLogin} />;
  }

  return null;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  computedHash: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  path: PropTypes.string.isRequired,
};

export default ProtectedRoute;
