import { apiRemote } from '../../Services/RemoteService';

class AdminsAPI {
  static getAdminUsers() {
    return apiRemote
      .get(`admin/users/admin_users`)
      .then((response) => response.json());
  }

  static createAdminUser({
    first_name,
    last_name,
    email,
    username,
    password,
    leasing_teams,
    role,
    user_creation_enabled,
  }) {
    const payload = {
      first_name,
      last_name,
      email,
      username,
      password,
      leasing_teams,
      role,
      user_creation_enabled,
    };
    return apiRemote
      .post(`admin/users/admin_users`, payload)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static updateAdminUser(
    admin_id,
    user_role,
    {
      first_name,
      last_name,
      username,
      email,
      leasing_teams,
      password,
      user_creation_enabled,
    }
  ) {
    const payload = {
      first_name,
      last_name,
      username,
      email,
      leasing_teams,
      password,
      user_creation_enabled,
    };

    return apiRemote
      .put(`admin/users/admin_users/${user_role}/${admin_id}`, payload)
      .then((response) => response.json());
  }

  static deleteAdminUser(user_role, admin_id) {
    return apiRemote
      .delete(`admin/users/admin_users/${user_role}/${admin_id}`)
      .then((response) => response.json());
  }
}

export default AdminsAPI;
