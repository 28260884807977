import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Toaster } from '@knockrentals/knock-react';
import { AuthenticationService } from '@knockrentals/knock-react';
import CustomFiltersDialog from './CustomFiltersDialog';
import SelfieScanSettings from './SelfieScanSettings';
import moment from 'moment';
import PropertiesAPI from '../Properties/PropertiesAPI';
import Tooltip from '@material-ui/core/Tooltip';
import { LeasingProviderOptionsEnum } from '../Properties/Leasing/constants';
import './_groupSettings.scss';

const TOOL_TIP_TEXT = {
  enableResidentCalls:
    'If left unchecked Resident calls will bypass the Knock Voice App logged in Agents and go straight to the Resident destination number',
};

const DEFAULT_MAX_VALUE = 365;

const SETTINGS_DISPLAY = {
  account: {
    label: 'Account',
    settingName: 'hide_account_views',
    invertCheckbox: true,
  },
  accountPreferencesPage: {
    label: 'Account (Preferences page)',
    settingName: 'hide_account_preferences',
    invertCheckbox: true,
  },
  analytics: {
    label: 'Analytics',
    settingName: 'hide_analytics_views',
    invertCheckbox: true,
  },
  hideResidentsPage: {
    label: 'Hide residents page',
    settingName: 'hide_residents',
  },
  properties: {
    label: 'Properties',
    settingName: 'hide_property_views',
    invertCheckbox: true,
  },
  quoting: { label: 'Quoting', settingName: 'quotes_enabled' },
  toolbox: {
    label: 'Toolbox',
    settingName: 'hide_marketing_tools',
    invertCheckbox: true,
  },
  sharedLogin: { label: 'Shared login', settingName: 'share_login_enabled' },
  supportChatButton: {
    label: 'Support Chat Button (bottom right)',
    settingName: 'show_intercom',
  },
  enableBrowserCalling: {
    label: 'Enable Browser Calling',
    settingName: 'enable_browser_calling',
  },
  enableCallerHungUp: {
    label: 'Caller Hung Up = NAP',
    settingName: 'mark_hangups_nap',
  },
  enableCheatproofScore: {
    label: 'Cheatproof Engagement Score',
    settingName: 'enable_cheatproof_engagement_score',
  },
  enableCrossSell: {
    label: 'Enable Cross-Sell',
    settingName: 'enable_cross_sell',
  },
  enableMobileTours: {
    label: 'Enable Mobile Tours',
    settingName: 'enable_mobile_tours',
  },
  enableResidentCheatproofEngagementScore: {
    label: 'Enable Resident Cheatproof Engagement Score',
    settingName: 'enable_cheatproof_resident_engagement_score',
  },
  enableResidentToDoList: {
    label: 'Enable Resident To-Do List',
    settingName: 'enable_resident_to_do_list',
  },
  enableResidentCalls: {
    label: 'Enable Resident Calls',
    settingName: 'enable_resident_calls',
    toolTipText: TOOL_TIP_TEXT.enableResidentCalls,
  },
  enableDemandX: {
    label: 'Enable DemandX',
    settingName: 'enable_demand_x',
  },
  enableCompleteTourDrawer: {
    label: 'Enable Complete Tour Drawer',
    settingName: 'enable_complete_tour_drawer',
  },
  enableApplicantTodoList: {
    label: 'Enable Applicant To-Do List ',
    settingName: 'enable_applicant_todo_list',
  },
  applicantTodoFollowupCadence: {
    label: 'Applicant To-Do Followup Cadence',
    settingName: 'applicant_todo_followup_cadence',
    maxValue: 96,
  },
  includeApplicantsInEngagementScore: {
    label: 'Include Applicants in Engagement Score',
    settingName: 'include_applicants_in_engagement_score',
  },
};

const isValuerInRetentionPeriodRange = (value) => {
  const numberValue = Number(value);
  return numberValue >= 0 && numberValue <= 365;
};

class GroupSettings extends Component {
  state = {
    filterModalIsOpen: false,
    selectedFilterIndex: -1,
    properties: this.props.properties || [],
    retentionPeriodDays: this.props.group.communication_retention_period_days,
    isLeasingProviderOnSite: false,
  };

  componentDidMount() {
    const isLeasingProviderOnSiteForAllProperties = this.props.properties.every(
      (property) =>
        property.data.leasing.provider === LeasingProviderOptionsEnum.OnSite
    );
      this.setState({ isLeasingProviderOnSite: isLeasingProviderOnSiteForAllProperties });
  }

  render() {
    const internalMode = AuthenticationService._internalMode;
    return (
      <div className="group-settings">
        <h2>{this.props.group.group_name}</h2>

        <h3>General Settings</h3>
        <table className="wire-frame-table">
          <tbody>
            <tr>
              <td>Send post tour follow-up SMS</td>
              <td>
                <select
                  className="w-full"
                  value={this.props.group.appointment_followup_delay || ''}
                  onChange={this.selectChanged('appointment_followup_delay')}
                >
                  <option value={''}>Never</option>
                  <option value={'1:00:00'}>1 hour after appointment</option>
                  <option value={'2:00:00'}>2 hours after appointment</option>
                  <option value={'3:00:00'}>3 hours after appointment</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Craigslist reminders</td>
              <td>
                <select
                  className="w-full"
                  value={
                    this.props.group.craigslist_settings.reminder_frequency
                  }
                  onChange={this.craigslistSelectChanged('reminder_frequency')}
                >
                  <option value={0}>Never</option>
                  <option value={1}>Daily</option>
                  <option value={2}>Twice a day</option>
                  <option value={3}>Three times a day</option>
                  <option value={4}>Four times a day</option>
                  <option value={5}>Five times a day</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Craigslist template</td>
              <td>
                <select
                  className="w-full"
                  value={
                    this.props.group.craigslist_settings.company_template_id
                  }
                  onChange={this.craigslistSelectChanged('company_template_id')}
                >
                  {this.renderCraigslistTemplateOptions()}
                </select>
              </td>
            </tr>
            <tr>
              <td>Quotes expire after</td>
              <td>
                <select
                  className="w-full"
                  id="quote_expiration_hours"
                  value={this.props.group.quote_expiration_hours || 48}
                  onChange={this.selectChanged('quote_expiration_hours')}
                >
                  <option value={24}>24 Hours</option>
                  <option value={48}>48 Hours</option>
                  <option value={72}>72 Hours</option>
                  <option value={96}>96 Hours</option>
                </select>
              </td>
            </tr>
            {this.renderToggleOption(SETTINGS_DISPLAY.enableCheatproofScore)}
            {this.renderToggleOption(SETTINGS_DISPLAY.enableBrowserCalling)}
            {this.renderToggleOption(SETTINGS_DISPLAY.enableCrossSell)}
            {internalMode &&
              this.renderToggleOption(SETTINGS_DISPLAY.enableDemandX)}
            {this.renderToggleOption(
                SETTINGS_DISPLAY.enableCompleteTourDrawer
              )}
          </tbody>
        </table>

        <h3>Voice App Settings</h3>
        <table className="wire-frame-table">
          <thead></thead>
          <tbody>
            {this.renderToggleOption(SETTINGS_DISPLAY.enableResidentCalls)}
            {this.renderToggleOption(SETTINGS_DISPLAY.enableCallerHungUp)}
          </tbody>
        </table>

        <h3>Show/Hide Settings</h3>
        <h4 className="text-light uppercase mb-1">Miscellaneous</h4>

        <table className="wire-frame-table">
          <thead>
            <tr>
              <th>Element</th>
              <th>Show Leasing Team UI?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Manual posting/deactivating of listings</td>
              <td className="text-center">
                <input
                  type="checkbox"
                  id="allowed_property_type"
                  checked={this.props.group.allowed_property_type === 'mf'}
                  onChange={this.propertyTypesChanged()}
                />
              </td>
            </tr>
            {this.renderToggleOption(SETTINGS_DISPLAY.quoting)}
          </tbody>
        </table>

        <h4 className="text-light uppercase mb-1">Lefthand Navigation</h4>
        <p className="text-dark">
          Settings for the lefthand navigation bar in the leasing application.
        </p>

        <table className="wire-frame-table">
          <thead>
            <tr>
              <th>Element</th>
              <th>Show Leasing Team UI?</th>
            </tr>
          </thead>
          {this.renderToggleOption(SETTINGS_DISPLAY.properties)}
          {this.renderToggleOption(SETTINGS_DISPLAY.account)}
          {this.renderToggleOption(SETTINGS_DISPLAY.accountPreferencesPage)}
          {this.renderToggleOption(SETTINGS_DISPLAY.toolbox)}
          {this.renderToggleOption(SETTINGS_DISPLAY.supportChatButton)}
          {this.renderToggleOption(SETTINGS_DISPLAY.analytics)}
          {this.renderToggleOption(SETTINGS_DISPLAY.sharedLogin)}
        </table>

        <h3>Resident CRM Settings</h3>

        <table className="wire-frame-table">
          <tbody>
            {this.renderToggleOption(SETTINGS_DISPLAY.hideResidentsPage)}
            <tr>
              <td>New resident SMS invitation</td>
              <td>
                <select
                  className="w-full"
                  value={
                    this.props.group.is_resident_sms_consent_invitation_manual
                  }
                  onChange={this.onChangeResidentSMSConsent}
                >
                  <option value={true}>Manually send invitation</option>
                  <option value={false}>Auto-send invitation</option>
                </select>
              </td>
            </tr>
            {this.renderToggleOption(SETTINGS_DISPLAY.enableResidentToDoList)}
            {this.renderToggleOption(
              SETTINGS_DISPLAY.enableResidentCheatproofEngagementScore
            )}
            <tr>
              <td>Resident Renewal Outreach Threshold</td>
              <td className="text-center">
                <input
                  type="input"
                  id="resident_renewal_outreach_threshold"
                  style={{ width: '25px' }}
                  defaultValue={
                    this.props.group.resident_renewal_outreach_threshold
                  }
                  onBlur={this.inputChanged(
                    'resident_renewal_outreach_threshold'
                  )}
                />
                <label>
                  <strong> days</strong>
                </label>
              </td>
            </tr>
            <tr>
              <td>Resident Renewal Followup Cadence</td>
              <td className="text-center">
                <input
                  type="input"
                  id="resident_renewal_followup_cadence"
                  style={{ width: '25px' }}
                  defaultValue={
                    this.props.group.resident_renewal_followup_cadence
                  }
                  onBlur={this.inputChanged(
                    'resident_renewal_followup_cadence'
                  )}
                />
                <label>
                  <strong> days</strong>
                </label>
              </td>
            </tr>
          </tbody>
        </table>

        {internalMode && (
          <div>
            <h3>Applicant CRM Settings</h3>

            <table className="wire-frame-table">
              <tbody>
                {this.renderToggleOption(
                  SETTINGS_DISPLAY.enableApplicantTodoList
                )}
                <tr>
                  <td>{SETTINGS_DISPLAY.applicantTodoFollowupCadence.label}</td>
                  <td className="text-center">
                    <input
                      type="text"
                      id={
                        SETTINGS_DISPLAY.applicantTodoFollowupCadence
                          .settingName
                      }
                      style={{ width: '25px' }}
                      defaultValue={
                        this.props.group.applicant_todo_followup_cadence
                      }
                      onBlur={this.inputChanged(
                        SETTINGS_DISPLAY.applicantTodoFollowupCadence
                          .settingName,
                        SETTINGS_DISPLAY.applicantTodoFollowupCadence.maxValue
                      )}
                    />
                    <label>
                      <strong> hours</strong>
                    </label>
                  </td>
                </tr>


                {this.renderToggleOption({
                  ...SETTINGS_DISPLAY.includeApplicantsInEngagementScore,
                  disabled: this.state.isLeasingProviderOnSite,
                })}
              </tbody>
            </table>
          </div>
        )}

        {internalMode && (
          <div>
            <h3>Mobile Settings</h3>
            <p>
              Enabling Mobile Tours turns on Selfie-Scan for all Properties (see
              SelfieScan Settings section below).<br></br>
              You can still toggle Selfie-Scan for individual Properties after
              turning on Mobile Tours.
            </p>
            <table className="wire-frame-table">
              <tbody>
                {this.renderToggleOption(SETTINGS_DISPLAY.enableMobileTours)}
              </tbody>
            </table>
          </div>
        )}

        {internalMode && (
          <div>
            <h3>Retention Settings</h3>
            <table className="wire-frame-table">
              <tbody>
                <tr>
                  <td>Retention Period</td>
                  <td className="text-center">
                    <input
                      id="communication_retention_period_days"
                      style={{ width: '40px' }}
                      value={this.state.retentionPeriodDays}
                      onBlur={(e) => this.handleOnBlurRetentionPeriod(e)}
                      onChange={(e) => this.validateRetentionPeriodOnChange(e)}
                    />
                    <label>
                      <strong> days</strong>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {internalMode && (
          <SelfieScanSettings groupProperties={this.state.properties} />
        )}

        <h3>Custom Settings</h3>
        {(() => {
          const customFields = this.renderCustomFieldsCheckboxes();
          if (customFields.length > 0) {
            return (
              <table className="wire-frame-table">
                <tbody>{customFields}</tbody>
              </table>
            );
          } else {
            return <div className="text-dark">No custom settings</div>;
          }
        })()}
        <div
          style={{
            display: this.props.group.custom_filters_enabled ? 'block' : 'none',
          }}
        >
          <h3>Custom Prospect Date Filters</h3>

          {this.props.group.custom_prospect_report_filter.length > 0 ? (
            <table className="wire-frame-table">
              <thead>
                <tr>
                  <th>Display Name</th>
                  <th>Date Range</th>
                  <th>Default Selection</th>
                  <th style={{ minWidth: '60px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.props.group.custom_prospect_report_filter.map(
                  (filter, index) => {
                    return (
                      <tr key={index}>
                        <td>{filter.name}</td>
                        <td>
                          {moment(filter.start_date).format('YYYY/MM/DD')} -{' '}
                          {moment(filter.end_date).format('YYYY/MM/DD')}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <input
                            id="defaultFilterCheckbox"
                            type="checkbox"
                            onChange={this.onChangeDefaultCustomFilter.bind(
                              this,
                              index
                            )}
                            checked={filter.is_default}
                          />
                        </td>
                        <td style={{ textAlign: 'center', minWidth: '60px' }}>
                          <i
                            id="editCustomFilter"
                            className="fa fa-pencil"
                            style={{ paddingRight: '20px' }}
                            onClick={this.renderCustomFilterModal.bind(
                              this,
                              index
                            )}
                          />
                          <i
                            id="deleteCustomFilter"
                            className="fa fa-trash"
                            style={{
                              color: this.isFilterDeletable(filter.is_default)
                                ? 'red'
                                : 'gray',
                            }}
                            onClick={this.onRemoveCustomFilter.bind(
                              this,
                              index
                            )}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          ) : null}

          {this.props.group.custom_prospect_report_filter.length < 6 ? (
            <button
              id="addCustomFilter"
              className="knock-react-button"
              onClick={this.renderCustomFilterModal.bind(this, -1)}
            >
              <i className="fa fa-plus" /> Add Filter
            </button>
          ) : (
            <div style={{ paddingTop: '10px' }}>
              Maximum of six filters per team allowed
            </div>
          )}

          {this.state.filterModalIsOpen ? (
            <CustomFiltersDialog
              closeHandler={this.renderCustomFilterModal.bind(this, -1)}
              selectedIndex={this.state.selectedFilterIndex}
              settings={this.props.group}
              filterUpdated={this.onSubmitCustomFilterModal.bind(this)}
            />
          ) : null}
        </div>
      </div>
    );
  }

  propertyTypesChanged() {
    return (event) => {
      const checked = event.target.checked;
      let updatedSettings = { ...this.props.group };
      updatedSettings.allowed_property_type = checked ? 'mf' : 'none';

      this.settingsUpdated(updatedSettings);
    };
  }

  renderCraigslistTemplateOptions() {
    return this.props.group.craigslist_settings.all_templates.map(
      (template) => {
        return (
          <option
            key={template.id}
            value={template.id}
            disabled={template.name === 'short_code'}
          >
            {template.name === 'default' && 'Standard'}
            {template.name === 'weidner' && 'Non-standard'}
            {template.name === 'short_code' && 'Short code (deprecated)'}
            {template.name === 'template_four' && 'Template 4'}
          </option>
        );
      }
    );
  }

  renderCustomFieldsCheckboxes() {
    const customFieldsSection = [];
    if (
      this.props.group.custom_fields &&
      !!Object.keys(this.props.group.custom_fields).length
    ) {
      Object.keys(this.props.group.custom_fields).forEach((key) => {
        const { desc_short, is_active } = this.props.group.custom_fields[key];
        customFieldsSection.push(
          <tr key={key}>
            <td>{desc_short}</td>
            <td>
              <input
                type="checkbox"
                id={`custom-field-${key}`}
                checked={is_active}
                onChange={this.customFieldCheckboxChanged(key)}
              />
            </td>
          </tr>
        );
      });
    }
    return customFieldsSection;
  }

  checkboxChanged(key, invertCheckbox = false) {
    return (event) => {
      let updatedSettings = { ...this.props.group };
      updatedSettings[key] = invertCheckbox
        ? !event.target.checked
        : event.target.checked;

      if (
          key == SETTINGS_DISPLAY.enableApplicantTodoList.settingName &&
          updatedSettings.enable_applicant_todo_list &&
          !this.state.isLeasingProviderOnSite
      ) {
          updatedSettings.include_applicants_in_engagement_score = true;
      }

      this.settingsUpdated(updatedSettings);

      if (
        key === 'enable_mobile_tours' &&
        updatedSettings['enable_mobile_tours'] === true
      ) {
        this.turnOnSelfieScanForProperties(updatedSettings);
      }
    };
  }

  onChangeResidentSMSConsent = (e) => {
    this.settingsUpdated({
      ...this.props.group,
      is_resident_sms_consent_invitation_manual:
        e.target.value === 'true' ? true : false,
    });
  };

  selectChanged(key) {
    return (event) => {
      let updatedSettings = { ...this.props.group };
      updatedSettings[key] = event.target.value;

      this.settingsUpdated(updatedSettings);
    };
  }

  inputChanged(key, maxValue = DEFAULT_MAX_VALUE) {
    const minValue = 1;

    return (event) => {
      const validNumberWithinRange =
        /^\d+$/.test(event.target.value) &&
        parseInt(event.target.value, 10) >= minValue &&
        parseInt(event.target.value, 10) <= maxValue;

      if (!validNumberWithinRange) {
        Toaster.showToast(
          `Must be an integer between ${minValue} and ${maxValue}`,
          2000,
          Toaster.ToastClasses.error
        );
        return;
      }

      let updatedSettings = { ...this.props.group };
      updatedSettings[key] = event.target.value;
      this.settingsUpdated(updatedSettings);
    };
  }

  hasRetentionPeriodChanged = (newValue, currentValue) => {
    if (newValue === '') {
      return currentValue !== null && newValue !== currentValue;
    }
    return Number(newValue) !== currentValue;
  };

  handleOnBlurRetentionPeriod = ({ target: { value } }) => {
    const key = 'communication_retention_period_days';
    const updatedSettings = { ...this.props.group };
    if (this.hasRetentionPeriodChanged(value, this.props.group[key])) {
      if (value === '') {
        updatedSettings[key] = null;
        this.settingsUpdated(updatedSettings);
        return;
      }
      if (!isValuerInRetentionPeriodRange(value)) {
        Toaster.showToast(
          'Must be an integer between 0 and 365',
          2000,
          Toaster.ToastClasses.error
        );
        return;
      }
      updatedSettings[key] = Number(value);
      this.settingsUpdated(updatedSettings);
    }
  };

  validateRetentionPeriodOnChange = ({ target: { value } }) => {
    const cleanValue = value.replace(/[^\d]/g, '');
    if (cleanValue === '' || isValuerInRetentionPeriodRange(cleanValue)) {
      const retentionPeriodDays =
        cleanValue === '' ? cleanValue : Number(cleanValue).toString();
      this.setState({ retentionPeriodDays });
    }
  };

  craigslistSelectChanged(key) {
    return (event) => {
      let updatedSettings = { ...this.props.group };
      updatedSettings.craigslist_settings[key] = event.target.value;

      this.settingsUpdated(updatedSettings);
    };
  }

  customFieldCheckboxChanged(key) {
    return (event) => {
      let updatedSettings = { ...this.props.group };
      updatedSettings.custom_fields[key]['is_active'] = event.target.checked;
      updatedSettings.updated_custom_field_id = key;
      this.settingsUpdated(updatedSettings);
    };
  }

  turnOnSelfieScanForProperties = () => {
    const properties = this.state.properties;
    const propertiesToUpdate = properties.filter(
      (p) => p.preferences.enable_selfie_scan === false
    );

    const allDoneCallback = () => {
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      this.setState({ properties: properties });
    };

    const updateApiCall = async (item, callback) => {
      PropertiesAPI.updateProperty(item.data.property_id, item)
        .then(() => {
          callback();
        })
        .catch((e) => {
          Toaster.showToast(
            'Error saving property info.',
            2000,
            Toaster.ToastClasses.error
          );
          console.log(e);
        });
    };

    let numItemsProcessed = 0;

    propertiesToUpdate.forEach((item) => {
      item.preferences.enable_selfie_scan = true;
      updateApiCall(item, () => {
        numItemsProcessed += 1;
        if (numItemsProcessed === propertiesToUpdate.length) {
          allDoneCallback();
        }
      });
    });
  };

  settingsUpdated(updatedSettings) {
    this.props.onSettingsChanged(this.props.group.group_id, updatedSettings);
  }

  /*
   * Renders a toggle table row.
   * @param {Object} props
   * @param {string} props.label - The text label that appears on the UI.
   * @param {string} props.settingName - The attribute in this.props.group to
   *                                     check and set
   * @param {bool} props.invertCheckbox - should the output and input be inverted?
   *                                      e.g. if the label is "show X" and the
   *                                      setting is "hide_x"
   * @param {function} props.changeHandler - function that is called that handles
   *                                         a change event
   *
   */
  renderToggleOption({
    label,
    settingName,
    invertCheckbox = false,
    changeHandler,
    toolTipText = '',
    disabled = false
  }) {
    let isChecked = invertCheckbox
      ? !this.props.group[settingName]
      : this.props.group[settingName];

    if (isChecked === undefined) {
      isChecked = false;
    }

    if (invertCheckbox === undefined) {
      invertCheckbox = false;
    }

    if (changeHandler === undefined) {
      changeHandler = this.checkboxChanged(settingName, invertCheckbox);
    }

    return (
      <tr>
        <td>{label}</td>
        <td className="text-center">
          <input
            type="checkbox"
            id={settingName}
            checked={isChecked}
            onChange={changeHandler}
            disabled={disabled}
          />
        </td>
        {toolTipText && (
          <td className="tooltip-cell">
            <Tooltip title={toolTipText}>
              <span className="fa-regular fa-question-circle"></span>
            </Tooltip>
          </td>
        )}
      </tr>
    );
  }

  renderCustomFilterModal(index) {
    return this.setState({
      selectedFilterIndex: index,
      filterModalIsOpen: !this.state.filterModalIsOpen,
    });
  }

  onChangeDefaultCustomFilter(selectedIndex) {
    let updatedSettings = { ...this.props.group };
    updatedSettings.custom_prospect_report_filter.map(
      (filter, index) => (filter.is_default = index === selectedIndex)
    );

    this.settingsUpdated(updatedSettings);
  }

  onRemoveCustomFilter(index) {
    if (
      this.isFilterDeletable(
        this.props.group.custom_prospect_report_filter[index].is_default
      )
    ) {
      let updatedSettings = { ...this.props.group };
      updatedSettings.custom_prospect_report_filter.splice(index, 1);
      this.settingsUpdated(updatedSettings);
    }
  }

  isFilterDeletable(isDefault) {
    return (
      !isDefault || this.props.group.custom_prospect_report_filter.length === 1
    );
  }

  onSubmitCustomFilterModal(customFilters) {
    let updatedSettings = { ...this.props.group };
    updatedSettings.custom_prospect_report_filter = customFilters;
    this.settingsUpdated(updatedSettings);
  }
}

GroupSettings.propTypes = {
  group: PropTypes.object.isRequired,
  onSettingsChanged: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GroupSettings;
