import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@knockrentals/knock-shared-web';

import {
  AI_TEMPLATE_ORDER as templateOrder,
  AI_TEMPLATE_TYPES,
  AI_TEMPLATE_TYPE_TITLES,
} from '../AiConstants';
import { AddIconButton } from '../../../common/AddIconButton';
import { AiTemplateTypeAccordion } from '../AiTemplateTypeAccordion';
import { AiTemplates } from './AiTemplates';
import AiSetupBanner from '../AiSetupBanner';

import './AiTemplatesList.scss';

const AiTemplatesList = ({ setAiTemplate, templatesByType, displayAlertSetup }) => {
  return (
    <>
      {displayAlertSetup && <AiSetupBanner />}
      <section className="ai-template-list">
        <List>
          {templateOrder.map((templateType) => {
            const { templates = [], templateCount = 0 } =
              templatesByType[templateType] || {};

            return (
              <AiTemplateTypeAccordion
                key={templateType}
                templateCount={templateCount}
                title={AI_TEMPLATE_TYPE_TITLES[templateType]}
              >
                <AiTemplates
                  setAiTemplate={setAiTemplate}
                  templates={templates}
                  templateType={templateType}
                  intentTemplates={templatesByType[templateType]}
                />
                {templateType !== AI_TEMPLATE_TYPES.AI_POWERED_WEBFORM && (
                  <AddIconButton
                    handleOnClick={() =>
                      setAiTemplate({
                        templateType,
                        intentTemplates: templatesByType[templateType],
                      })
                    }
                    primaryText="Create New Template"
                  />
                )}
              </AiTemplateTypeAccordion>
            );
          })}
        </List>
      </section>
    </>
  );
};

AiTemplatesList.propTypes = {
  setAiTemplate: PropTypes.func,
  displayAlertSetup: PropTypes.bool,
  templatesByType: PropTypes.objectOf(
    PropTypes.shape({
      intentId: PropTypes.number,
      templateCount: PropTypes.number,
      templates: PropTypes.arrayOf(
        PropTypes.shape({
          assignedPropertiesCount: PropTypes.number,
          properties: PropTypes.array,
          templateId: PropTypes.number,
          templateName: PropTypes.string,
        })
      ),
    })
  ),
};

export default AiTemplatesList;
