import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FeatureFlagContext } from '../../../Context/FeatureFlagContext';

import * as _ from 'lodash';
import { CustomFeeInput } from '../CustomFee';
import CustomFeeList from '../CustomFeeList';
import { Toaster } from '@knockrentals/knock-react';
import {
  UpchargeExplainerModal,
  EditUpchargeModal,
} from './PropertyInfoEditLeasingModals';
import InfoIconTooltip from '../components/InfoTooltip';
import {
  LeasingProviderOptionsEnum,
  COVERABLE_COST_TYPES,
  AFFORDABLE_HOUSING_TYPES,
  LEASE_LENGTH_OPTIONS,
  TOOLTIP_MESSAGES,
  UPCHARGE_LEASE_LENGTH_OPTIONS,
} from './constants';
import ApplicationInformation from './ApplicationInformation';
import { validateUrl } from '../../../Utils';

const validateApplicationUrl = (applicationUrl) => {
  if (applicationUrl === '') {
    return;
  }

  if (!/^https?:\/\//.test(applicationUrl)) {
    return 'Specified URL is invalid. URL must start with "https://" or "http://"';
  }

  if (!validateUrl(applicationUrl)) {
    return 'Specified URL is invalid.';
  }
};

class PropertyInfoEditLeasing extends Component {
  state = {
    deposit: '',
    leasingNotes: '',
    leaseBreakPenalty: '',
    leaseLengthOptions: [],

    leasingProvider: LeasingProviderOptionsEnum.CustomURL,
    applicationFee: '',
    isDefaultApplicationUrlOverridden: false,
    applicationUrl: '',
    applicationInstructions: '',
    qualificationCriteria: '',

    petsAllowed: this.generatePetsAllowedTemplate(),
    petsNotes: '',
    petsRent: '',
    petsDeposit: '',
    petsFee: '',

    coveredCosts: [],

    affordableHousingPrograms: [],
    affordableHousingNotes: '',

    newCoveredCost: '',
    newAffordableHousingProgram: '',

    propertyCustomFees: [],

    newFeeName: '',
    newFeeAmount: '',

    upchargeExplainerModalIsOpen: false,
    editUpchargeModalIsOpen: false,
    includeUpchargeInTotal: false,
    selectedUpcharge: 0,
    selectedUpchargeIndex: -1,

    errors: {},
  };

  fetchFeatureFlags = (property) => {
    // Destructure feature flags and update flag fn from context provider and state
    // If the feature flag has not already been fetched for this leasing team, initialize and set in context
    // If no leasing team, or the flag can't be found, it will evaluate to false
    const { quoteUpchargesFlags, updateQuoteUpchargesFlags } = this.context;

    const leasingTeamId = property.leasing_team_id;

    if (leasingTeamId && quoteUpchargesFlags[leasingTeamId] === undefined) {
      updateQuoteUpchargesFlags(leasingTeamId);
    }
  };

  UNSAFE_componentWillMount() {
    this.setPropertyIntoState(this.props.property);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setPropertyIntoState(nextProps.property);
  }

  setPropertyIntoState(property) {
    this.setState({
      deposit: property.data.leasing.terms.deposit
        ? property.data.leasing.terms.deposit
        : '',
      leasingSpecial: property.data.leasing.terms.leasingSpecial
        ? property.data.leasing.terms.leasingSpecial
        : '',
      leaseBreakPenalty: property.data.leasing.terms.breakPenalty
        ? property.data.leasing.terms.breakPenalty
        : '',
      leasingNotes: property.data.leasing.terms.notes
        ? property.data.leasing.terms.notes
        : '',
      leaseLengthOptions: this.generateLeaseLengths(property),
      includeUpchargeInTotal:
        property.data.leasing.terms.includeUpcharges || false,

      applicationFee: property.data.leasing.application.fee
        ? property.data.leasing.application.fee
        : '',
      applicationUrl: property.data.leasing.application.link
        ? property.data.leasing.application.link.trim()
        : '',
      applicationInstructions: property.data.leasing.application.instructions
        ? property.data.leasing.application.instructions
        : '',
      qualificationCriteria: property.data.leasing.qualificationCriteria
        ? property.data.leasing.qualificationCriteria
        : '',
      leasingProvider:
        property.data.leasing.provider || LeasingProviderOptionsEnum.CustomURL,
      isDefaultApplicationUrlOverridden:
        property.data.leasing.application.isDefaultApplicationUrlOverridden ||
        false,

      petsAllowed: property.data.pets.allowed
        ? property.data.pets.allowed
        : this.generatePetsAllowedTemplate(),
      petsNotes: property.data.pets.notes ? property.data.pets.notes : '',
      petsRent: property.data.pets.rent ? property.data.pets.rent : '',
      petsDeposit: property.data.pets.deposit ? property.data.pets.deposit : '',
      petsFee: property.data.pets.fee ? property.data.pets.fee : '',

      coveredCosts:
        property.data.costables.length > 0
          ? property.data.costables
          : this.generateNewBooleanMapping(
              COVERABLE_COST_TYPES,
              'name',
              'isCovered'
            ),

      affordableHousingNotes: property.data.affordableHousing
        ? property.data.affordableHousing.notes
        : '',
      affordableHousingPrograms: property.data.affordableHousing.programs
        ? property.data.affordableHousing.programs
        : this.generateNewBooleanMapping(
            AFFORDABLE_HOUSING_TYPES,
            'name',
            'available'
          ),

      propertyCustomFees: property.custom_fees ? property.custom_fees : [],
    });
  }

  generateNewBooleanMapping(nameList, nameString, booleanString) {
    const mapping = [];

    _.forEach(nameList, (nameValue) => {
      const obj = {};
      obj[nameString] = nameValue;
      obj[booleanString] = false;
      mapping.push(obj);
    });

    return mapping;
  }

  generateLeaseLengths(property) {
    const leaseLengths = property.data.leasing.terms.leaseLengths;
    this.fetchFeatureFlags(property);

    const lengthOptions = !this.context.quoteUpchargesFlags[
      property.leasing_team_id
    ]
      ? [...LEASE_LENGTH_OPTIONS]
      : [...UPCHARGE_LEASE_LENGTH_OPTIONS];

    if (!leaseLengths || !leaseLengths.length) {
      return lengthOptions;
    }

    for (let i = 0; i < leaseLengths.length; i += 1) {
      const leaseLengthOption = leaseLengths[i];
      const leaseLength = leaseLengthOption.leaseLength.toString();
      const match = _.find(lengthOptions, { leaseLength: leaseLength });

      if (match) {
        match.isAvailable = leaseLengthOption.isAvailable;
        if (leaseLengthOption.upchargeAmount) {
          match.upchargeAmount = leaseLengthOption.upchargeAmount;
        }
      }
    }

    return lengthOptions;
  }

  generatePetsAllowedTemplate() {
    return {
      cats: false,
      none: true,
      small_dogs: false,
      large_dogs: false,
    };
  }

  petCheckboxUpdated(type) {
    return (event) => {
      var petsAllowed = this.state.petsAllowed;

      petsAllowed[type] = event.target.checked;
      petsAllowed['none'] = !(
        petsAllowed['cats'] ||
        petsAllowed['small_dogs'] ||
        petsAllowed['large_dogs']
      );

      this.setState({
        [type]: petsAllowed,
      });

      this.submit();
    };
  }

  checkboxUpdated(type, index, boolKey) {
    return (event) => {
      const thisAttribute = this.state[type];
      thisAttribute[index][boolKey] = event.target.checked;

      this.setState({
        [type]: thisAttribute,
      });

      this.submit();
    };
  }

  includeUpchargeCheckboxUpdated() {
    return (event) => {
      const includeUpcharge = event.target.checked;
      this.setState({ includeUpchargeInTotal: includeUpcharge });

      this.submit();
    };
  }

  onLeasingProviderChanged = (e, isSubmit) => {
    const newLeasingProvider = e.target.value;

    this.setState({
      leasingProvider: newLeasingProvider,
      applicationUrl: '',
      isDefaultApplicationUrlOverridden:
        newLeasingProvider === LeasingProviderOptionsEnum.MriProspectConnect,
    });

    if (isSubmit) {
      this.submit();
    }
  };

  validate = () => {
    const errors = {};

    const applicationUrlErrorMessage = validateApplicationUrl(
      this.state.applicationUrl
    );

    if (applicationUrlErrorMessage) {
      errors.applicationUrl = applicationUrlErrorMessage;
    }

    return errors;
  };

  updateInputField = (field) => (value) => {
    this.setState({
      [field]: value,
    });
  };

  inputChanged = (field, isSubmit) => {
    return (event) => {
      if (event.target.validity.valid) {
        const targetValue =
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value;

        this.setState(
          {
            [field]: targetValue,
          },
          this.inputChangedCallback(isSubmit)
        );
      }
    };
  };

  inputChangedCallback = (isSubmit) => {
    if (!isSubmit) {
      return;
    }

    const errors = this.validate();

    this.setState({
      errors,
    });

    if (Object.keys(errors).length === 0) {
      this.submit();
    }
  };

  clearErrors = () => {
    this.setState({
      errors: {},
    });
  };

  addCustomCheckbox(type, nameKey, nameValueFromState, boolKey) {
    const thisAttribute = this.state[type];
    const nameValue = this.state[nameValueFromState];

    if (_.isEmpty(nameValue)) {
      return;
    }

    const newCheckItem = {};
    newCheckItem[nameKey] = nameValue;
    newCheckItem[boolKey] = true;
    thisAttribute.push(newCheckItem);

    this.setState({
      [nameValueFromState]: '',
      [type]: thisAttribute,
    });

    this.submit();
  }

  removeCustomCheckbox(type, index) {
    const thisAttribute = this.state[type];
    thisAttribute.splice(index, 1);
    this.setState({
      [type]: thisAttribute,
    });
    this.submit();
  }

  submit() {
    setTimeout(() => {
      const updatedCommunity = this.props.property.data;

      updatedCommunity.leasing.terms.deposit = this.state.deposit;
      updatedCommunity.leasing.terms.leasingSpecial = this.state.leasingSpecial;
      updatedCommunity.leasing.terms.breakPenalty =
        this.state.leaseBreakPenalty;
      updatedCommunity.leasing.terms.notes = this.state.leasingNotes;
      updatedCommunity.leasing.terms.leaseLengths =
        this.state.leaseLengthOptions;
      updatedCommunity.leasing.terms.includeUpcharges =
        this.state.includeUpchargeInTotal;

      updatedCommunity.leasing.application.fee = this.state.applicationFee;
      updatedCommunity.leasing.application.link = this.state.applicationUrl;
      updatedCommunity.leasing.application.instructions =
        this.state.applicationInstructions;
      updatedCommunity.leasing.application.isDefaultApplicationUrlOverridden =
        this.state.isDefaultApplicationUrlOverridden;
      updatedCommunity.leasing.qualificationCriteria =
        this.state.qualificationCriteria;
      updatedCommunity.leasing.provider = this.state.leasingProvider;

      updatedCommunity.pets.allowed = this.state.petsAllowed;
      updatedCommunity.pets.notes = this.state.petsNotes;
      updatedCommunity.pets.rent = this.state.petsRent;
      updatedCommunity.pets.deposit = this.state.petsDeposit;
      updatedCommunity.pets.fee = this.state.petsFee;

      updatedCommunity.costables = this.state.coveredCosts;
      updatedCommunity.affordableHousing.notes =
        this.state.affordableHousingNotes;
      updatedCommunity.affordableHousing.programs =
        this.state.affordableHousingPrograms;

      const updatedProperty = this.props.property;
      updatedProperty.data = updatedCommunity;

      updatedProperty.custom_fees = this.state.propertyCustomFees;

      this.props.onChange(updatedProperty);
    }, 100);
  }

  addCustomFee = () => {
    const { isCustomFeesEnabled } = this.context;

    if (!this.state.newFeeName || !this.state.newFeeAmount) {
      return Toaster.showToast(
        'Custom Fees require all fields to be filled out',
        2000,
        Toaster.ToastClasses.error
      );
    }

    // Check fee amount for any non-numeric characters, database column is formatted for integer values
    if (!isCustomFeesEnabled && String(this.state.newFeeAmount).match(/\D/))
      return Toaster.showToast(
        'Please enter a whole number for fee amount',
        2000,
        Toaster.ToastClasses.error
      );

    const newFee = {
      name: this.state.newFeeName,
      amount: Number(this.state.newFeeAmount),
      property_id: this.props.property.id,
    };

    const updatedProperty = { ...this.props.property };

    // this is the "do we update an existing custom fee or create a new one" logic, refactored slightly to
    // use the updatedProperty payload instead of constantly updating state, helps error cases
    const updateIndex = this.state.propertyCustomFees.findIndex(
      (fee) => fee.name.toLowerCase() === newFee.name.toLowerCase()
    );

    if (updateIndex === -1) {
      updatedProperty.custom_fees = [...this.state.propertyCustomFees, newFee];
    }

    if (updateIndex !== -1) {
      updatedProperty.custom_fees = [
        ...this.state.propertyCustomFees.slice(0, updateIndex),
        ...this.state.propertyCustomFees.slice(updateIndex + 1),
        newFee,
      ];
    }

    // stolen from PropertiesPage.js, if used again it should be pulled out into a util function
    const savingContent = (
      <span>
        <i className="fa fa-lg fa-spin fa-cog" /> Saving now...
      </span>
    );
    Toaster._fireShowToastHandlers(savingContent, Toaster.ToastClasses.success);

    this.props.onChange(updatedProperty);

    this.setState(() => ({
      propertyCustomFees: updatedProperty.custom_fees,
      newFeeName: '',
      newFeeAmount: '',
    }));
  };

  removeCustomFee = (index) => {
    this.setState((prevState) => ({
      propertyCustomFees: [
        ...prevState.propertyCustomFees.slice(0, index),
        ...prevState.propertyCustomFees.slice(index + 1),
      ],
    }));
    return this.submit();
  };

  toggleUpchargeExplainerModal = () => {
    this.setState({
      upchargeExplainerModalIsOpen: !this.state.upchargeExplainerModalIsOpen,
    });
  };

  openEditUpchargeModal = (selectedUpcharge, index) => {
    this.setState({ selectedUpcharge: selectedUpcharge });
    this.setState({ selectedUpchargeIndex: index });
    this.setState({ editUpchargeModalIsOpen: true });
  };

  closeEditUpchargeModal = () => {
    this.setState({ editUpchargeModalIsOpen: false });
  };

  onSubmitEditUpcharge = (upcharge) => {
    let updatedLeaseLengthOptions = [...this.state.leaseLengthOptions];
    updatedLeaseLengthOptions[this.state.selectedUpchargeIndex].upchargeAmount =
      upcharge;
    this.setState({ leaseLengthOptions: updatedLeaseLengthOptions });
    return this.submit();
  };

  render() {
    return (
      <div className="property-info-edit-container">
        <div className="full-width">
          <h2 className="side-margin-lg">
            Leasing Information
            <InfoIconTooltip message={TOOLTIP_MESSAGES.LEASING} />
          </h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">Deposit</div>
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.deposit}
                  name="deposit"
                  type="text"
                  onBlur={this.inputChanged('deposit', true).bind(this)}
                  onChange={this.inputChanged('deposit').bind(this)}
                  className="knock-react-input"
                  placeholder="e.g. 500"
                />
                <span className="input-append-text">USD / month</span>
              </div>
            </div>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">
                Lease Lengths
              </div>

              {!this.context.quoteUpchargesFlags[
                this.props.property.leasing_team_id
              ] ? (
                <div className="property-info-edit-field-input">
                  {this.state.leaseLengthOptions.map((leaseLength, key) => {
                    return (
                      <span
                        className="inline-block side-margin-lg"
                        key={`lease-length-${key}`}
                      >
                        <input
                          type="checkbox"
                          checked={leaseLength.isAvailable}
                          onChange={this.checkboxUpdated(
                            'leaseLengthOptions',
                            key,
                            'isAvailable'
                          )}
                        />
                        <span>
                          {leaseLength.leaseLength} {leaseLength.lengthUnit}
                        </span>
                      </span>
                    );
                  })}
                </div>
              ) : (
                <table className="wire-frame-table">
                  <thead>
                    <tr>
                      <th>Lease Term</th>
                      <th style={{ minWidth: '75px' }}>Active</th>
                      <th colSpan={2}>Upcharge Types and Amounts</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}></td>
                      <td colSpan={2}>
                        <span style={{ fontSize: '12px' }}>
                          Add upcharge to monthly rent
                        </span>
                        <input
                          id="includeUpcharge"
                          type="checkbox"
                          checked={this.state.includeUpchargeInTotal}
                          onChange={this.includeUpchargeCheckboxUpdated().bind(
                            this
                          )}
                          style={{ minWidth: '20px' }}
                        />
                        <span
                          id="infoModal"
                          onClick={this.toggleUpchargeExplainerModal}
                        >
                          <i className="fa fa-lg fa-question-circle"></i>
                        </span>
                      </td>
                    </tr>
                    {this.state.leaseLengthOptions.map((leaseLength, index) => {
                      return (
                        <tr key={`lease-length-${index}`}>
                          <td>
                            {leaseLength.leaseLength} {leaseLength.lengthUnit}
                          </td>
                          <td style={{ minWidth: '75px', textAlign: 'center' }}>
                            <input
                              type="checkbox"
                              id={`isAvailable-${index}`}
                              checked={leaseLength.isAvailable}
                              onChange={this.checkboxUpdated(
                                'leaseLengthOptions',
                                index,
                                'isAvailable'
                              )}
                              style={{ minWidth: '20px' }}
                            />
                          </td>
                          <td style={{ borderRight: 'none' }}>
                            <span style={{ marginLeft: '70px' }}>
                              ${leaseLength.upchargeAmount}
                            </span>
                          </td>
                          <td style={{ borderLeft: 'none' }}>
                            <span
                              id={`editUpcharge-${index}`}
                              style={{ marginLeft: '50px' }}
                              onClick={
                                leaseLength.isAvailable
                                  ? this.openEditUpchargeModal.bind(
                                      this,
                                      leaseLength.upchargeAmount,
                                      index
                                    )
                                  : null
                              }
                            >
                              <i
                                style={{
                                  color: leaseLength.isAvailable
                                    ? 'black'
                                    : 'gray',
                                }}
                                className="fa fa-lg fa-pencil-square"
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">
                Leasing Specials
              </div>
              <div className="property-info-edit-field-input">
                <textarea
                  value={this.state.leasingSpecial}
                  onBlur={this.inputChanged('leasingSpecial', true).bind(this)}
                  onChange={this.inputChanged('leasingSpecial').bind(this)}
                />
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">
                Breaking Penalties
              </div>
              <div className="property-info-edit-field-input">
                <textarea
                  value={this.state.leaseBreakPenalty}
                  onBlur={this.inputChanged('leaseBreakPenalty', true).bind(
                    this
                  )}
                  onChange={this.inputChanged('leaseBreakPenalty').bind(this)}
                />
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">Notes</div>
              <div className="property-info-edit-field-input">
                <textarea
                  value={this.state.leasingNotes}
                  onBlur={this.inputChanged('leasingNotes', true).bind(this)}
                  onChange={this.inputChanged('leasingNotes').bind(this)}
                  placeholder="This is where you can note any other leasing options or stipulations! E.g. 'We also offer 2 year leases, ask our leasing office if you're interested'"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">
            Custom Fees
            <InfoIconTooltip message={TOOLTIP_MESSAGES.CUSTOM_FEES} />
          </h2>
          <div className="knock-react-flex half-width readable-width">
            <CustomFeeList
              isCustomFeesEnabled={this.context.isCustomFeesEnabled}
              propertyCustomFees={this.state.propertyCustomFees}
              removeCustomFee={this.removeCustomFee}
            />
          </div>

          <div className="knock-react-flex">
            <div className="property-info-edit-field-label">New Fee</div>
            <div className="property-info-edit-field-input">
              <input
                value={this.state.newFeeName}
                name="feeName"
                onBlur={this.inputChanged('newFeeName').bind(this)}
                onChange={this.inputChanged('newFeeName').bind(this)}
                className="knock-react-input"
                placeholder="Fee name, ex: Cleaning"
                style={{ marginRight: '5px' }}
              />

              {this.context.isCustomFeesEnabled ? (
                <CustomFeeInput
                  amount={this.state.newFeeAmount}
                  className="knock-react-input"
                  updateInputField={this.updateInputField('newFeeAmount')}
                />
              ) : (
                <input
                  value={this.state.newFeeAmount}
                  name="feeAmount"
                  onBlur={this.inputChanged('newFeeAmount').bind(this)}
                  onChange={this.inputChanged('newFeeAmount').bind(this)}
                  className="knock-react-input"
                  placeholder="Fee in USD, ex: 100"
                  type="number"
                  min="1"
                  max="2147483647" // int limit in our DB
                />
              )}

              <button
                className="knock-react-button btn-success btn-input-inline"
                id="addFeeButton"
                onClick={this.addCustomFee}
              >
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>
        </div>

        <ApplicationInformation
          onLeasingProviderChanged={this.onLeasingProviderChanged}
          inputChanged={this.inputChanged}
          leasingProvider={this.state.leasingProvider}
          applicationUrl={this.state.applicationUrl}
          isDefaultApplicationUrlOverridden={
            this.state.isDefaultApplicationUrlOverridden
          }
          applicationFee={this.state.applicationFee}
          qualificationCriteria={this.state.qualificationCriteria}
          applicationInstructions={this.state.applicationInstructions}
          errors={this.state.errors}
          clearErrors={this.clearErrors}
        />

        <div className="full-width">
          <h2 className="side-margin-lg">
            Pet Policy
            <InfoIconTooltip message={TOOLTIP_MESSAGES.PET_POLICY} />
          </h2>
          <div className="knock-react-flex">
            <div className="half-width inline-block">
              <div className="knock-react-flex">
                <div className="property-info-edit-field-label">
                  Refundable Deposit
                </div>
                <div className="property-info-edit-field-input">
                  <input
                    value={this.state.petsDeposit}
                    name="petsDeposit"
                    onBlur={this.inputChanged('petsDeposit', true).bind(this)}
                    onChange={this.inputChanged('petsDeposit').bind(this)}
                    className="knock-react-input"
                  />
                  <span className="input-append-text">USD</span>
                </div>
              </div>
              <div className="knock-react-flex">
                <div className="property-info-edit-field-label">
                  Non-refundable Fee
                </div>
                <div className="property-info-edit-field-input">
                  <input
                    value={this.state.petsFee}
                    name="petsFee"
                    onBlur={this.inputChanged('petsFee', true).bind(this)}
                    onChange={this.inputChanged('petsFee').bind(this)}
                    className="knock-react-input"
                  />
                  <span className="input-append-text">USD</span>
                </div>
              </div>
            </div>
            <div className="half-width inline-block">
              <div className="knock-react-flex">
                <div className="property-info-edit-field-label">Pet Rent</div>
                <div className="property-info-edit-field-input">
                  <input
                    value={this.state.petsRent}
                    name="petsRent"
                    onBlur={this.inputChanged('petsRent', true).bind(this)}
                    onChange={this.inputChanged('petsRent').bind(this)}
                    className="knock-react-input"
                  />
                  <span className="input-append-text">USD / month</span>
                </div>
              </div>
            </div>
          </div>
          <div className="knock-react-flex">
            <div className="property-info-edit-field-label">Allowed</div>
            <div className="property-info-edit-field-input">
              <span className="inline-block side-margin-lg">
                <input
                  type="checkbox"
                  checked={this.state.petsAllowed['cats']}
                  onChange={this.petCheckboxUpdated('cats')}
                />
                <span>Cats</span>
              </span>
              <span className="inline-block side-margin-lg">
                <input
                  type="checkbox"
                  checked={this.state.petsAllowed['small_dogs']}
                  onChange={this.petCheckboxUpdated('small_dogs')}
                />
                <span>Small Dogs</span>
              </span>
              <span className="inline-block side-margin-lg">
                <input
                  type="checkbox"
                  checked={this.state.petsAllowed['large_dogs']}
                  onChange={this.petCheckboxUpdated('large_dogs')}
                />
                <span>Large Dogs</span>
              </span>
            </div>
          </div>
          <div className="knock-react-flex">
            <div className="property-info-edit-field-label">Notes</div>
            <div className="property-info-edit-field-input">
              <textarea
                value={this.state.petsNotes}
                onBlur={this.inputChanged('petsNotes', true).bind(this)}
                onChange={this.inputChanged('petsNotes').bind(this)}
                placeholder="Enter any notes about breed restrictions, weight restrictions, or anything else a renter needs to know!"
              />
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">
            Covered Costs
            <InfoIconTooltip message={TOOLTIP_MESSAGES.COVERED_COSTS} />
          </h2>
          <div className="knock-react-flex half-width readable-width">
            <div className="property-info-edit-field-label">Covered Costs</div>
            <div className="property-info-edit-field-input">
              {this.state.coveredCosts.map((coveredCost, key) => {
                return (
                  <span
                    className="inline-block side-margin-lg"
                    key={`covered-cost-${key}`}
                  >
                    <input
                      type="checkbox"
                      checked={coveredCost.isCovered}
                      onChange={this.checkboxUpdated(
                        'coveredCosts',
                        key,
                        'isCovered'
                      )}
                    />
                    <span>
                      {coveredCost.name}
                      {!_.includes(COVERABLE_COST_TYPES, coveredCost.name) ? (
                        <button
                          className="knock-react-button btn-danger btn-checkbox-inline"
                          onClick={this.removeCustomCheckbox.bind(
                            this,
                            'coveredCosts',
                            key
                          )}
                        >
                          <strong>×</strong>
                        </button>
                      ) : null}
                    </span>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="knock-react-flex">
            <div className="property-info-edit-field-label" />
            <div className="property-info-edit-field-input">
              <input
                value={this.state.newCoveredCost}
                name="newCoveredCost"
                onChange={this.inputChanged('newCoveredCost').bind(this)}
                className="knock-react-input"
                placeholder="Another covered cost"
              />
              <button
                className="knock-react-button btn-success btn-input-inline"
                onClick={this.addCustomCheckbox.bind(
                  this,
                  'coveredCosts',
                  'name',
                  'newCoveredCost',
                  'isCovered'
                )}
              >
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">
            Affordable Housing
            <InfoIconTooltip message={TOOLTIP_MESSAGES.AFFORDABLE_HOUSING} />
          </h2>
          <div className="knock-react-flex half-width readable-width">
            <div className="property-info-edit-field-label">
              Available Programs
            </div>
            <div className="property-info-edit-field-input">
              {this.state.affordableHousingPrograms.map((program, key) => {
                return (
                  <span
                    className="inline-block side-margin-lg"
                    key={`program-${key}`}
                  >
                    <input
                      type="checkbox"
                      checked={program.available}
                      onChange={this.checkboxUpdated(
                        'affordableHousingPrograms',
                        key,
                        'available'
                      )}
                    />
                    <span>
                      {program.name}
                      {!_.includes(AFFORDABLE_HOUSING_TYPES, program.name) ? (
                        <button
                          className="knock-react-button btn-danger btn-checkbox-inline"
                          onClick={this.removeCustomCheckbox.bind(
                            this,
                            'affordableHousingPrograms',
                            key
                          )}
                        >
                          <strong>×</strong>
                        </button>
                      ) : null}
                    </span>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="knock-react-flex">
            <div className="property-info-edit-field-label" />
            <div className="property-info-edit-field-input">
              <input
                value={this.state.newAffordableHousingProgram}
                name="newAffordableHousingProgram"
                onChange={this.inputChanged('newAffordableHousingProgram').bind(
                  this
                )}
                className="knock-react-input"
                placeholder="Another affordable housing program"
              />
              <button
                className="knock-react-button btn-success btn-input-inline"
                onClick={this.addCustomCheckbox.bind(
                  this,
                  'affordableHousingPrograms',
                  'name',
                  'newAffordableHousingProgram',
                  'available'
                )}
              >
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>
          <div className="knock-react-flex">
            <div className="property-info-edit-field-label">Notes</div>
            <div className="property-info-edit-field-input">
              <textarea
                value={this.state.affordableHousingNotes}
                onBlur={this.inputChanged('affordableHousingNotes', true).bind(
                  this
                )}
                onChange={this.inputChanged('affordableHousingNotes').bind(
                  this
                )}
              />
            </div>
          </div>
        </div>
        {this.state.upchargeExplainerModalIsOpen ? (
          <UpchargeExplainerModal
            closeHandler={this.toggleUpchargeExplainerModal.bind(this)}
          />
        ) : null}
        {this.state.editUpchargeModalIsOpen ? (
          <EditUpchargeModal
            closeHandler={this.closeEditUpchargeModal.bind(this)}
            submitHandler={this.onSubmitEditUpcharge.bind(this)}
            selectedUpcharge={this.state.selectedUpcharge}
          />
        ) : null}
      </div>
    );
  }
}

PropertyInfoEditLeasing.propTypes = {
  property: PropTypes.object,
  onChange: PropTypes.func,
};

export default PropertyInfoEditLeasing;

PropertyInfoEditLeasing.contextType = FeatureFlagContext;
