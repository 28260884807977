import {
  Alert,
  Box,
  Snackbar,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import React from 'react';

const useStyles = makeStyles({
  bannerContainer: {
    marginBottom: '24px',
  },
  messageTitle: {
    marginBottom: '4px',
    fontSize: '16px',
    letterSpacing: '0.2px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  messageText: {
    paddingBottom: '6px',
  },
});

const ErrorToaster = ({ open, onClose }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose(false);
  };

  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      autoHideDuration={8000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Box className={classes.bannerContainer}>
        <Alert severity="error" className={classes.alert}>
          <Box className={classes.contentContainer}>
            <Text className={classes.messageTitle}>
              Configuration API Error
            </Text>
            <Text className={classes.messageText} variant="body2">
              There was an issue retrieving the configuration data for these
              properties.
            </Text>
          </Box>
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default ErrorToaster;
