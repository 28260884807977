import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from '@material-ui/core';
import {
  CardContent,
  InputText,
  LoadingOverlay,
  Text,
} from '@knockrentals/knock-shared-web';
import Tooltip from '@material-ui/core/Tooltip';

import { ErrorDialog } from '../../../common/ErrorDialog';
import ConfirmationDialog from '../../../common/ConfirmationDialog';
import { FeatureActions } from '../../../common/FeatureActions';
import FeatureCard from '../../../common/FeatureCard';
import { PropertiesDataTable } from '../PropertiesDataTable';

import useAiTemplate, { getSelectedPropertyIds } from './useAiTemplate';
import {
  AI_TEMPLATE_TYPE_TITLES,
  VALIDATION_ERROR_MESSAGES,
  AI_COMPANY_TEMPLATE,
  AI_CONFIRMATION_BUTTON_TEXT,
  AI_CONFIRMATION_MODES,
  getAiConfirmationTitle,
  getAiConfirmationMessage,
  AI_TEMPLATE_TYPES,
  AI_PERSONALIZED_RESPONSE_CUSTOM_TAG,
  AI_MERGE_TAGS,
} from '../AiConstants';
import { useStyles } from './stylesAiTemplate';

import './AiTemplate.scss';
import { AddIconButton } from '../../../common/AddIconButton';
import FeatureDrawer from '../../../common/FeatureDrawer';
import AddAiProperty from '../AddAiProperty/AddAiProperty';
import { AiEmailTemplateEditor } from './AiEmailTemplateEditor';
import { MentionsTextInput } from '../MentionsTextInput';

const AiTemplate = ({
  aiTemplate: { templateId, templateType, intentTemplates } = {},
  closeDrawer,
  fetchCompanyTemplates,
  mergeTags,
}) => {
  const classes = useStyles();

  const {
    addProperties,
    deleteAiTemplate,
    errorMessage,
    isEditing,
    isLoading,
    saveAiTemplate,
    setErrorMessage,
    setTablePropertiesMap,
    template,
    tablePropertiesMap,
    updateTemplate,
    propertiesTemplatesList,
    totalIntentAssignedProperties,
  } = useAiTemplate({
    closeDrawer,
    fetchCompanyTemplates,
    templateId,
    intentTemplates,
  });
  const [isEditorInited, setEditorInited] = useState(false);
  const [confirmationMode, setConfirmationMode] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});
  const [isAddPropertyOpen, setIsAddPropertyOpen] = useState(false);

  const { message = '', subject = '', templateName = '' } = template;
  const subheader = `${AI_TEMPLATE_TYPE_TITLES[templateType]} / ${
    isEditing ? templateName : 'Create New Template'
  }`;

  const clearErrorMessage = (field) => {
    setErrorMessages((prevState) => {
      const updatedMessages = { [field]: '' };

      return { ...prevState, ...updatedMessages };
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (errorMessages[name] && value) {
      clearErrorMessage(name);
    }

    updateTemplate({ [name]: value });
  };

  const handleOnChangeMessage = (value) => {
    if (errorMessages.message) {
      clearErrorMessage('message');
    }

    updateTemplate({ [AI_COMPANY_TEMPLATE.MESSAGE]: value });
  };

  const validateAiTemplate = () => {
    const messages = {};
    if (!templateName.trim()) {
      messages.templateName = VALIDATION_ERROR_MESSAGES.TEMPLATE_NAME;
    }

    if (message === '') {
      messages.message = VALIDATION_ERROR_MESSAGES.MESSAGE;
    }
    if (
      templateType === AI_TEMPLATE_TYPES.AI_POWERED_WEBFORM &&
      !message.includes(AI_PERSONALIZED_RESPONSE_CUSTOM_TAG.name)
    ) {
      messages.message = VALIDATION_ERROR_MESSAGES.AI_POWERED_WEBFORM_MESSAGE;
    }

    if (!subject.trim()) {
      messages.subject = VALIDATION_ERROR_MESSAGES.SUBJECT;
    }

    if(subject.length > 100 ) {
      messages.subject = VALIDATION_ERROR_MESSAGES.SUBJECT_LENGTH;
    }

    if (Object.keys(messages).length > 0) {
      return messages;
    }
  };

  const handleSaveOnClick = () => {
    const hasError = Object.values(errorMessages).some((message) => message);
    if (hasError) {
      return;
    }

    const messages = validateAiTemplate();
    if (messages) {
      setErrorMessages(messages);
      return;
    }

    if (getSelectedPropertyIds(tablePropertiesMap).length) {
      setConfirmationMode(
        isEditing ? AI_CONFIRMATION_MODES.EDIT : AI_CONFIRMATION_MODES.CREATE
      );
    } else {
      saveAiTemplate();
    }
  };

  const handleConfirmation = () => {
    if (confirmationMode === AI_CONFIRMATION_MODES.DELETE) {
      deleteAiTemplate();
    } else {
      saveAiTemplate();
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage('');
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationMode(null);
  };

  const handleClosePropertyDrawer = () => setIsAddPropertyOpen(false);

  return (
    <div className="ai-template-container">
      <LoadingOverlay open={isLoading || !isEditorInited} />

      <ErrorDialog
        closeDialog={handleCloseErrorDialog}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <FeatureDrawer
        isOpen={isAddPropertyOpen}
        onClose={handleClosePropertyDrawer}
      >
        <AddAiProperty
          addProperties={addProperties}
          closeDrawer={handleClosePropertyDrawer}
          intentTemplates={intentTemplates}
          templateId={templateId}
          templateName={templateName}
          templatePropertiesMap={tablePropertiesMap}
          templateType={templateType}
        />
      </FeatureDrawer>

      <ConfirmationDialog
        closeDialog={handleCloseConfirmationDialog}
        handleConfirmation={handleConfirmation}
        isOpen={Boolean(confirmationMode)}
        message={getAiConfirmationMessage(templateName, confirmationMode)}
        primaryButtonText={AI_CONFIRMATION_BUTTON_TEXT[confirmationMode]}
        title={getAiConfirmationTitle(templateName, confirmationMode)}
      />

      <FeatureCard
        closeDrawer={closeDrawer}
        subheader={subheader}
        title="AI EMAIL RESPONSE"
      >
        <CardContent className={classes.aiTemplateCardContent}>
          <InputText
            error={Boolean(errorMessages.templateName)}
            helperText={errorMessages.templateName}
            fullWidth
            inputProps={{ maxLength: 100 }}
            label="Template Name"
            name={AI_COMPANY_TEMPLATE.TEMPLATE_NAME}
            onChange={handleOnChange}
            value={templateName}
            requiredMessage="required"
            disabled={template.isDefault}
          />
          <MentionsTextInput
            mergeTags={mergeTags.filter((mergeTag) => AI_MERGE_TAGS.includes(mergeTag.name))}
            name={AI_COMPANY_TEMPLATE.SUBJECT}
            inputValue={subject}
            label={"Subject"}
            error={errorMessages.subject}
            helperText={errorMessages.subject}
            onChange={handleOnChange}
            updateTemplate={updateTemplate}
          />
          <div
            className={classnames(
              'message-container',
              errorMessages.message && 'error'
            )}
          >
            <React.Fragment>
              <div className="rte-label-wrapper">
                <Text
                  variant="subtitle2"
                  color={errorMessages.message ? 'error' : 'secondary'}
                  className={classes.textLabel}
                >
                  Message
                </Text>
                <Link
                  href="https://knockcrm.zendesk.com/hc/en-us/articles/4411086073755-Knock-Email-Editor"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text variant="subtitle2">
                    Use Merge Tags to create responses at Scale!
                  </Text>
                </Link>
              </div>

              <AiEmailTemplateEditor 
                handleOnChangeMessage={handleOnChangeMessage} 
                mergeTags={mergeTags} 
                message={message} 
                setEditorInited={setEditorInited} 
                templateType={templateType}
              />
            </React.Fragment>

            {errorMessages.message && (
              <Text
                color="error"
                className={classes.emailErrorText}
                variant="caption"
              >
                {errorMessages.message}
              </Text>
            )}
          </div>

          <div className="assigned-properties">
            {isEditing ? (
              <Text
                variant="subtitle2"
                color="secondary"
                className={classes.textLabel}
              >
                {`Properties included in ${template.templateName}`}
              </Text>
            ) : (
              <React.Fragment>
                <Text
                  variant="subtitle2"
                  color="secondary"
                  className={classes.textLabel}
                >
                  Select Properties for New Template
                </Text>
                <Text
                  variant="subtitle2"
                  color="secondary"
                  className={classes.textLabel}
                >
                  These properties will be removed from their current template.
                </Text>
              </React.Fragment>
            )}

            {!isLoading && (
              <PropertiesDataTable
                isEditing={isEditing}
                properties={propertiesTemplatesList}
                tablePropertiesMap={tablePropertiesMap}
                setTablePropertiesMap={setTablePropertiesMap}
              />
            )}
          </div>

          {isEditing &&
            totalIntentAssignedProperties ===
              propertiesTemplatesList.length && (
              <Tooltip
                title="All properties are already assigned to this template"
                placement="right"
              >
                <span>
                  <AddIconButton
                    handleOnClick={() => setIsAddPropertyOpen(true)}
                    primaryText="Select Additional Properties"
                    disabled={true}
                  />
                </span>
              </Tooltip>
            )}
          {isEditing &&
            totalIntentAssignedProperties !==
              propertiesTemplatesList.length && (
              <AddIconButton
                handleOnClick={() => setIsAddPropertyOpen(true)}
                primaryText="Select Additional Properties"
              />
            )}
        </CardContent>

        <FeatureActions
          handleCancelOnClick={closeDrawer}
          handleSaveOnClick={handleSaveOnClick}
          handleDeleteOnClick={() =>
            setConfirmationMode(AI_CONFIRMATION_MODES.DELETE)
          }
          isDeleteButtonVisible={isEditing && !template.isDefault}
        />
      </FeatureCard>
    </div>
  );
};

AiTemplate.propTypes = {
  aiTemplate: PropTypes.shape({
    propertyCount: PropTypes.number,
    templateId: PropTypes.number,
    templateType: PropTypes.string,
    intentTemplates: PropTypes.shape({
      intentId: PropTypes.number,
      templateCount: PropTypes.number,
      templates: PropTypes.arrayOf(
        PropTypes.shape({
          assignedPropertiesCount: PropTypes.number,
          properties: PropTypes.arrayOf(
            PropTypes.shape({
              property_id: PropTypes.number,
              property_name: PropTypes.string,
            })
          ),
          templateId: PropTypes.number,
          templateName: PropTypes.string,
        })
      ),
    }),
  }),
  closeDrawer: PropTypes.func,
  fetchCompanyTemplates: PropTypes.func,
  mergeTags: PropTypes.array,
};

export default AiTemplate;
