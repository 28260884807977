import React from 'react';
import PropTypes from 'prop-types';
import { CustomFeeDisplay } from './CustomFee';

const CustomFeeList = ({
  isCustomFeesEnabled,
  propertyCustomFees,
  removeCustomFee,
}) => (
  <div className="property-info-edit-field-input">
    {propertyCustomFees.map((fee, index) => {
      const { amount, name } = fee;
      const formattedAmount = isCustomFeesEnabled ? (
        <CustomFeeDisplay amount={amount} />
      ) : (
        `$${amount}`
      );

      return (
        <span className="inline-block side-margin-lg" key={index}>
          <span>
            {`${name}: `} {formattedAmount}
            <button
              className="knock-react-button btn-danger btn-checkbox-inline"
              id="removeFeeButton"
              data-testid={`removeFeeButton-${index}`}
              onClick={() => removeCustomFee(index)}
            >
              <i className="fa fa-times" />
            </button>
          </span>
        </span>
      );
    })}
  </div>
);

CustomFeeList.propTypes = {
  isCustomFeesEnabled: PropTypes.bool,
  propertyCustomFees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
  removeCustomFee: PropTypes.func,
};

export default CustomFeeList;
