import React from 'react';
import PropTypes from 'prop-types';
import PropertyInfoEditBasics from './PropertyInfoEditBasics';
import PropertyInfoEditAmenities from './PropertyInfoEditAmenities';
import PropertyInfoEditLeasing from './Leasing/PropertyInfoEditLeasing';
import PropertyInfoEditNearbyCommunities from './PropertyInfoEditNearbyCommunities';
import PropertyInfoEditSettings from './PropertyInfoEditSettings';

const TAB_OPTIONS = ['Basics', 'Amenities', 'Leasing', 'Settings', 'Cross Sell'];

const PropertyInfo = ({
  selectedTab,
  property,
  settings,
  teams,
  changeSelectedTab,
  onInformationChanged
}) => {

  const getActiveStatusSubClassOfTab = (tabOption) => {
    return selectedTab === tabOption
      ? 'active'
      : 'inactive';
  };

  return (
    <div>
      <h2>{property.data.location.name}</h2>

      <div className="property-info-nav-tabs-menu" role="tablist">
        {TAB_OPTIONS.map((tabOption) => (
          <div
            key={tabOption}
            className={
              `property-info-nav-tab ${getActiveStatusSubClassOfTab(tabOption)}`
            }
            onClick={() => { changeSelectedTab(tabOption); }}
            role="tab"
          >
            {tabOption}
          </div>)
        )}
      </div>

      {selectedTab === 'Basics' && (
        <PropertyInfoEditBasics
          teams={teams}
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {selectedTab === 'Amenities' && (
        <PropertyInfoEditAmenities
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {selectedTab === 'Leasing' && (
        <PropertyInfoEditLeasing
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {selectedTab === 'Settings' && (
        <PropertyInfoEditSettings
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {selectedTab === 'Cross Sell' && (
        <PropertyInfoEditNearbyCommunities
          property={property}
          settings={settings}
          onChange={onInformationChanged}
        />
      )}
    </div>
  );
};

PropertyInfo.propTypes = {
  selectedTab: PropTypes.string,
  property: PropTypes.object,
  settings: PropTypes.array,
  teams: PropTypes.array,
  changeSelectedTab: PropTypes.func,
  onInformationChanged: PropTypes.func,
};

export default PropertyInfo;
