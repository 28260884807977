import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { appDataInitialState, useApp } from './hooks';

export const defaultAppContext = {
  companyId: appDataInitialState.companyId,
  companyName: appDataInitialState.companyName,
  isInternalMode: appDataInitialState.isInternalMode,
  isLoggedIn: appDataInitialState.isLoggedIn,
  isReady: appDataInitialState.isReady,
  setIsReady: () => {},
};

export const AppContext = createContext(defaultAppContext);

export const AppContextProvider = ({ children }) => {
  const {
    companyId,
    companyName,
    isInternalMode,
    isLoggedIn,
    isReady,
    setIsReady,
  } = useApp();

  const values = {
    companyId,
    companyName,
    isInternalMode,
    isLoggedIn,
    isReady,
    setIsReady,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useAppContext = () => useContext(AppContext);
