import React, { Component } from 'react';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { Collapse } from '@knockrentals/knock-react';
import { EmailTemplateEditor } from '../../EmailTemplateEditor';
import { MERGE_TAG_TYPES } from '../../Services/message-service';
import { Tooltip } from '@knockrentals/knock-shared-web';
import { validateUrl } from '../../Utils';

import './_propertyTemplates.scss';

const WINBACK_SUPPORT_ARTICLE =
  'https://kb.realpage.com/articles/Solution/WinBack-Campaign';

const TEMPLATE_CHAR_LIMITS = {
  short: 140,
  medium: 1500,
  long: 10000,
};

const EMAIL_TEMPLATE = 'email_template';
const SELF_GUIDED_EMAIL_TEMPLATE = 'self_guided_email_template';
const SELF_GUIDED_TOUR_EMAIL_INSTRUCTIONS =
  'self_guided_tour_email_instructions';
const WINBACK_TEMPLATE = 'winback_email_template';

export const AUTO_RESPONSE_TYPES = {
  EMAIL: 'email',
  SMS: 'sms',
  SELF_GUIDED_EMAIL: 'self_guided_email',
  SELF_GUIDED_SMS: 'self_guided_sms',
  WINBACK_EMAIL: 'winback',
};

const getDefaultSubject = (propertyName) => `Inquiry at ${propertyName}`;

const TemplateCheckbox = ({ isChecked, onChange, name }) => {
  return (
    <div className="property-template-checkbox">
      <input
        checked={isChecked}
        onChange={onChange}
        type="checkbox"
        name={name}
      />
      <label>Enabled</label>
    </div>
  );
};

const getSuppressedAutorespondersInitialState = (suppressedAutorespondsers) => {
  const defaultSuppressedResponders = {
    [AUTO_RESPONSE_TYPES.EMAIL]: true,
    [AUTO_RESPONSE_TYPES.SMS]: true,
    [AUTO_RESPONSE_TYPES.SELF_GUIDED_EMAIL]: true,
    [AUTO_RESPONSE_TYPES.SELF_GUIDED_SMS]: true,
    [AUTO_RESPONSE_TYPES.WINBACK_EMAIL]: true,
  };

  // If the autoresponder is in the array, it is suppressed aka disabled
  suppressedAutorespondsers.forEach((suppressedResponder) => {
    defaultSuppressedResponders[suppressedResponder] = false;
  });

  return defaultSuppressedResponders;
};

class PropertyTemplates extends Component {
  state = {
    errors: {},
    email_subject:
      this.props.property.email_subject ||
      getDefaultSubject(this.props.property.property_name),
    email_template: this.props.property.email_template,
    sms_template: this.props.property.sms_template,
    // The properties below do not have a default value in elastic search, so we default them to an empty string here.
    self_guided_email_template:
      this.props.property.self_guided_email_template || '',
    self_guided_sms_template:
      this.props.property.self_guided_sms_template || '',
    self_guided_tour_email_instructions:
      this.props.property.self_guided_tour_email_instructions || '',
    self_guided_tour_sms_instructions:
      this.props.property.self_guided_tour_sms_instructions || '',
    live_video_tour_instructions:
      this.props.property.live_video_tour_instructions || '',
    live_video_tour_url: this.props.property.live_video_tour_url || '',
    tours_mobile_quote_template:
      this.props.property.tours_mobile_quote_template || '',
    winback_email_template: this.props.property.winback_email_template || '',
    mytour_agenda: this.props.property.mytour_agenda || '',
    self_guided_detailed_instructions:
      this.props.property.self_guided_detailed_instructions || '',
    leasing_team_settings: this.props.property.leasing_team_settings || {},
    suppressedAutoresponders: getSuppressedAutorespondersInitialState(
      this.props.property.suppressed_autoresponders || []
    ),
  };

  render() {
    const hasErrors = this.hasErrors(this.state.errors);

    const {
      isCompanyTemplatesEnabled,
      isCompanyTemplatesSelfGuidedToursEnabled,
      isCompanyTemplatesWinbackEnabled,
    } = this.props;
    const isWinbackHidden =
      isCompanyTemplatesEnabled && isCompanyTemplatesWinbackEnabled;

    const isSelfGuidedTourPostVisitTemplatesHidden =
      isCompanyTemplatesEnabled && isCompanyTemplatesSelfGuidedToursEnabled;

    let saveButtonStyles = {};
    const disabledSaveButtonStyles = {
      cursor: 'not-allowed',
      backgroundColor: '#EFEFEF',
      color: '#a7a7a7',
      border: '1px solid #E6E6E6',
    };

    if (hasErrors) {
      saveButtonStyles = { ...disabledSaveButtonStyles };
    }

    const {
      [AUTO_RESPONSE_TYPES.EMAIL]: isEmailAutoResponseEnabled,
      [AUTO_RESPONSE_TYPES.SELF_GUIDED_EMAIL]:
        isSelfGuidedEmailAutoResponseEnabled,
      [AUTO_RESPONSE_TYPES.SELF_GUIDED_SMS]: isSelfGuidedSMSAutoResponseEnabled,
      [AUTO_RESPONSE_TYPES.SMS]: isSMSAutoResponseEnabled,
      [AUTO_RESPONSE_TYPES.WINBACK_EMAIL]: isEmailWinbackResponseEnabled,
    } = this.state.suppressedAutoresponders;

    return (
      <Collapse title={this.props.property.property_name}>
        <div className="property-templates-container">
          <div className="horizonatal-rule"></div>
          {!isCompanyTemplatesEnabled && (
            <React.Fragment>
              <div>
                <div>
                  <strong>Email Subject: </strong>
                </div>
                <div>
                  <input
                    value={this.state.email_subject}
                    onChange={this.templateChanged('email_subject')}
                    className="knock-react-input email-subject"
                    maxLength={TEMPLATE_CHAR_LIMITS.short}
                  />
                  <button
                    className="knock-react-button"
                    onClick={this.resetEmailSubject}
                  >
                    Default
                  </button>
                </div>
                <div className="property-templates-chars-left">
                  {this.charactersLeftMessage(
                    TEMPLATE_CHAR_LIMITS.short,
                    this.state.email_subject.length
                  )}
                </div>
              </div>

              <div>
                <div>
                  <strong>Email Auto Response:</strong>
                </div>
                <TemplateCheckbox
                  isChecked={isEmailAutoResponseEnabled}
                  onChange={this.handleSuppressedResponseOnChange}
                  name={AUTO_RESPONSE_TYPES.EMAIL}
                />
                <div
                  className={
                    !isEmailAutoResponseEnabled ? 'disable-suppressed' : ''
                  }
                >
                  <EmailTemplateEditor
                    content={this.state.email_template}
                    mergeTags={this.getMergeTagsByType(MERGE_TAG_TYPES.DEFAULT)}
                    name="auto-response-email-template"
                    onChange={this.handleEmailTemplateEditorChange(
                      EMAIL_TEMPLATE
                    )}
                  />
                </div>
              </div>

              <div>
                <div>
                  <strong>SMS Auto Response:</strong>
                </div>
                <TemplateCheckbox
                  isChecked={isSMSAutoResponseEnabled}
                  onChange={this.handleSuppressedResponseOnChange}
                  name={AUTO_RESPONSE_TYPES.SMS}
                />
                <div
                  className={
                    !isSMSAutoResponseEnabled ? 'disable-suppressed' : ''
                  }
                >
                  <textarea
                    value={this.state.sms_template}
                    onChange={this.templateChanged('sms_template')}
                    maxLength={TEMPLATE_CHAR_LIMITS.short}
                  />
                  <div className="property-templates-chars-left">
                    {this.charactersLeftMessage(
                      TEMPLATE_CHAR_LIMITS.short,
                      this.state.sms_template.length
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          <div>
            <div>
              <strong>Self-Guided Tour Post-Visit Email:</strong>
            </div>
            <TemplateCheckbox
              isChecked={isSelfGuidedEmailAutoResponseEnabled}
              onChange={this.handleSuppressedResponseOnChange}
              name={AUTO_RESPONSE_TYPES.SELF_GUIDED_EMAIL}
            />
            <div
              className={
                !isSelfGuidedEmailAutoResponseEnabled
                  ? 'disable-suppressed'
                  : ''
              }
            >
              <EmailTemplateEditor
                content={this.state.self_guided_email_template}
                mergeTags={this.getMergeTagsByType(MERGE_TAG_TYPES.DEFAULT)}
                name="self-guided-email-template"
                onChange={this.handleEmailTemplateEditorChange(
                  SELF_GUIDED_EMAIL_TEMPLATE
                )}
              />
            </div>
          </div>
          {!isSelfGuidedTourPostVisitTemplatesHidden && (
            <div>
              <div>
                <strong>Self-guided Tour Post-Visit SMS Message:</strong>
              </div>
              <TemplateCheckbox
                isChecked={isSelfGuidedSMSAutoResponseEnabled}
                onChange={this.handleSuppressedResponseOnChange}
                name={AUTO_RESPONSE_TYPES.SELF_GUIDED_SMS}
              ></TemplateCheckbox>
              <div
                className={
                  !isSelfGuidedSMSAutoResponseEnabled
                    ? 'disable-suppressed'
                    : ''
                }
              >
                <textarea
                  value={this.state.self_guided_sms_template}
                  onChange={this.templateChanged('self_guided_sms_template')}
                  maxLength={TEMPLATE_CHAR_LIMITS.short}
                />
                <div className="property-templates-chars-left">
                  {this.charactersLeftMessage(
                    TEMPLATE_CHAR_LIMITS.short,
                    this.state.self_guided_sms_template.length
                  )}
                </div>
              </div>
            </div>
          )}
          <React.Fragment>
            <div>
              <div>
                <strong>Knock Self-Guided Tour Instructions (Email):</strong>
              </div>
              <EmailTemplateEditor
                content={this.state.self_guided_tour_email_instructions}
                mergeTags={this.getMergeTagsByType(MERGE_TAG_TYPES.DEFAULT)}
                name="self-guided-tour-email-instructions"
                onChange={this.handleEmailTemplateEditorChange(
                  SELF_GUIDED_TOUR_EMAIL_INSTRUCTIONS
                )}
              />
            </div>

            <div>
              <div>
                <strong>Knock Self-Guided Tour Instructions (SMS):</strong>
              </div>
              <textarea
                value={this.state.self_guided_tour_sms_instructions}
                onChange={this.templateChanged(
                  'self_guided_tour_sms_instructions'
                )}
                maxLength={TEMPLATE_CHAR_LIMITS.short}
              />
              <div className="property-templates-chars-left">
                {this.charactersLeftMessage(
                  TEMPLATE_CHAR_LIMITS.short,
                  this.state.self_guided_tour_sms_instructions.length
                )}
              </div>
            </div>

            <div>
              <div>
                <strong>Knock Self-Guided Detailed Instructions:</strong>
              </div>
              <textarea
                value={this.state.self_guided_detailed_instructions}
                onChange={this.templateChanged(
                  'self_guided_detailed_instructions'
                )}
                maxLength={TEMPLATE_CHAR_LIMITS.medium}
              />
              <div className="property-templates-chars-left">
                {this.charactersLeftMessage(
                  TEMPLATE_CHAR_LIMITS.medium,
                  this.state.self_guided_detailed_instructions.length
                )}
              </div>
            </div>

            <div>
              <div>
                <strong>Knock My Tour Agenda:</strong>
              </div>
              <textarea
                value={this.state.mytour_agenda}
                onChange={this.templateChanged('mytour_agenda')}
                maxLength={TEMPLATE_CHAR_LIMITS.medium}
              />
              <div className="property-templates-chars-left">
                {this.charactersLeftMessage(
                  TEMPLATE_CHAR_LIMITS.medium,
                  this.state.mytour_agenda.length
                )}
              </div>
            </div>
          </React.Fragment>
          <div>
            <div>
              <strong>Live Video Tour Instructions:</strong>
            </div>
            <textarea
              value={this.state.live_video_tour_instructions}
              onChange={this.templateChanged('live_video_tour_instructions')}
              maxLength={TEMPLATE_CHAR_LIMITS.short}
            />
            <div className="property-templates-chars-left">
              {this.charactersLeftMessage(
                TEMPLATE_CHAR_LIMITS.short,
                this.state.live_video_tour_instructions.length
              )}
            </div>
          </div>

          {!isWinbackHidden && (
            <div>
              <div>
                <strong> Winback Response : </strong>
                <Tooltip title="This email will be sent 75 days prior to the lease term length preference expiration if set or 9.5 months after lead creation.">
                  <i className="fa-regular fa-question-circle tooltip"></i>
                </Tooltip>
                <Link
                  href={WINBACK_SUPPORT_ARTICLE}
                  target="_blank"
                  rel="noreferrer"
                  className="link-padding"
                >
                  View Support Article
                </Link>
              </div>

              <TemplateCheckbox
                isChecked={isEmailWinbackResponseEnabled}
                onChange={this.handleSuppressedResponseOnChange}
                name={AUTO_RESPONSE_TYPES.WINBACK_EMAIL}
              />

              <div
                className={
                  !isEmailWinbackResponseEnabled ? 'disable-suppressed' : ''
                }
              >
                <EmailTemplateEditor
                  content={this.state.winback_email_template}
                  mergeTags={this.getMergeTagsByType(MERGE_TAG_TYPES.DEFAULT)}
                  name="auto-response-winback-template"
                  onChange={this.handleEmailTemplateEditorChange(
                    WINBACK_TEMPLATE
                  )}
                />
              </div>
            </div>
          )}

          <div className="knock-react-flex">
            <div
              style={{
                verticalAlign: 'middle',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: '10px',
              }}
            >
              <strong>Live Video Tour URL: </strong>
            </div>
            <div>
              <input
                value={this.state.live_video_tour_url}
                onChange={this.templateChanged('live_video_tour_url')}
                className="knock-react-input"
                style={{
                  minWidth: '355px',
                }}
              />
            </div>
            {this.state.errors.live_video_tour_url && (
              <span className="knock-react-form-hint knock-react-form-hint-error">
                {this.state.errors.live_video_tour_url}
              </span>
            )}
          </div>

          {this.state.leasing_team_settings.enable_mobile_tours && (
            <div>
              <div>
                <strong>Tours Mobile Quote:</strong>
              </div>
              <textarea
                value={this.state.tours_mobile_quote_template}
                onChange={this.templateChanged('tours_mobile_quote_template')}
                maxLength={TEMPLATE_CHAR_LIMITS.medium}
              />
              <div className="property-templates-chars-left">
                {this.charactersLeftMessage(
                  TEMPLATE_CHAR_LIMITS.medium,
                  this.state.tours_mobile_quote_template.length
                )}
              </div>
            </div>
          )}

          <button
            className="knock-react-button"
            onClick={this.submit}
            disabled={hasErrors}
            style={saveButtonStyles}
          >
            Save
          </button>
          {hasErrors && (
            <span className="knock-react-form-hint knock-react-form-hint-error">
              Resolve form errors to save
            </span>
          )}
        </div>
      </Collapse>
    );
  }

  areAllAutoresponsesSuppressed = () => {
    return Object.values(this.state.suppressedAutoresponders).every(
      (isSuppressed) => isSuppressed
    );
  };

  handleAllAutoresponsesSuppressedOnChange = (e) => {
    const { checked } = e.target;
    this.setState({
      suppressedAutoresponders: {
        [AUTO_RESPONSE_TYPES.EMAIL]: checked,
        [AUTO_RESPONSE_TYPES.SMS]: checked,
        [AUTO_RESPONSE_TYPES.SELF_GUIDED_EMAIL]: checked,
        [AUTO_RESPONSE_TYPES.SELF_GUIDED_SMS]: checked,
        [AUTO_RESPONSE_TYPES.WINBACK_EMAIL]: checked,
      },
    });
  };

  handleSuppressedResponseOnChange = (e) => {
    const { checked, name } = e.target;

    this.setState((prevState) => ({
      suppressedAutoresponders: {
        ...prevState.suppressedAutoresponders,
        [name]: checked,
      },
    }));
  };

  resetEmailSubject = () => {
    this.setState({
      email_subject: getDefaultSubject(this.props.property.property_name),
    });
  };

  charactersLeftMessage = (numCharsMax, currLength) => {
    const numCharactersLeft = numCharsMax - currLength;
    return `${numCharactersLeft} ${
      numCharactersLeft === 1 ? 'character' : 'characters'
    } left`;
  };

  getMergeTagsByType = (mergeTagType) => {
    const { mergeTags = [] } = this.props;
    return mergeTags[mergeTagType] || [];
  };

  handleTemplateChange = (newValue) => {
    const values = this.getFormValues();

    this.setState(newValue);
    this.validate({
      ...values,
      ...newValue,
    });
  };

  templateChanged = (key) => (event) => {
    const newValue = { [key]: event.target.value };
    this.handleTemplateChange(newValue);
  };

  handleEmailTemplateEditorChange = (key) => (editorData) => {
    this.handleTemplateChange({ [key]: editorData });
  };

  submit = () => {
    const values = this.getFormValues();
    const isValid = this.validate(values);

    if (isValid) {
      this.props.onChange(values);
    }
  };

  getFormValues = () => {
    return {
      email_subject: this.state.email_subject,
      email_template: this.state.email_template,
      sms_template: this.state.sms_template,
      self_guided_email_template: this.state.self_guided_email_template,
      self_guided_sms_template: this.state.self_guided_sms_template,
      self_guided_tour_email_instructions:
        this.state.self_guided_tour_email_instructions,
      self_guided_tour_sms_instructions:
        this.state.self_guided_tour_sms_instructions,
      live_video_tour_instructions: this.state.live_video_tour_instructions,
      live_video_tour_url: this.state.live_video_tour_url,
      tours_mobile_quote_template: this.state.tours_mobile_quote_template,
      winback_email_template: this.state.winback_email_template,
      mytour_agenda: this.state.mytour_agenda,
      self_guided_detailed_instructions:
        this.state.self_guided_detailed_instructions,
      suppressed_autoresponders: Object.keys(
        this.state.suppressedAutoresponders
      ).filter((responder) => !this.state.suppressedAutoresponders[responder]),
    };
  };

  validate = (values) => {
    const errors = {};

    if (
      values.live_video_tour_url &&
      !validateUrl(values.live_video_tour_url)
    ) {
      errors.live_video_tour_url = 'Specified website is invalid';
    }

    this.setState({ errors });

    return !this.hasErrors(errors);
  };

  hasErrors = (errors) => {
    const keys = Object.keys(errors);
    return !!(keys.length && keys.length > 0);
  };
}

PropertyTemplates.propTypes = {
  email_subject: PropTypes.string,
  isCompanyTemplatesEnabled: PropTypes.bool,
  isCompanyTemplatesSelfGuidedToursEnabled: PropTypes.bool,
  isCompanyTemplatesWinbackEnabled: PropTypes.bool,
  property: PropTypes.shape({
    email_template: PropTypes.string,
    leasing_team_settings: PropTypes.shape({
      enable_mobile_tours: PropTypes.bool,
    }),
    live_video_tour_instructions: PropTypes.string,
    live_video_tour_url: PropTypes.string,
    mytour_agenda: PropTypes.string,
    propertyName: PropTypes.string,
    self_guided_detailed_instructions: PropTypes.string,
    self_guided_email_template: PropTypes.string,
    self_guided_sms_template: PropTypes.string,
    self_guided_tour_email_instructions: PropTypes.string,
    self_guided_tour_sms_instructions: PropTypes.string,
    sms_template: PropTypes.string,
    suppressed_autoresponders: PropTypes.arrayOf(PropTypes.string),
    tours_mobile_quote_template: PropTypes.string,
    winback_email_template: PropTypes.string,
  }),
};

export default PropertyTemplates;
