import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';

import { IndeterminateSwitch } from '../../../../common/IndeterminateSwitch';
import BrandContentTooltip from '../BrandContentTooltip/BrandContentTooltip';
import BrandFooterPreview from './BrandFooterPreview';
import FooterTemplateEditor from './FooterTemplateEditor';
import { getTooltipMessage, BOX_SHADOW } from '../utils';
import { DEFAULT_TEXT_COLORS } from '../../../constants';

export const BRAND_FOOTER_TITLE = 'Footer';

export const FOOTER_DESCRIPTIONS = {
  DEFAULT:
    'Footer will display a banner of your brand color with an overlay of any copy you add in the text field below.',
  NO_BRAND_COLOR:
    'Because there is no Brand Color available, the Footer will display a banner of white with an overlay of any copy you add in the text field below.',
};

export const IS_FOOTER_ENABLED_LABEL = 'Display Footer';

const useStyles = makeStyles({
  descriptionText: {
    margin: '24px 0',
  },
  footerCard: {
    marginTop: '16px',
  },
  footerCardContent: {
    '& .email-footer-preview-body': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  },
});

const getDescriptionText = (brandColor, isBatchUpdate) => {
  if (brandColor || isBatchUpdate) {
    return FOOTER_DESCRIPTIONS.DEFAULT;
  }
  return FOOTER_DESCRIPTIONS.NO_BRAND_COLOR;
};

const BrandFooter = ({
  brandColor,
  contrastingTextColor,
  footerContent,
  isBatchUpdate,
  isBrandColorMixed,
  isFooterContentMixed,
  isFooterEnabled,
  isFooterEnabledIndeterminate = false,
  mergeTags,
  propertyCount,
  updateBrandContent,
}) => {
  const classes = useStyles();

  const getTooltipTitle = () => {
    if (isBrandColorMixed || isFooterContentMixed) {
      return getTooltipMessage(propertyCount);
    }
  };

  const isTemplateEditorVisible =
    isFooterEnabled || isFooterEnabledIndeterminate;

  const handleFooterTemplateOnChange = (footerContent) => {
    updateBrandContent({ footerContent });
  };

  const handleIsFooterEnabledOnChange = (updateMap) => {
    if (isFooterEnabledIndeterminate) {
      updateMap.isFooterEnabledIndeterminate = false;
    }
    updateBrandContent(updateMap);
  };

  return (
    <Card className={classes.footerCard} variant="outlined">
      <CardHeader title={BRAND_FOOTER_TITLE} />

      <CardContent className={classes.footerCardContent}>
        <FormControlLabel
          control={
            <BrandContentTooltip title={getTooltipTitle()}>
              <IndeterminateSwitch
                ariaLabel={IS_FOOTER_ENABLED_LABEL}
                isChecked={isFooterEnabled || false}
                name="isFooterEnabled"
                isIndeterminate={isFooterEnabledIndeterminate}
                onChangeHandler={handleIsFooterEnabledOnChange}
              />
            </BrandContentTooltip>
          }
          label={IS_FOOTER_ENABLED_LABEL}
        />

        <Text className={classes.descriptionText} variant="body2">
          {getDescriptionText(brandColor, isBatchUpdate)}
        </Text>

        {isTemplateEditorVisible && (
          <React.Fragment>
            <FooterTemplateEditor
              footerContent={footerContent}
              isFooterContentMixed={isFooterContentMixed}
              mentionsList={mergeTags}
              onChange={handleFooterTemplateOnChange}
            />

            <Box bgcolor={brandColor} boxShadow={BOX_SHADOW}>
              <BrandFooterPreview
                brandColor={brandColor || DEFAULT_TEXT_COLORS.WHITE}
                footerContent={footerContent}
                isBrandColorMixed={isBrandColorMixed}
                textColor={contrastingTextColor}
                width="100%"
              />
            </Box>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
};

BrandFooter.propTypes = {
  brandColor: PropTypes.string,
  contrastingTextColor: PropTypes.string,
  footerContent: PropTypes.string,
  isBatchUpdate: PropTypes.bool,
  isBrandColorMixed: PropTypes.bool,
  isFooterContentMixed: PropTypes.bool,
  isFooterEnabled: PropTypes.bool,
  isFooterEnabledIndeterminate: PropTypes.bool,
  mergeTags: PropTypes.array,
  propertyCount: PropTypes.number,
  updateBrandContent: PropTypes.func,
};

export default BrandFooter;
