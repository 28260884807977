import React from 'react';
import { Link } from 'react-router-dom';
import { VerticalNavbarHeader } from '@knockrentals/knock-react';

const srcImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABCdJREFUeNrs3U9IFFEcB/D3m/3vkhgdQgjRW3jpzynoJELQraAIupSCh25Jp06xJyGQhI5ReaggEirokiB1ETypESydsjKKDpFZ5rqrTr/fuLu45m6768zs7733+8JjllVnZ+bj772ZeeuqlEQikUgkzQV03OjvXdc7cHEV2yVs3cWnl7A9w5Y58Gnkg4CEh3EUF0+3QeyWAUQZF5BwMF5h66jj27VEAUMxtEUBgzG0RAHDMbRDAQswtEIBSzC0QQGLMLRAAcsw2KOAhRisUcBSDLYoYDEGSxSwHIMdCggGLxQQDF4oIBi8UBztMfYlFRw+6Ocm3sdtvGwFSBAY0fsXvWYKCuiOUYb4lVPrA4+U++6b1t0XGIFRigEoYAyGIShgFIYBKGAchuYoYCSGxihgLIamKGA0hoYoYDyGZihgBYZGKGANhiYoYBWGBihgHUYxzkoBUR6qjexXVihgJYbjqLa2NqyUNbV84Q4rFLAVA2Br193lHCsUsBmjFE4oji0YhJBKpf7B8L7WnlTtj4dUpLfTz5dsapILbMGgyqAKqRUOlQKCwQsFBIMXCggGLxTHRAwKDeDNYrRyoHdMxEgmkyoSiey9ylqAAiZixGIxX9cZZvcFgsELBYoY3biYE4zWo3hjyKZyM4LBY0yBYnUs6IpBEAQSZgKqlB76FCMHq+OYYLColBtel+UoOCIYLFDOeCBYIW8EgwVKR6lC5gSDT6U4xY/De60DBl19c8LwGWW+fNqbdzcG1dZnFrLFoPtSdH+Ka3xAeV4G6Vy8ubDi5vvrQmkRxm5TrwahUC81VgahdC2OzuKiz1Xqo2CEikJFcBaHjqUKEAo+OY+7Tfe0MghTPvvaUO5brKCR2OQVwfAXhRD66LiXf7aO9SewxenBMkYwGruiz92bVrm7097jHRnHNlyqDNYge53t4xi6zfLz9O2+Yk9U9ayWHYiJGNv27b/HO8psg2tirM8sqPXsl4rydw7tV7ETPd7ShES5Y2x+/qFWb02p/GR2t364AiZx/rhKDp70BlVtq4hDl1UNY3VsysNo9AwnNdzvwejYZbEASafTFRh+zDfET/Wq9Og5VtVSD0jLR066N+U3hnc7CLs4Wk+tbo5jnFZj7Jx6/T30wLeZOFrPyrUJAWkWg8aMwsx7X1+HKmVtYlZAGsXwrmrxijaI/Mm8EJBGMEq/yUH197ReXaokVJB4PF717Tr5J8EesMLLrIBsD0EkEonqB8znsSPs9WsFwmEeXJfTX8cGDDntFQx+INFoVDC4gNCtEMFgAmLC1KsxIILBCEQwGIEIBiOQWp8hIgkZxOR3iGgHIhiMQASDEYhgMAIRDGYgNJ8hGHxAcmH8cb6A1ImBrSCHK/jU897ePDa3OIYE+Q9g3KB3FuTqVSKRSCQSiUQi4ZC/AgwAEvGo+Oyuh8YAAAAASUVORK5CYII=';

const NavHeader = () => {
  return (
    <Link to="/sources">
      <VerticalNavbarHeader>
        <img
          style={{ position: 'relative', top: '-1px' }}
          alt="Admin"
          className="fa-fw fa"
          src={srcImage}
        />
        Admin
      </VerticalNavbarHeader>
    </Link>
  );
};

export default NavHeader;
