import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import { useAppContext } from '../../Context/AppContext';

import AccessPage from '../../Pages/Access/AccessPage';
import AdminsPage from '../../Pages/Users/AdminsPage';
import AiTemplatesPage from '../../Pages/Features/CompanyTemplatesPage/AiTemplates/AiTemplatesPage';
import Attributes from '../../Pages/Attributes/Attributes';
import { BrandContentPage } from '../../Pages/Features/BrandContentPage';
import { CallIntelPage } from '../../Pages/Features/CallIntelPage';
import { CompanyQuickRepliesPage } from '../../Pages/Features/CompanyQuickRepliesPage';
import { CompanyTemplatesPage } from '../../Pages/Features/CompanyTemplatesPage';
import DoorwayPage from '../../Pages/Doorway/DoorwayPage';
import { DripCampaignPage } from '../../Pages/Features/DripCampaignPage';
import IntegrationsPage from '../../Pages/Integrations/IntegrationsPage';
import LoginPage from '../../Pages/Login/LoginPage';
import LogoutPage from '../../Pages/Logout/LogoutPage';
import PhotosPage from '../../Pages/Photos/PhotosPage';
import PricingPage from '../../Pages/Pricing/PricingPage';
import PropertiesPage from '../../Pages/Properties/PropertiesPage';
import ProspectsPage from '../../Pages/Prospects/ProspectsPage';
import RegistrationPage from '../../Pages/Registration/RegistrationPage';
import { SchedulingPage } from '../../Pages/Features/SchedulingPage';
import SettingsPage from '../../Pages/Settings/SettingsPage';
import SheetsPage from '../../Pages/Sheets/SheetsPage';
import SourceListPage from '../../Pages/Sources/SourceListPage';
import SourcesPage from '../../Pages/Sources/SourcesPage';
import SpreadsheetRefreshPage from '../../Pages/SpreadsheetRefresh/SpreadsheetRefreshPage';
import SyncPage from '../../Pages/Sync/SyncPage';
import SyndicationPage from '../../Pages/Syndication/SyndicationPage';
import SSDAPage from '../../Pages/SSDA/SSDAPage';
import TemplatesPage from '../../Pages/Templates/TemplatesPage';
import ToursPage from '../../Pages/Tours/ToursPage';
import UsersPage from '../../Pages/Users/UsersPage';
import UtilitiesPage from '../../Pages/Utilities/UtilitiesPage';
import NewVirtualAgent from '../../Pages/VirtualAgent/NewVirtualAgent';

import ProtectedRoute from '../ProtectedRoute';
import { isMasterRole } from '../utils';

const AdminRouter = () => {
  const {
    isBrandContentEnabled,
    isCallIntelStandaloneEnabled,
    isCompanyTemplatesEnabled,
    isSSDAPageEnabled,
  } = useFeatureFlagContext();

  const { isInternalMode } = useAppContext();

  const renderIndexRedirect = () => {
    return <Redirect to="/sources" />;
  };

  return (
    <Switch>
      <Route exact path="/" render={renderIndexRedirect} />
      <ProtectedRoute path="/integrations" component={IntegrationsPage} />
      <ProtectedRoute path="/sources" component={SourcesPage} />
      <ProtectedRoute path="/source-list" component={SourceListPage} />
      <ProtectedRoute path="/users" component={UsersPage} />
      <ProtectedRoute path="/admins" component={AdminsPage} />

      {isInternalMode && (
        <ProtectedRoute path="/syndication" component={SyndicationPage} />
      )}

      <ProtectedRoute path="/pricing" component={PricingPage} />
      <ProtectedRoute path="/properties" component={PropertiesPage} />
      <ProtectedRoute path="/attributes" component={Attributes} />
      <ProtectedRoute path="/photos" component={PhotosPage} />
      <ProtectedRoute path="/registration" component={RegistrationPage} />
      <ProtectedRoute path="/scheduling" component={SchedulingPage} />

      {isSSDAPageEnabled && (
        <ProtectedRoute path="/ssda" component={SSDAPage} />
      )}

      <ProtectedRoute
        path="/quick-replies"
        component={CompanyQuickRepliesPage}
      />

      {isInternalMode && (
        <ProtectedRoute path="/prospects" component={ProspectsPage} />
      )}

      {isBrandContentEnabled && (
        <ProtectedRoute path="/brand-content" component={BrandContentPage} />
      )}

      <ProtectedRoute path="/doorway" component={DoorwayPage} />

      <ProtectedRoute path="/drip-campaign" component={DripCampaignPage} />

      <ProtectedRoute path="/sheets" component={SheetsPage} />

      {isCallIntelStandaloneEnabled && isInternalMode && (
        <ProtectedRoute path="/call-intel" component={CallIntelPage} />
      )}

      {isCompanyTemplatesEnabled && (
        <ProtectedRoute path="/templates" component={CompanyTemplatesPage} />
      )}

      {!isCompanyTemplatesEnabled && (
        <ProtectedRoute path="/templates" component={TemplatesPage} />
      )}

      <ProtectedRoute path="/ai-templates" component={AiTemplatesPage} />

      <ProtectedRoute path="/settings" component={SettingsPage} />

      {isInternalMode && (
        <ProtectedRoute path="/utilities" component={UtilitiesPage} />
      )}

      <ProtectedRoute path="/sync" component={SyncPage} />

      {isMasterRole() && (
        <ProtectedRoute path="/access" component={AccessPage} />
      )}

      <ProtectedRoute path="/virtual-agent" component={NewVirtualAgent} />

      <Route path="/login" component={LoginPage} />
      <Route path="/autologin" component={LoginPage} />
      <Route path="/tours" component={ToursPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/refresh-spreadsheet" component={SpreadsheetRefreshPage} />
    </Switch>
  );
};

export default AdminRouter;
