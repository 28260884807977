import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { EditorConfig } from './EditorConfig';
import { useFeatureFlagContext } from '../Context/FeatureFlagContext';
import './_RichTextEditor.scss';

CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.21.0/full-all/ckeditor.js';

const countPattern = (str, pattern) =>
  ((str || '').match(pattern) || []).length;

const linkCorrection = (children, linkTarget) => {
  children.forEach((child) => {
    // if we have a tag then change the target attribution
    if (child.name === 'a') {
      child.attributes = child.attributes || {};
      child.attributes.target = linkTarget;
    }
    child.children &&
      child.children.length &&
      linkCorrection(child.children, linkTarget);
  });
};

const setHtmlTagFormatRules = (editor) => {
  var htmlTagNames = ['br', 'li', 'ol', 'p', 'ul'];
  var rules = {
    breakAfterClose: false,
    breakAfterOpen: false,
    breakBeforeClose: false,
    breakBeforeOpen: false,
    indent: false,
  };

  htmlTagNames.forEach((tagName) => {
    editor.dataProcessor.writer.setRules(tagName, rules);
  });
};

export const LINK_TARGETS = {
  NOT_SET: 'notSet',
  FRAME: 'frame',
  POPUP: 'popup',
  BLANK: '_blank',
  TOP: '_top',
  SELF: '_self',
  PARENT: '_parent',
};

const RichTextEditor = ({
  authToken,
  content,
  charLimit,
  defaultLinkTarget,
  imageUploadUrl,
  isSourceModeEnabled,
  maxResizeHeight,
  maxResizeWidth,
  mentionsList,
  minResizeHeight,
  minResizeWidth,
  name,
  onChange,
  onBlur,
  onDialogDefinition,
  onInstanceReady,
  placeHolderText,
}) => {
  const { isCKEditorSourceModeEnabled } = useFeatureFlagContext();

  const charCountRef = useRef(0);

  const handleCharacterCount = (editor) => {
    const editorBody = editor.document.getBody();
    const textContent = editorBody.getText();
    const charLength =
      textContent.length +
      editorBody.getChildCount() -
      1 - // add up paragraph count except first paragraph
      countPattern(textContent, /\n/g); // remove new line character count and rely on getChildCount method as it is more predictable
    charCountRef.current = charLength;
  };

  const handleOnChange = ({ editor }) => {
    const data = editor.getData();
    onChange(data, charCountRef.current);
  };

  // make sure that defaultLinkTarget is amongst allowed values
  if (
    defaultLinkTarget &&
    !Object.values(LINK_TARGETS).includes(defaultLinkTarget)
  ) {
    throw Error(`Default Link target "${defaultLinkTarget}" is not valid`);
  }

  const isSourceModeFeatureEnabled =
    isSourceModeEnabled && isCKEditorSourceModeEnabled;

  const CKEditorConfig = new EditorConfig({
    authToken,
    imageUploadUrl,
    isSourceModeEnabled: isSourceModeFeatureEnabled,
    maxResizeHeight,
    maxResizeWidth,
    mentionsList,
    minResizeHeight,
    minResizeWidth,
    onBlur,
    placeHolderText,
  });

  return (
    <div>
      <CKEditor
        config={CKEditorConfig.getConfig()}
        data={content}
        name={name}
        onChange={handleOnChange}
        onInstanceReady={(event) => {
          const { editor } = event;

          if (charLimit) {
            editor.on('getData', (evt) => {
              handleCharacterCount(editor);
            });
          }

          setHtmlTagFormatRules(editor);
          onInstanceReady && onInstanceReady();
        }}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.on('paste', (evt) => {
            if (defaultLinkTarget) {
              // Parse the HTML string to a pseudo-DOM structure.
              const fragment = CKEDITOR.htmlParser.fragment.fromHtml(
                evt.data.dataValue
              );
              const writer = new CKEDITOR.htmlParser.basicWriter();

              // Manipulate fragment to have links with target attribution set to defaultLinkTarget
              linkCorrection(fragment.children, defaultLinkTarget);
              fragment.writeHtml(writer);
              evt.data.dataValue = writer.getHtml();
            }
          });

          CKEDITOR.on('dialogDefinition', (ev) => {
            if (ev.data.name === 'link') {
              ev.data.definition.getContents('target').get('linkTargetType')[
                'default'
              ] = defaultLinkTarget || LINK_TARGETS.NOT_SET;
            }

            if (ev.data.name === 'sourcedialog' && isSourceModeFeatureEnabled) {
              ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;

              ev.data.definition.buttons = [
                CKEDITOR.dialog.cancelButton.override({
                  class: 'custom-source-mode-button cancel-button',
                }),
                CKEDITOR.dialog.okButton.override({
                  class: 'custom-source-mode-button ok-button',
                }),
              ];
            }
            if (onDialogDefinition) {
              onDialogDefinition(ev);
            }
          });
        }}
      />
      {charLimit && charCountRef.current <= charLimit && (
        <div>{`${charLimit - charCountRef.current} characters left`}</div>
      )}
      {charLimit && charCountRef.current > charLimit && (
        <div>{`Character limit exceeded by ${
          charCountRef.current - charLimit
        }`}</div>
      )}
    </div>
  );
};

RichTextEditor.propTypes = {
  authToken: PropTypes.string,
  content: PropTypes.string,
  charLimit: PropTypes.number,
  defaultLinkTarget: PropTypes.string,
  imageUploadUrl: PropTypes.string,
  isSourceModeEnabled: PropTypes.bool,
  maxResizeHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxResizeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mentionsList: PropTypes.array,
  minResizeHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minResizeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDialogDefinition: PropTypes.func,
  onInstanceReady: PropTypes.func,
  placeHolderText: PropTypes.string,
};

export default RichTextEditor;
